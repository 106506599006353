/* jshint esversion: 6 */
/*
 * @Descripttion:
 * @version:
 * @Author: 张磊
 * @Date: 2021-05-18 19:29:45
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-23 00:41:30
 * @FilePath: /官网迭代/src/api/index.js
 */
import user from "./user";
import unit from "./unit";
import home from "./home";
import course from "./course";
import admin from "./admin";
import pes from "./pes";
import publicBenefit from "./publicBenefit";
import train from "./train";
import park from "./park";
import buy from "./buy";
import school from "./school";
import datav from "./datav";
import nAdmin from "./newadmin";
import sports from "./sports";
import zhSchool from "./zhSchool";
import product from "./product";
import goldcoin from "./goldcoin";
import s_sports from "./s_sports";
export default {
  user,
  unit,
  home,
  course,
  admin,
  pes,
  publicBenefit,
  train,
  park,
  buy,
  school,
  datav,
  nAdmin,
  sports,
  zhSchool,
  product,
  goldcoin,
  s_sports,
};

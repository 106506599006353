import { request } from "@/utils/request";

//V2.0 //在线课程
const findOnlineClass = (id) => {
  return request({
    url: `/newClassroomOnline/findOnlineClass?schoolId=${id}`,
    method: "get",
  });
};

//V2.0 //校园风采
const findSchoolAnnex = (id) => {
  return request({
    url: `/newSchoolAnnex/findSchoolAnnex?schoolId=${id}`,
    method: "get",
  });
};

//V2.0 //师资力量
const findSchoolFaculty = (id) => {
  return request({
    url: `/newSchoolFaculty/findSchoolFaculty?schoolId=${id}`,
    method: "get",
  });
};

//V2.0 //教学实力
const findSchoolCredit = (id) => {
  return request({
    url: `/newSchoolCredit/findSchoolCredit?schoolId=${id}`,
    method: "get",
  });
};

//V2.0 //排行榜
const findTopByType = (data) => {
  let params = `schoolId=${data.schoolId}&rankingType=${data.rankingType}`;
  if (data.province) {
    params += `&province=${data.province}`;
  }
  if (data.city) {
    params += `&city=${data.city}`;
  }
  return request({
    url: `/newRanking/findTopByType?${params}`,
    method: "get",
  });
};

//V2.0 //排行榜大屏
const findTopByTypeDatav = (params) => {
  return request({
    url: `/newRanking/findTopByType`,
    method: "get",
    params,
  });
};

//V2.0 // 查询默认视频列表
const getVideoDefault = () => {
  return request({
    url: "/newSystemVideo/getVideoDefault",
    method: "get",
  });
};

//V2.0 // 上课学生状态列表查询
const getStudentParentStatus = (params) => {
  return request({
    url: `/newClassroomOnline/getStudentParentStatus`,
    method: "get",
    params,
  });
};

//V2.0 //学校班级列表
const getSchoolGradeList = (schoolId) => {
  return request({
    // url: `/atteGrade/getReadingLabel/${schoolId}`,
    url: `/atteGrade/getReadingLabelType/${schoolId}`,
    method: "get",
  });
};

//V2.0 //全部学校班级列表
const getAllGradeLabel = (schoolId) => {
  return request({
    url: `/atteGrade/getAllGradeLabel/${schoolId}`,
    method: "get",
  });
};

//V2.0 //去掉已毕业班级
const findSelectBySchoolId = (data) => {
  return request({
    url: `/atteGrade/findSelectBySchoolId`,
    method: "get",
    params: data,
  });
};

///V2.0/ 班级类型
const findGradeType = () => {
  return request({
    url: `/atteGrade/findGradeType`,
    method: "get",
  });
};

//V2.0 查询课程视频进度和上课累计积分
const findClassIfo = (params) => {
  return request({
    url: `/newClassroomOnline/findClassIfo`,
    method: "get",
    params,
  });
};

//V2.0 根据老师ids查询老师信息
const findTeachersByIds = (params) => {
  return request({
    url: `/atteTeacher/findTeachersByIds`,
    method: "get",
    params,
  });
};

//V2.0 校园管理 查询老师
const findGradeInfo = (params) => {
  return request({
    url: `/atteGrade/findGradeInfo`,
    method: "get",
    params,
  });
};

//V2.0 查询班级所有学生
const findStusByGradeId = (params, data) => {
  return request({
    url: `/atteStudent/findStusByGradeId/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//V2.0 班级所有学生
const findSchoolInfo = (params, data) => {
  return request({
    url: `/atteSchool/findSchoolInfo/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//V2.0 代入校
const waitAssociatedSchool = (params, data) => {
  return request({
    url: `/atteStudent/waitAssociatedSchool/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//V2.0 学校已配课未完善信息的学生
const findNoinfoStusBySchoolId = (params, data) => {
  return request({
    url: `/atteStudent/findNoinfoStusBySchoolId/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};
//V2.0 学校已配课未完善信息的学生
const findNoinfoStusByteacherId = (params, data) => {
  return request({
    url: `/atteStudent/findNoinfoStusByteacherId/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//V2.0 批量转学
const batchTransfer = (data) => {
  return request({
    url: `/atteStudent/batchTransfer`,
    method: "post",
    data,
  });
};

//V2.0 批量毕业
const batchGraduation = (data) => {
  return request({
    url: `/atteStudent/batchGraduation2`,
    method: "post",
    data,
  });
};

//V2.0 学生批量分班
const batchChangeGrade = (data) => {
  return request({
    url: `/atteStudent/batchChangeGrade`,
    method: "post",
    data,
  });
};

//V2.0 查询老师和学生
const searchName = (params, data) => {
  return request({
    url: `/atteStudent/searchName/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//V2.0 查询老师和学生
const findTeacherSchoolInfo = (params, data) => {
  return request({
    url: `/atteSchool/findTeacherSchoolInfo/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//V2.0 课室播放添加统计数据
const addStartClassroom = (params, data) => {
  return request({
    url: `/newClassroomOnline/addClassroom/${params}`,
    method: "post",
    data,
  });
};

//V2.0 学校升班
const promotion = (data) => {
  return request({
    url: `/atteGrade/promotion/school/`,
    method: "post",
    data,
  });
};

//V2.0 移除列表
const removeList = (id) => {
  return request({
    url: `/atteGrade/removeList/${id}`,
    method: "get",
  });
};
//V2.0 移除列表
const removeStuList = (id) => {
  return request({
    url: `/atteGrade/removeStuList/${id}`,
    method: "get",
  });
};

//V2.0 恢复教师
const recoveryTec = (id) => {
  return request({
    url: `/atteTeacher/recovery/${id}`,
    method: "get",
  });
};

//V2.0 恢复学生
const recoveryStu = (id) => {
  return request({
    url: `/atteStudent/recovery/${id}`,
    method: "get",
  });
};

//V2.0 留级
const nextGrade = (data) => {
  return request({
    url: `/atteStudent/grade/repeat`,
    method: "post",
    data,
  });
};

//V2.0 批量添加班级信息
const addGradeBatch = (data) => {
  return request({
    url: `/atteGrade/addGradeBatch`,
    method: "post",
    data,
  });
};

//V2.0 毕业
const graduation = (id) => {
  return request({
    url: `/atteGrade/graduation/grade/${id}`,
    method: "get",
  });
};

//V2.0 编辑班级
const updateGradeNew = (id, data) => {
  return request({
    url: `/atteGrade/updateGradeNew/${id}`,
    data,
    method: "put",
  });
};

// v2,0 查询老师所带班级的导航
const getTeacherGradeLabel = (data) => {
  return request({
    url: `/atteGrade/getTeacherGradeLabel`,
    method: "post",
    data,
  });
};

// v2,0 获取班级
const atteGrade = (data) => {
  return request({
    url: `/atteGrade/${data}`,
    method: "get",
  });
};

// V2.0 根据班级id所有学生配课状态
const findStudentByGradeId = (data) => {
  return request({
    url: `/atteStudent/findStudentByGradeId`,
    method: "get",
    params: data,
  });
};

// V2.0 批量恢复删除学生
const recoveryBatch = (data) => {
  return request({
    url: `/atteStudent/recoveryBatch`,
    method: "get",
    params: data,
  });
};

// http://localhost:8090/user/updatePassword 修改密码
const updatePassword = (data) => {
  return request({
    url: "/user/updatePassword",
    method: "post",
    data,
  });
};
// http://localhost:8090/user/findUserCenter 账户中心
const findUserCenter = () => {
  return request({
    url: "/user/findUserCenter",
    method: "get",
  });
};

// http://localhost:8090/atteSchool/schoolRegister?school&password=123456&groupId=2 注册
/**
 *
 * @param {school} data 注册的学校信息【对象AtteSchool 】
 * @param {password} data 用户设置的密码
 * @param {groupId} data 用户组ID  园长注册为2
 * @returns
 */
const schoolRegister = (data) => {
  return request({
    url: "/atteSchool/schoolRegister",
    data,
    method: "post",
  });
};

// http://localhost:8090/atteTeacher/2  查询教师信息
const getTeacherInfo = (id) => {
  return request({
    url: `/atteTeacher/${id}`,
    method: "get",
  });
};

// v2.0V2.0 老师的班级列表
const findGradeListOfTid = (params) => {
  return request({
    url: `/atteTeacher/findGradeListOfTid`,
    method: "get",
    params,
  });
};
// http://localhost:8090/sms/registerSend?phone=13651070379 注册手机号验证
/**
 *
 * @param {phone} params 手机号
 * @returns
 */
const registerSend = (params) => {
  return request({
    url: `/sms/registerSend`,
    method: "get",
    params,
  });
};

// http://localhost:8090/sms/changePassSend?phone=13651070379 转让园长发送验证码
/**
 *
 * @param {phone} params 手机号
 * @returns
 */
const changePassSend = (params) => {
  return request({
    url: `/sms/changePassSend`,
    method: "get",
    params,
  });
};

// http://localhost:8090/sms/loginSend?phone=13651070379 手机号登陆获取验证码
/**
 *
 * @param {phone} params 手机号
 * @returns
 */
const phoneLoginCode = (params) => {
  return request({
    url: `/sms/loginSend`,
    method: "get",
    params,
  });
};

// http://localhost:8090/phoneLogin
/**
 * 手机号登录
 * @param {phone} params 手机号
 * @param {code} params 验证码
 * @returns
 */
const phoneLogin = (data) => {
  return request({
    url: `/phoneLogin`,
    method: "post",
    data,
  });
};

// http://localhost:8090/sms/verifymobileCode?phone=&code=   登录：验证码是否正确
const verifymobileCode = (params) => {
  return request({
    url: `/sms/verifymobileCode`,
    method: "get",
    params,
  });
};

// http://localhost:8090/sms/noLoginVerifymobileCode?phone=&code=  no：验证码是否正确
const noLoginVerifymobileCode = (params) => {
  return request({
    url: `/sms/noLoginVerifymobileCode`,
    method: "get",
    params,
  });
};

//http://localhost:8090/user/updatePwdByPhone 忘记密码重置密码
/**
 * 手机号登录
 * @param {phone} params 手机号
 * @param {newPassword} params 密码
 * @returns
 */
const updatePwdByPhone = (data) => {
  return request({
    url: `/user/updatePwdByPhone`,
    method: "post",
    data,
  });
};

// http://localhost:8090/user/schollDataCenter 示范园审核不通过回显数据
const schollDataCenter = (id) => {
  return request({
    url: `/atteSchool/${id}`,
    method: "get",
  });
};

// http://localhost:8090/atteSchool/updateBgimgeBySchoolId   设置背影图片
const updateBgimgeBySchoolId = (params) => {
  return request({
    url: "/atteSchool/updateBgimgeBySchoolId",
    params,
    method: "put",
  });
};

// http://localhost:8090//newSchoolAnnex/saveSchoolShow/ 校园中心(页面提交)

/**
 * 手机号登录
 * @param {schoolId} schoolId 学校id
 * @returns
 */
const saveSchoolShow = (data, schoolId) => {
  return request({
    url: "/newSchoolAnnex/saveSchoolShow/" + schoolId,
    data,
    method: "post",
  });
};

//学校查询
const getatteFindSchool = (params, schoolId) => {
  return request({
    url: "/atteSchool/" + schoolId,
    params,
    method: "get",
  });
};

//学校提交
const submitAtteSchool = (data, schoolId) => {
  return request({
    url: "/atteSchool/" + schoolId,
    data,
    method: "put",
  });
};

//校园查询

///newSchoolAnnex/

// http://localhost:8090//newSchoolAnnex/findSchoolShow/ 校园中心(页面查询)

/**
 * 手机号登录
 * @param {schoolId} schoolId 学校id
 * @returns
 */
const findSchoolShow = (params) => {
  return request({
    url: "/newSchoolAnnex/findSchoolShow",
    params,
    method: "get",
  });
};

//http://localhost:8090/costOrder/findOrderStatus 购课情况
const findOrderStatus = (params) => {
  return request({
    url: "/costOrder/findOrderStatus",
    params,
    method: "get",
  });
};

//http://localhost:8090/schoolClass/findPaidCourseStatus 购买课程状态 是否购课

const findPaidCourseStatus = (params) => {
  return request({
    url: "/schoolClass/findPaidCourseStatus",
    params,
    method: "get",
  });
};

export default {
  findOnlineClass,
  findSchoolAnnex,
  findSchoolCredit,
  findSchoolFaculty,
  findTopByType,
  findTopByTypeDatav,
  findGradeType,
  findClassIfo,
  findTeachersByIds,
  findGradeInfo,
  findStusByGradeId,
  findNoinfoStusBySchoolId,
  findNoinfoStusByteacherId,
  findTeacherSchoolInfo,
  findSchoolInfo,
  findStudentByGradeId,
  getTeacherGradeLabel,
  findSelectBySchoolId,
  recoveryBatch,
  promotion,
  removeList,
  removeStuList,
  recoveryTec,
  recoveryStu,
  nextGrade,
  graduation,
  atteGrade,
  updateGradeNew,
  addGradeBatch,
  waitAssociatedSchool,
  addStartClassroom,
  batchTransfer,
  batchGraduation,
  batchChangeGrade,
  searchName,
  getVideoDefault,
  getSchoolGradeList,
  getAllGradeLabel,
  getStudentParentStatus,
  updatePassword,
  findUserCenter,
  schoolRegister,
  getTeacherInfo,
  registerSend,
  changePassSend,
  phoneLoginCode,
  phoneLogin,
  verifymobileCode,
  noLoginVerifymobileCode,
  updatePwdByPhone,
  schollDataCenter,
  updateBgimgeBySchoolId,
  saveSchoolShow,
  findSchoolShow,
  submitAtteSchool,
  getatteFindSchool,
  findOrderStatus,
  findPaidCourseStatus,
  findGradeListOfTid,
};

<template>
  <div class="authority">
    <div class="pop-que" v-if="$route.meta.isDatav && dialogStatus">
      <div class="pop-content">
        <div class="close" @click="binClose">
          <img src="../assets/img/zhSchool/popup/Icon_Tc_Close.png" alt="" />
        </div>
        <div class="pop-title1">您还未开通该项权限</div>
        <div class="pop-img">
          <img src="../assets/img/zhSchool/Pic_Tc_WKTQX.png" alt="" />
          <p class="ts-title">购买套餐使用平台服务，立享专属特权！</p>
        </div>
        <div class="pop-title2">
          <img src="../assets/img/zhSchool/Pic_Tc_TCQX.png" alt="" />
        </div>
        <div class="pop-btn" @click="buy">立即购买</div>
      </div>
    </div>
    <el-dialog
      v-else
      title="提示"
      :visible.sync="dialogStatus"
      center
      width="40%"
      :before-close="closeDialog"
    >
      <div class="tc-main">
        <div class="cont">您还未开通该项权限</div>
        <div>
          <img src="../assets/img/tc/Pic_Tc_ZWQX.png" alt="" class="cont-img" />
        </div>
        <div class="dex">购买套餐使用平台服务，立享专属特权！</div>
        <div class="fgx"></div>
        <div class="product">套餐权限</div>
        <div>
          <img src="../assets/img/tc/Pic_Tc_TCQX.png" alt="" class="pro-img" />
        </div>
        <div class="buy" @click="buy">立即购买</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogStatus: false,
    };
  },
  watch: {
    "$store.state.authorityStatus": {
      immediate: true,
      handler: function (val) {
        this.dialogStatus = val;
      },
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("setAuthorityState", false);
    },
    binClose() {
      this.$store.commit("setAuthorityState", false);
      this.dialogStatus = false;
    },
    buy() {
      //立即购买
      this.$store.commit("setAuthorityState", false);
      this.$router.push({
        path: "/product",
        query: {
          type: "class",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.tc-main {
  padding: 5px 10px;
  text-align: center;

  .cont {
    margin-top: 10px;
    font-size: 28px;
    font-weight: bold;
    color: #b93138;
  }
  .cont-img {
    margin-top: 15px;
    margin-bottom: 8px;
    width: 157px;
    height: 131px;
  }
  .dex {
    font-size: 20px;
    font-weight: bold;
    color: #2d4f93;
  }
  .fgx {
    margin-top: 30px;
    width: 100%;
    height: 1px;
    background: #dddddd;
    border-radius: 10px;
  }
  .product {
    margin-top: 23px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .pro-img {
    margin-top: 24px;
    width: 100%;
  }
  .buy {
    margin: 46px auto 0 auto;
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(50deg, #b93037, #d44249);
    box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
    border-radius: 20px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.authority {
  .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }
  .el-dialog__header {
    height: 30px;
    background: #b93138;
    // line-height:20px;
    font-size: 18px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      // line-height:20px;

      .el-dialog__close {
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
//  智慧没有权限弹出框
.pop-que {
  width: 100%;
  height: 100%;
  background: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .pop-content {
    width: 611px;
    height: 642px;
    background: url(../assets/img/zhSchool/Pic_Bg_Tc_WQX.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      right: 20px;
      top: 40px;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .pop-title1 {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffc66c;
      text-align: center;
      margin-top: 100px;
    }
    .pop-title2 {
      text-align: center;
      margin-top: 58px;
      img {
        width: 513px;
        height: 151px;
      }
    }
    .pop-img {
      margin-top: 30px;
      text-align: center;
      img {
        width: 151px;
        height: 119px;
        text-align: center;
      }
      .ts-title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #b8edff;
        padding-top: 12px;
      }
    }
  }
  .ts-content {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c3e8ff;
    line-height: 30px;
    text-indent: 36px;
  }
  .pop-btn {
    width: 149px;
    height: 49px;
    background: url(../assets/img/zhSchool/Bg_Btn.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 49px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c3e8ff;
    margin: 20px auto;
    cursor: pointer;
  }
  .pop-btn:hover {
    background: url(../assets/img/zhSchool/Bg_Btn_H.png) no-repeat;
    background-size: 100% 100%;
  }
}
</style>
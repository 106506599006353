import { request } from "@/utils/request";
console.log()
//  http://10.34.3.149:8090/contest/project/list/{id}  获取赛区比赛项目/训练方案列表
const projectList = (id) => {
    let url = '/contest/project/list';
    if(id){
        url = `/contest/project/list/${id}`;
    }
    return request({
        url: url,
        method: "get",
    });
};
//  http://10.34.3.149:8090/contest/news/schedule  获取赛区的赛程新闻获取详情
/**
 *
 * @param {contestAreaId}} params 赛区id
 * @param {contestNewType} params 频道栏目类型 1 赛事 2 花边 3 轮播 4 赛程
 * @param {contestSessions} params 场次数
 * @param {contestSessionsId} params 场次id
 * @returns
 */
const scheduleNews = (data) => {
    return request({
        url: '/contest/news/schedule',
        method: "POST",
        data
    });
};

//  http://10.34.3.149:8090/contest/news/{id}  获取新闻详情
const newsInfo = (id,params) => {

    return request({
        url: `/contest/news/${id}`,
        method: "get",
        params
    });
};

//  http://10.34.3.149:8090/contest/comment/list/{id}  获取新闻的评论列表
const getCommentList = (id,params) => {

    return request({
        url: `/contest/comment/list/${id}`,
        method: "get",
        params
    });
};
//  http://10.34.3.149:8090/contest/comment/list/{id} 比赛新闻评论添加
const contestComment = (data) => {

    return request({
        url: `/contest/comment`,
        method: "POST",
        data
    });
};
//  http://10.34.3.149:8090/contest/news/fabulous/{id} 比赛新闻点赞
const newsFabulous = (id,params) => {

    return request({
        url: `/contest/news/fabulous/${id}`,
        method: "get",
        params
    });
};

//获取运动会的赛事列表
const querylist = (params) => {
    return request({
        url: `contest/area/query/list`,
        method: "get",
        params
    });
};
//比赛轮播
const projectlistspaots = (id) => {

    return request({
        url: `contest/sessions/project/match/list/${id}`,
        method: "get",
    });
};
//获取赛区比赛项目列表
const projectlistid = (id) => {

    return request({
        url: `contest/project/list/${id}`,
        method: "get",
    });
};
//获取今日参赛队伍列表

const teamtoday = (id) => {

    return request({
        url: `contest/team/today/${id}`,
        method: "get",
    });
};
//赛区的赛程新闻获取详情(web、h5)

const newslist = (data) => {
    return request({
        url: 'contest/news/list',
        method: "POST",
        data
    });
};
//获取赛区场次列表

const sessionsList = (id) => {
    return request({
        url: `/contest/sessions/list/${id}`,
        method: "get",
    });
};

// /contest/area/ranking/{id} 获取赛区排行榜(win)

const areaRanking = (id) => {
    return request({
        url: `/contest/area/ranking/${id}`,
        method: "get",
    });
};

// /contest/comment/fabulous/{id} 比赛新闻评论点赞

const commentFabulous = (id,params) => {
    return request({
        url: `/contest/comment/fabulous/${id}`,
        method: "get",
        params
    });
};

//小小运动会抽签
const recordget = (data) => {
    return request({
        url: 'contest/team/get',
        method: 'POST',
        data
    })
}
//小小运动会报名
//小小运动会判断是否报名
const contestSchoolGet = (params) => {
    return request({
        url: '/contest/school/get',
        method: 'get',
        params
    })
}
//小小运动会获取场次的具体赛程 
const sessionsGetSchedule = (params) => {
    return request({
        url: '/contest/sessions/getSchedule',
        method: 'get',
        params
    })
}

const schoolradd = (data) => {
    return request({
        url: 'contest/school/add',
        method: 'POST',
        data
    })
}


export default {
    projectList,
    scheduleNews,
    newsInfo,
    getCommentList,
    contestComment,
    querylist,
    projectlistspaots,
    projectlistid,
    teamtoday,
    newslist,
    sessionsList,
    newsFabulous,
    areaRanking,
    commentFabulous,
    recordget,
    contestSchoolGet,
    sessionsGetSchedule,
    schoolradd
};
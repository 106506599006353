const PUBLICBENEFIT_ROUTERS = [
  {
    name: 'publicBenefit',
    path: '/publicBenefit',
    component: () => import('@/views/publicBenefit/index.vue'),
    meta: {isPublicBenefit:true}
  },
  {
    name: 'publicBenefitDetail',
    path: '/publicBenefitDetail',
    component: () => import('@/views/publicBenefit/detail.vue'),
    meta: {isPublicBenefit:true}
  },
]

export default PUBLICBENEFIT_ROUTERS

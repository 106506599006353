import stuReport from "@/views/admin/test/stuReport.vue";
import classReport from "@/views/admin/test/classReport.vue";
import schoolReport from "@/views/admin/test/schoolReport.vue";
const ADMIN_ROUTERS = [

  {
    name: "school",
    path: "/school/home",
    component: () => import("@/views/school/index.vue"),
    meta: { isSchool: true },
  },
  {
    name: "schoolHome",
    path: "schoolHome",
    component: () => import("@/views/admin/school/schoolHome.vue"),
    meta: { isSchool: true },
    redirect: "schoolHome/schoolInfo",
    children: [
      // 学校信息
      {
        name: "schoolInfo",
        path: "schoolInfo",
        component: () => import("@/views/admin/school/schoolInfo.vue"),
        meta: { isSchool: true },
      },
      // 校园管理
      {
        name: "schoolPark",
        path: "schoolPark",
        component: () => import("@/views/admin/school/schoolPark.vue"),
        meta: { isSchool: true },
      },
    ],
  },

  {
    name: "adminClassroom",
    path: "classroom",
    component: () => import("@/views/admin/school/classroom.vue"),
    meta: { isSchool: true },
    redirect: "classroom/classIndex",
    children: [
      // 课堂管理
      {
        path: "classIndex",
        component: () =>
          import("@/views/admin/school/classroom/classIndex.vue"),
        meta: { isSchool: true },
      },
      // 在线配课
      {
        path: "classConfig",
        component: () => import("@/views/admin/school/classConfig.vue"),
        meta: { isSchool: true },
      },
      {
        name: "adminOnlineclass",
        path: "onlineclass",
        component: () => import("@/views/admin/school/onlineclass.vue"),
        meta: { isSchool: true },
      },
    ],
  },

  {
    name: "evaluate",
    path: "evaluate",
    component: () => import("@/views/course/evaluate.vue"),
    meta: { isSchool: true },
  },
  {
    name: "adminTestcenter",
    path: "testconter",
    component: () => import("@/views/admin/school/testcenter.vue"),
    meta: { isSchool: true },
    redirect: "/admin/testconter/testconterIndex/",
    children: [
      {
        name: "adminTestcenterIndex",
        path: "testconterIndex",
        meta: { isSchool: true },
        component: () =>
          import("@/views/admin/school/testCon/testcenterIndex.vue"),
      },
      // pda测试
      {
        path: "padContent",
        component: () => import("@/views/admin/school/testCon/padContent.vue"),
        meta: { isSchool: true },
        redirect: "padContent/corporeityTest",
        children: [
          // 体质测试
          {
            path: "corporeityTest",
            component: () =>
              import("@/views/admin/school/testCon/corporeityTest.vue"),
            meta: { isSchool: true },
            redirect: "corporeityTest/corporeityTestA",
            children: [
              // 期末初测试
              {
                path: "corporeityTestA",
                component: () =>
                  import("@/views/admin/school/testCon/corporeityTestA.vue"),
                meta: { isSchool: true },
              },
              // 期末初测试
              {
                path: "semesterFirst",
                component: () =>
                  import("@/views/admin/school/testCon/semesterFirst.vue"),
                meta: { isSchool: true },
              },
            ],
          },
          // 运动测试
          {
            path: "exerciseTest",
            component: () =>
              import("@/views/admin/school/testCon/exerciseTest.vue"),
            meta: { isSchool: true },
            redirect: "exerciseTest/exerciseTestA",
            children: [
              // 运动测试
              {
                path: "exerciseTestA",
                component: () =>
                  import("@/views/admin/school/testCon/exerciseTestA.vue"),
                meta: { isSchool: true },
              },
              // 运动测试
              {
                path: "exerciseTestB",
                component: () =>
                  import("@/views/admin/school/testCon/exerciseTestInfo.vue"),
                meta: { isSchool: true },
              },
              // 期末末测试
              {
                path: "semesterEnd",
                component: () =>
                  import("@/views/admin/school/testCon/semesterEnd.vue"),
                meta: { isSchool: true },
              },
              // 视频页面
              {
                path: "sportsInfo",
                component: () =>
                  import("@/views/admin/teacher/sports_info.vue"),
                meta: { isSchool: true },
              },
            ],
          },
        ],
      },
      // pes测试
      {
        path: "pesContent",
        component: () => import("@/views/admin/school/testCon/pesContent.vue"),
        meta: { isSchool: true },
      },
      {
        //学生测试报告
        name: "stuReport",
        path: "stuReport",
        component: stuReport,
        meta: {
          isSchool: true,
        },
      },
      {
        //班级测试报告
        name: "classReport",
        path: "classReport",
        component: classReport,
        meta: {
          isSchool: true,
        },
      },
      {
        //班级测试报告
        name: "schoolReport",
        path: "schoolReport",
        component: schoolReport,
        meta: {
          isSchool: true,
        },
      },
    ],
  },
  {
    name: "adminDeclare",
    path: "declare",
    component: () => import("@/views/admin/school/declare.vue"),
    meta: { isSchool: true },
    redirect: "declare/demonstrationDeclare",
    children: [
      {
        //示范园
        path: "demonstrationDeclare",
        component: () =>
          import("@/views/admin/school/demonstrationDeclare.vue"),
        meta: { isSchool: true },
      },
      {
        //足特园
        path: "zuteDeclare",
        component: () => import("@/views/admin/school/zuteDeclare.vue"),
        meta: { isSchool: true },
      },
    ],
  },

  // 结束
  {
    name: "classHome",
    path: "classHome",
    component: () => import("@/views/admin/school/classHome.vue"),
    meta: { isAdmin: true, classHeader: true },
  },
  {
    name: "classManagement",
    path: "classManagement",
    component: () => import("@/views/admin/school/classManagement.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "teacherHome",
    path: "teacherHome",
    component: () => import("@/views/admin/school/teacherHome.vue"),
    meta: { isAdmin: true, teacherHeader: true },
  },
  {
    name: "teacherManagement",
    path: "teacherManagement",
    component: () => import("@/views/admin/school/teacherManagement.vue"),
    meta: { isAdmin: true },
  },

  {
    name: "waitRelevance",
    path: "waitRelevance",
    component: () => import("@/views/admin/school/waitRelevance.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "makeOver",
    path: "makeOver",
    component: () => import("@/views/admin/school/makeOver.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "letter",
    path: "letter",
    component: () => import("@/views/admin/school/letter.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "letterDetail",
    path: "letterDetail",
    component: () => import("@/views/admin/school/letterDetail.vue"),
    meta: { isAdmin: true },
  },
  // 积分
  {
    name: "integralList",
    path: "integralList",
    component: () => import("@/views/admin/school/integralList.vue"),
    meta: { isSchool: true },
  },
  {
    name: "integralDetailList",
    path: "integralDetailList",
    component: () => import("@/views/admin/school/integralDetailList.vue"),
    meta: { isSchool: true },
  },
  {
    name: "historyIntegral",
    path: "historyIntegral",
    component: () => import("@/views/admin/school/historyIntegral.vue"),
    meta: { isSchool: true },
  },

  // 学员管理相关
  {
    name: "studentList",
    path: "studentList",
    component: () => import("@/views/admin/student/studentList.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "withClassNoFullInfo",
    path: "withClassNoFullInfo",
    component: () => import("@/views/admin/student/withClassNoFullInfo.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "graduationStudentList",
    path: "graduationStudentList",
    component: () => import("@/views/admin/student/graduationStudentList.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "transferStudentOut",
    path: "transferStudentOut",
    component: () => import("@/views/admin/student/transferStudentOut.vue"),
    meta: { isAdmin: true },
  },

  // 费用管理相关
  {
    name: "contract",
    path: "contract",
    component: () => import("@/views/admin/cost/contract.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "invoiceList",
    path: "invoiceList",
    component: () => import("@/views/admin/cost/invoiceList.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "paymentRecords",
    path: "paymentRecords",
    component: () => import("@/views/admin/cost/paymentRecords.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "courseDetailList",
    path: "courseDetailList",
    component: () => import("@/views/admin/cost/courseDetailList.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "transferVoucher",
    path: "transferVoucher",
    component: () => import("@/views/admin/cost/transferVoucher.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "message",
    path: "message",
    component: () => import("@/views/admin/school/message.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "forgetPassword",
    path: "forgetPassword",
    component: () => import("@/views/admin/school/forgetPassword.vue"),
    meta: { isAdmin: true },
  },
  // pda
  {
    name: "report1",
    path: "report1",
    component: () => import("@/views/admin/pda/pda_report1.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "report2",
    path: "report2",
    component: () => import("@/views/admin/pda/pda_report2.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "report3",
    path: "report3",
    component: () => import("@/views/admin/pda/pda_report3.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "foundation",
    path: "foundation",
    component: () => import("@/views/admin/teacher/foundation.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "sports",
    path: "sports",
    component: () => import("@/views/admin/teacher/sports.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "atte_foundation",
    path: "atte_foundation",
    component: () => import("@/views/admin/teacher/atte_foundation.vue"),
    meta: { isAdmin: true },
  },
  {
    name: "atte_sports",
    path: "atte_sports",
    component: () => import("@/views/admin/teacher/atte_sports.vue"),
    meta: { isAdmin: true },
  }

];

export default ADMIN_ROUTERS;

import { request } from "@/utils/request";

//  http://10.34.3.149:8090/atteGrade/findGradesDetails  班级汇总列表
const findGradesDetails = () => {
  return request({
    url: "/atteGrade/findGradesDetails",
    method: "get",
  });
};
//  http://10.34.3.149:8090//atteGrade/findTeacherGrades  老师账号班级汇总列表
const findTeacherGrades = (params) => {
  return request({
    url: "/atteGrade/findTeacherGrades",
    params,
    method: "get",
  });
};

//  http://10.34.3.149:8090/atteGrade/findStuInfoByGradeId?schoolId=11&gradeId=53&page=0&size=3  班级主页
/**
 *
 * @param {schoolId} params
 * @param {gradeId} params
 * @param {page} params
 * @param {size} params
 * @returns
 */
const findStuInfoByGradeId = (params) => {
  return request({
    url: "/atteGrade/findStuInfoByGradeId",
    params,
    method: "get",
  });
};
// https://localhost:8090/user/findHasClass?schoolId=11非公益配课

const findHasClass = (params) => {
  return request({
    url: "/user/findHasClass",
    params,
    method: "get",
  });
};
// https://localhost:8090/footballApply/deleteFile/11 删除图片视频

const deleteFile = (params) => {
  return request({
    url: "/footballApply/deleteFile",
    params,
    method: "get",
  });
};
//http://localhost:8086/footballApply/updateInput  光标移出提交
const updateInput = (data) => {
  return request({
    url: "/footballApply/updateInput",
    data,
    method: "put",
  });
};

//http://uat.prefootball.cn:8086/atteSchool/findAuditState/11  查询学校认证状态
const findAuditState = (id) => {
  return request({
    url: `/atteSchool/findAuditState/${id}`,
    method: "get",
  });
};
//http://uat.prefootball.cn:8086/atteSchool/findFootballApplayStatus/{schoolId}  查询学校认证状态
const findFootballApplayStatus = (id) => {
  return request({
    url: `/user/findFootballApplayStatus/${id}`,
    method: "get",
  });
};
//http://uat.prefootball.cn:8086/user/findFootballApplayStatus/{schoolId}  查询幼儿录入状态
const findStuRegistered = (id) => {
  return request({
    url: `/user/findStuRegistered/${id}`,
    method: "get",
  });
};

//http://uat.prefootball.cn:8086/findFootabllApplyEndTime   查询足特是否开启
const findFootabllApplyEndTime = () => {
  return request({
    url: `/findFootabllApplyEndTime`,
    method: "get",
  });
};
//http://uat.prefootball.cn:8086/footballApply/updateStep1  申报第一次保存
const updateStep1 = (data) => {
  return request({
    url: `/footballApply/updateStep1`,
    data,
    method: "put",
  });
};
//http://uat.prefootball.cn:8086/footballApply/updateStep2 申报提交
const updateStep2 = (data) => {
  return request({
    url: `/footballApply/updateStep2`,
    data,
    method: "put",
  });
};
//http://uat.prefootball.cn:8086/footballApply/1  申报资料查询
const footballApply = (id) => {
  return request({
    url: `/footballApply/${id}`,
    method: "get",
  });
};
// http://42.193.121.89:8090/atteGrade/updateGrade/2 编辑班级
/**
 *
 * @param {type} data
 * @param {name} data
 * @param {id} id
 * @returns
 */
const updateGrade = (data, id) => {
  return request({
    url: `/atteGrade/updateGrade/${id}`,
    data,
    method: "put",
  });
};

//  http://localhost:8090/atteGrade/findBySchoolId?schoolId=11  园班级名称下拉框
/**
 * @param {schoolId} params
 * @returns
 */
const findBySchoolId = (params) => {
  return request({
    url: "/atteGrade/findBySchoolId",
    params,
    method: "get",
  });
};

//http://localhost:8090/atteTeacher/findTeacherBySchoolId/1/10?schoolId=11 教师管理列表
/**
 * @param {id} id 学校id
 * @param {params} params 分页
 * @returns
 */
const findTeacherBySchoolId = (params, data) => {
  return request({
    url: `/atteTeacher/findTeacherBySchoolId/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// 获取老师所带班级列表
const findGradesOfid = (params) => {
  return request({
    url: "/atteTeacher/findGradesOfid",
    params,
    method: "get",
  });
};

// http://localhost:8090/atteTeacher?teacherId=2&userId=22  删除老师
/**
 * @param {teacherId} params 老师id
 * @param {userId} params 用户id
 * @returns
 */
const atteTeacherDel = (params) => {
  return request({
    url: `/atteTeacher/deleteStatus`,
    method: "put",
    params,
  });
};

//// http://localhost:8090/atteTeacher/addTeacher/11  添加老师
/**
 * @param {id} id 学校id
 * @returns
 */
const atteTeacherAdd = (id, data) => {
  return request({
    url: `/atteTeacher/addTeacher/${id}`,
    method: "post",
    data,
  });
};

// http://http://localhost:8090/atteTeacher/upadetTeacher/2 编辑老师
/**
 * @param {id} id 教师id
 * @returns
 */
const upadetTeacher = (data) => {
  return request({
    url: `/atteTeacher/upadetTeacher`,
    method: "put",
    data,
  });
};
// http://10.34.3.149:8090/atteSchool/statisticalInfo/11 学校h5内信息
/**
 * @param {id} id 学校id
 * @returns
 */
const atteSchoolStatisticalInfo = (data) => {
  return request({
    url: `/atteSchool/statisticalInfo`,
    method: "get",
    params: data,
  });
};
//  http://localhost:8090/atteStudent/addStudent  添加学生
/**
 *
 * @param {idCardType} data
 * @param {idCard} data
 * @param {name} data
 * @param {parentName} data
 * @param {photo} data
 * @param {birthday} data
 * @param {gradeId} data
 * @param {sex} data
 * @param {phone} data
 * @returns
 */
const addStudent = (data) => {
  return request({
    url: "/atteStudent/addStudent ",
    data,
    method: "post",
  });
};

// http://42.193.121.89:8090/atteStudent/findAllStudent/1/10?schoolId=11&name=请问  学员管理

/**
 * @param {page} params
 * @param {size} params
 * @param {schoolId} data
 * @param {name} data
 * @returns
 */
const findAllStudent = (params, data) => {
  return request({
    url: `/atteStudent/findAllStudent/${params.page}/${params.size}`,
    params: data,
    method: "get",
  });
};
// http://localhost:8090/atteStudent/findInCompleteStudent/1/10?schoolId=11  未完善信息学员

/**
 * @param {page} params
 * @param {size} params
 * @param {schoolId} data
 * @param {name} data
 * @returns
 */
const findNoFullInfoStudent = (params, data) => {
  return request({
    url: `/atteStudent/findInCompleteStudent/${params.page}/${params.size}`,
    params: data,
    method: "get",
  });
};

//  http://localhost:8090/atteStudent/updateStu  修改学生
/**
 *
 * @param {id} 学生id
 * @param {idCardType} data
 * @param {idCard} data
 * @param {name} data
 * @param {parentName} data
 * @param {photo} data
 * @param {birthday} data
 * @param {gradeId} data
 * @param {sex} data
 * @param {phone} data
 * @returns
 */
const updateStu = (data, id) => {
  return request({
    url: `atteStudent/updateStu/${id}`,
    data,
    method: "put",
  });
};

// http://localhost:8090/atteStudent/updatePlan?schoolId=11&stuIds=53,54  学生配课
/**
 *
 * @param {schoolId} params  学校ID
 * @param {stuIds} params  学生id ， 号拼接
 * @returns
 */
const updatePlan = (params) => {
  return request({
    url: `atteStudent/updatePlan`,
    params,
    method: "put",
  });
};

//  http://localhost:8090/atteTeacher/findTeacherDetails?schoolId=11 教师汇总列表
/**
 * @param {schoolId} param
 * @returns
 */
const findTeacherDetails = (params) => {
  return request({
    url: "/atteTeacher/findTeacherDetails",
    params,
    method: "get",
  });
};

//  http://localhost:8090/atteTeacher/findGradesOfTeacherId?teacherId=2 我的班级列表
/**
 * @param {schoolId} param
 * @returns
 */
const findGradesOfTeacherId = (params) => {
  return request({
    url: "/atteTeacher/findGradesOfTeacherId",
    params,
    method: "get",
  });
};

//  http://localhost:8090/atteStudent/changeSchool/181?type=2&beforeGrade=3&gradeId=0 学生转学|恢复|毕业
/**
 * @param {type} param (1,0,2)0:在校，1;转学，2：毕业
 * @param {beforeGrade} param (3,0,3)之前的班级
 * @param {gradeId} param (0,3,0) 班级id 置为0
 * @param {id} id 学生id
 * @returns
 */
const changeSchool = (params, id) => {
  return request({
    url: `/atteStudent/changeSchool/${id}`,
    params,
    method: "put",
  });
};

// http://localhost:8090/atteStudent/updateDeleteStatus/1  删除学生
/**
 * @param {id} id 学生id
 * @returns
 */
const updateDeleteStatus = (id) => {
  return request({
    url: `atteStudent/updateDeleteStatus/${id}`,
    method: "put",
  });
};

// http://10.34.3.149:8090/atteSchool/11 学校信息
/**
 * @param {id} id 学校id
 * @returns
 */
const atteSchool = (id) => {
  return request({
    url: `/atteSchool/${id}`,
    method: "get",
  });
};

// http://10.34.3.149:8090/atteSchool/11 学校信息
/**
 * @param {id} id 学校id
 * @returns
 */
const atteSchoolEdit = (id, data) => {
  return request({
    url: `/atteSchool/${id}`,
    method: "put",
    data,
  });
};

//localhost:8090/messageUser/messageNotice/1/10?userId=21 站内信列表
/**
 * @param {id} id 学校id
 * @param {params} params 分页
 * @returns
 */
const messageNotice = (params, data) => {
  return request({
    url: `/messageUser/messageNotice/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://10.34.3.149:8090/messageNotice/messageInfo?messageId=18  站内信详情
/**
 * @param {messageId} params  信息id
 * characteristic@returns
 */
const messageInfo = (params) => {
  return request({
    url: `/messageNotice/messageInfo`,
    method: "get",
    params: params,
  });
};

// http://localhost:8090/messageUser/deleteStatus?messageId=18&userId=21 站内信删除
/**
 * @param {messageId} params  信息id
 * @param {userId} params  用户id
 * @returns
 */
const deleteStatus = (params) => {
  return request({
    url: `/messageUser/deleteStatus`,
    method: "put",
    params: params,
  });
};

// 积分
// http://10.34.3.149:8090/atteSchoolRanking/schoolRankingDetail?schoolId=11  我的积分查询
/**
 *
 * @param {schoolId} params 学校id
 * @returns
 */
const schoolRankingDetail = (params) => {
  return request({
    url: `/atteSchoolRanking/schoolRankingDetail`,
    method: "get",
    params,
  });
};

// http://10.34.3.149:8090/userScoreLog/findStudentRankingInfo/1/10?userId=346405&roleId=3  /老师/学生积分详情查询
/**
 *
 * @param {page} params 当前页
 * @param {size} params 页面条数
 * @param {userId} data 查询的id
 * @param {roleId} data (2:园，4:老师, 3学生) 角色
 * @returns
 */
const detailIntegra = (params, data) => {
  return request({
    url: `/atteStudent/findStudentRankingInfo/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};
// http://localhost:8090/userScoreLog/findSchoolRanking/1/10?schoolId=11&roleId=4 幼儿园积分
const schoollIntegra = (params, data) => {
  return request({
    url: `/userScoreLog/findSchoolRanking/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//http://10.34.3.149:8090/atteTeacherHistory/findTeacherRanking/1/10?schoolId=11  老师历史积分
/**
 *
 * @param {page} params 当前页
 * @param {size} params 页面条数
 * @param {schoollId} data 查询的学校id
 * @returns
 */
const findTeacherRanking = (params, data) => {
  return request({
    url: `/atteTeacher/findTeacherIntegral/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://localhost:8090/atteGrade/addGrade  新增班级
const addGrade = (data) => {
  return request({
    url: `/atteGrade/addGrade`,
    method: "post",
    data,
  });
};

// http://localhost:8090/atteGrade/updateDeleteStatus/103313 删除班级
const updateDeleteStatusGrade = (id) => {
  return request({
    url: `/atteGrade/updateDeleteStatus/${id}`,
    method: "put",
  });
};
// http://localhost:8090/atteGrade/addOrupdateBatchStudentS/1 删除班级
const addOrupdateBatchStudentS = (type, data) => {
  return request({
    url: `/atteGrade/addOrupdateBatchStudentS/${type}`,
    method: "post",
    data,
  });
};

const saveBath = (schoolId, data) => {
  return request({
    url: `/atteTeacher/saveBath/${schoolId}`,
    method: "post",
    data,
  });
};
//http://10.34.3.149:8090/atteStudentHistory/findStudentRanking/1/10?schoolId=11&name=11  学生积分详情
/**
 *
 * @param {page} params 当前页
 * @param {size} params 页面条数
 * @param {schoollId} data 查询的学校id
 * @param {name} data 查询的学生id
 * @returns
 */
const findStudentRanking = (params, data) => {
  return request({
    url: `/atteStudent/findStudentIntegral/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//http://10.34.3.149:8090/atteTeacherHistory/findTeacherRankingInfo?userId=22  老师历史积分查询（单人）
/**
 *
 * @param {userId} params 老师id
 * @returns
 */
const findTeacherRankingInfo = (params) => {
  return request({
    url: `/atteTeacher/findTeacherRankingInfo`,
    method: "get",
    params,
  });
};
//http://10.34.3.149:8090/atteTeacherHistory/findTeacherRankingInfo?userId=22  老师历史积分查询（单人）(新)
/**
 *
 * @param {userId} params 老师id
 * @returns
 */
const getTeacherIntegral = (params) => {
  return request({
    url: `/atteTeacher/${params.teacherId}`,
    method: "get",
    params,
  });
};
// http://10.34.3.149:8090/atteTeacher/findTeacherRankingDetail/1/10?userId=346405  /老师积分详情查询
/**
 *
 * @param {page} params 当前页
 * @param {size} params 页面条数
 * @param {userId} data 查询的id
 * @returns
 */
const findTeacherRankingDetail = (params, data) => {
  return request({
    url: `/atteTeacher/findTeacherRankingDetail/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://42.193.121.89:8090/historyIntegral/findListBySchoolId?shcoolId=11 历史积分
/**
 *
 * @param {shcoolId} params 老师id
 * @returns
 */
const findListBySchoolId = (params) => {
  return request({
    url: `/historyIntegral/findListBySchoolId`,
    method: "get",
    params,
  });
};

//学员管理

//http://localhost:8090/atteStudent/findChangeStuList/0/3?schoolId=11&name=&type=1  转学/毕业学生列表
/**
 *
 * @param {page} params 页码
 * @param {size} params 条数
 * @param {schoolId} data 学校id
 * @param {name} data 学生姓名
 * @param {type} data 1 转学 2 毕业
 * @returns
 */
const findChangeStuList = (params, data) => {
  return request({
    url: `/atteStudent/findChangeStuList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// 费用管理
// http://10.34.3.149:8090/contractAddress/findDownContractList/0/10?schoolId=11  合同下载列表
/**
 *
 * @param {page} params 页码
 * @param {size} params 条数
 * @param {schoolId} data 学校id
 * @returns
 */
const findDownContractList = (params, data) => {
  return request({
    url: `/contractAddress/findDownContractList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://10.34.3.149:8090/contractAddress/findUpContractList/0/10?schoolId=11 合同上传列表
/**
 *
 * @param {page} params 页码
 * @param {size} params 条数
 * @param {schoolId} data 学校id
 * @returns
 */
const findUpContractList = (params, data) => {
  return request({
    url: `/contractAddress/findUpContractList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://10.34.3.149:8090/contractOrder/findWaitContract?schoolId=11 合同上传列表
/**
 *
 * @param {schoolId} params 学校id
 * @returns
 */
const findWaitContract = (params) => {
  return request({
    url: `/contractOrder/findWaitContract`,
    method: "get",
    params,
  });
};

// http://10.34.3.149:8090/schoolClass/payCourseList?schoolId=11 课程详情
/**
 *
 * @param {schoolId} params 学校id
 * @returns
 */
const payCourseList = (params) => {
  return request({
    url: `/schoolClass/payCourseList`,
    method: "get",
    params,
  });
};

// http://10.34.3.149:8090/costOrder/findOrderList/1/12?schoolId=11  费用记录
/**
 *
 * @param {schoolId} data 学校id
 * @param {page} params 页码
 * @param {size} params 条数
 * @returns
 */
const findOrderList = (params, data) => {
  return request({
    url: `costOrder/findOrderList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://10.34.3.149:8090/invoice/findInvoiceList/0/10?schoolId=11 发票列表
/**
 *
 * @param {schoolId} data 学校id
 * @param {page} params 页码
 * @param {size} params 条数
 * @returns
 */
const findInvoiceList = (params, data) => {
  return request({
    url: `invoice/findInvoiceList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://localhost:8090/invoice/addInvoice 添加发票
/**
 *
 * @param {schoolId} data 学校id
 * @returns
 */
const addInvoice = (data) => {
  return request({
    url: `invoice/addInvoice`,
    method: "post",
    data,
  });
};

// http://10.34.3.149:8090/costOrder/findCreateOrder/1/10?userId=21 转账凭证列表
/**
 *
 * @param {userId}} data 学校id
 * @param {page} params 页码
 * @param {size} params 条数
 * @returns
 */
const findCreateOrder = (params, data) => {
  return request({
    url: `costOrder/findCreateOrder/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://10.34.3.149:8090/costOrder/findCostOrderById?id=8679&ordertype="" 转账costOrder凭证详情查询
/**
 *
 * @param {id} params 订单id
 * @param {size} params 订单类型
 * @returns
 */
const findCostOrderById = (params) => {
  return request({
    url: `costOrder/findCostOrderById`,
    method: "get",
    params,
  });
};

// http://localhost:8090/costOrder/updateCostOrderInfo?orderId=C20210406141021393738&intefile=/upload/11111.png,/upload/22222.png  更新costOrder支付凭证
/**
 *
 * @param {orderId} params 订单id
 * @param {intefile} params 图片列表，号隔开
 * @returns
 */
const updateCostOrderInfo = (params) => {
  return request({
    url: `costOrder/updateCostOrderInfo`,
    method: "put",
    params,
  });
};
// http://localhost:8090/atteStudent/batchGraduation  批量毕业学生
/**
 *
 * @param {ids} params ids
 * @param {gradeId} params 班级id
 * @returns
 */
const batchGraduation = (params) => {
  return request({
    url: `atteStudent/batchGraduation`,
    method: "put",
    params,
  });
};
// http://localhost:8090/atteStudent/coveryGraduation  恢复毕业学生
/**
 *
 * @param {ids} params id
 * @returns
 */
const coveryGraduation = (params) => {
  return request({
    url: `atteStudent/coveryGraduation`,
    method: "put",
    params,
  });
};
// 消息树
// http://42.193.121.89:8090/doc/findDocDownList/0/10?schoolId=11 消息树下载列表
/**
 *
 * @param {schoolId} data 学校id
 * @param {page} params 页码
 * @param {size} data 页面展示数
 * @returns
 */
const findDocDownList = (params, data) => {
  return request({
    url: `doc/findDocDownList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://42.193.121.89:8090/doc/findDocUploadList/0/10?schoolId=11 消息树上传列表
/**
 *
 * @param {schoolId} data 学校id
 * @param {page} params 页码
 * @param {size} data 页面展示数
 * @returns
 */
const findDocUploadList = (params, data) => {
  return request({
    url: `doc/findDocUploadList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// logout 退出登录
const logout = () => {
  return request({
    url: `logout`,
    method: "get",
  });
};

// http://localhost:8090/atteStudent/updataByList?students=1,3,10&grade=99  批量分班
/**
 *
 * @param {students} params 学生列表用','分割
 * @param {grade} params 班级id
 * @returns
 */
const updataByList = (params) => {
  return request({
    url: `atteStudent/updataByList`,
    method: "get",
    params,
  });
};

// http://localhost:8090/excel/download?gradeId=99999990  下载当前班级学生列表进行完善
/**
 *
 * @param {gradeId} params 学生列表用','分割
 * @returns
 */
const download = (params) => {
  return request({
    url: `excel/download`,
    method: "get",
    params,
  });
};

// http://localhost:8090/atteGrade/getQrcodeURL 分享链接地址

const getQrcodeURL = () => {
  return request({
    url: `atteGrade/getQrcodeURL`,
    method: "get",
  });
};

// http://localhost:8090/testingFoundation/testingByType  体质/运动测试文案
const testingByType = () => {
  return request({
    url: `/testingFoundation/testingByType`,
    method: "get",
  });
};

// 消息树上传
// http://localhost:8090/doc/uploadDoc
const uploadDoc = (data) => {
  return request({
    url: `/doc/uploadDoc`,
    method: "post",
    data,
  });
};
// 消息树更新
//localhost:8090/doc/8
const doc = (data, id) => {
  return request({
    url: `/doc/${id}`,
    method: "put",
    data,
  });
};

const findSportsTest = (params) => {
  return request({
    url: `/testingSports/findSportsTest`,
    method: "get",
    params,
  });
};

// http://localhost:8090/atteSchool/changeDirector  转让园长
const changeDirector = (data) => {
  return request({
    url: `/atteSchool/changeDirector`,
    method: "put",
    data,
  });
};

// http://localhost:8090/contractAddress/updateContract?contractId=11111&uploadUrl=/uploads/20200831/23a3a3b1716b22ad6b42c848a1c45f73.zip 更新合同地址
const updateContract = (params) => {
  return request({
    url: `/contractAddress/updateContract`,
    method: "get",
    params,
  });
};
// http://localhost:8080/atteSchool/eightyPercent?schoolId=11 判断是否可进行测试
const eightyPercent = (params) => {
  return request({
    url: `/atteSchool/eightyPercent`,
    method: "get",
    params,
  });
};
// 新疆政采项目获取状态
//localhost:8090/governmentbuyApply/findStatus
const findStatus = (params) => {
  return request({
    url: `/governmentbuyApply/findStatus`,
    method: "get",
    params,
  });
};
// 新疆政采项目提交申报
//localhost:8090/governmentbuyApply/addApply
const addApply = (data) => {
  return request({
    url: `/governmentbuyApply/addApply`,
    method: "post",
    data,
  });
};

// http://10.34.3.149:8090/costOrder/findCreateOrderList/1/10?userId=21 订单查询列表
/**
 *
 * @param {userId}} data 用户ID
 * @param {page} params 页码
 * @param {size} params 条数
 * @returns
 */
const findCreateOrderList = (params, data) => {
  return request({
    url: `costOrder/findCreateOrderList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

// http://10.34.3.149:8090/invoice/findByOrderId?orderId=21 查看发票数据
/**
 *
 * @param {orderId}} data 学校id

 * @returns
 */
const findByOrderId = (params) => {
  return request({
    url: `/invoice/findByOrderId`,
    method: "get",
    params: params,
  });
};

// http://10.34.3.149:8090/atteTeacher/findTeachersInfoById  查询教师信息
/**
 *
 * @param {orderId}} data 教师ID

 * @returns
 */
const findTeachersInfoById = (params) => {
  return request({
    url: `/atteTeacher/findTeachersInfoById`,
    method: "get",
    params: params,
  });
};
// http://42.193.121.89:8090/docHistory/add 消息树下载
/**
 *
 * @param {id} data id
 * @returns
 */
const docHistoryAdd = (data) => {
  return request({
    url: `docHistory/add`,
    method: "POST",
    data,
  });
};
// http://42.193.121.89:8090/school/supervise/statistics/total督导统计
/**
 *
 * @param {id} data id
 * @returns
 */

const superviseNumber = (data) => {
  return request({
    url: `/school/supervise/statistics/total`,
    method: "POST",
    data,
  });
};

//http://42.193.121.89:8090/school/supervise/{id} 督导个人信息
const superviseInfo = (id) => {
  return request({
    url: `/school/supervise/${id}`,
    method: "get",
  });
};

//  http://42.193.121.89:8090/school/school/supervise/{id}  督导修改个人信息
const superviseSubmit = (data, id) => {
  return request({
    url: `/school/supervise/${id}`,
    data,
    method: "PUT",
  });
};

//http://42.193.121.89:8090/school/supervise/statistics/total  全国督导总统计
const superviseNational = (data) => {
  return request({
    url: `school/supervise/statistics/total`,
    data,
    method: "POST",
  });
};

/**
 * 获取督导信息列表
 *
 * @param {Number} rank 督导级别：一般取$store.state.userInfo.superviseRank，1全国、2省级、3市级、4区县
 * @param {Number} type 信息类型：0全部、1足特信息、2示范园信息、3公益园信息
 * @param {Object} params 分页参数
 * @param {Object} data 查询列表的参数
 *
 * @returns {Promise}
 */
const getSchoolSuperviseList = (
  rank,
  type = 0,
  params = { page: 1, size: 10 },
  data = { city: "", dateType: 0, province: "" }
) => {
  const ranks = {
    1: "province/",
    2: "city/",
    3: "city/",
  };
  const types = {
    0: "",
    1: "characteristic/",
    2: "demonstrate/",
    3: "welfare/",
  };
  const method = "POST";
  const url = `/school/supervise/school/${ranks[rank]}${types[type]}${params.page}/${params.size}`;

  Object.keys(data).forEach((k) => {
    if (!data[k]) {
      delete data[k];
    }
  });

  console.log(`%cgetSchoolSuperviseList ${method} ${url} data:`, "color:red");

  return request({ method, url, data });
};

function filterData(data = {}, filter = undefined) {
  let d = {};
  if (typeof filter !== "function") {
    filter = (val) => !val;
  }

  Object.keys(data).forEach((k) => {
    if (!filter(data[k])) {
      d[k] = data[k];
    }
  });

  return d;
}

/**
 * 获取督导园所列表
 *
 * @param {Object} data 查询参数
 * @param {Object} page 分页参数
 * @returns {Promise | Promise<unknown>}
 */
const getSchoolSupervisingList = (
  data = {
    area: "", // 学校所在区
    city: "", // 学校所在市
    province: "", // 学校所在省
    dateType: 0, // 时间类型 1 昨日 2 上周 3 上月
    schoolCreditType: 0, // 3: 足特园 4：示范园 5：公益园 7：政采扶持园
    schoolName: "", // 学校名称
    statisticsDate: "", //
  },
  page = {
    page: 1,
    size: 10,
  }
) => {
  const url = `/school/supervise/school/${page.page}/${page.size}`;
  const method = "POST";

  return request({ method, url, data: filterData(data) });
};
/**
 * @param {id} id 学校id
 * @returns
 */
 const getAuthorizationTime = (id) => {
  return request({
    url: `/atteSchoolCert/findBySchoolId/${id}`,
    method: "get",
    params: '',
  });
};
/**
 * 获取督导信息列表导出
 *
 * @param {Number} rank 督导级别：一般取$store.state.userInfo.superviseRank，1全国、2省级、3市级、4区县
 * @param {Number} type 信息类型：0全部、1足特信息、2示范园信息、3公益园信息
 *
 * @returns {Promise}
 */

const exportSchool = (
  rank,
  type = 0,
  data = { city: "", dateType: 0, province: "", statisticsDate: "" },
  exportSchool
) => {
  const ranks = {
    1: "province/",
    2: "city/",
  };
  const types = {
    0: "",
    1: "characteristic/",
    2: "demonstrate/",
    3: "welfare/",
  };
  const method = "POST";
  let url = "";
  if (exportSchool) {
    url = `/school/supervise/school/${ranks[rank]}${types[type]}excel`;
  } else {
    url = `/school/supervise/school/excel`;
  }

  Object.keys(data).forEach((k) => {
    if (!data[k]) {
      delete data[k];
    }
  });

  console.log(`1111 ${method} ${url} data:`, "color:green");
  console.log(data);

  return request({ method, url, data });
};

const exportSchoolCallback = (fileName, isDelete = true) => {
  const method = "GET";
  const url = "/school/supervise/getOssFile";
  const params = { fileName, delete: isDelete };
  return request({ method, url, params });
};

const schoolSuperviseFitSchool = (schoolId = 0) => {
  const url = "/school/supervise/fit/school";
  const method = "PUT";
  const params = {
    schoolId: schoolId,
  };
  // const data = {schoolId:schoolId}

  console.log("schoolSuperviseFitSchool.data", params);

  return request({ method, url, params });
};

const downloadFile = (fileName) => {
  const url = "/school/supervise/getOssFile";
  const method = "GET";
  const params = {
    fileName: fileName,
    delete: true,
  };
  // const data = {schoolId:schoolId}

  console.log("downloadFile.data", params);

  return request({ method, url, params });
};



export default {
  //导出

  downloadFile,

  // 督导列表
  getSchoolSuperviseList,

  // 督导园所列表
  getSchoolSupervisingList,

  // 设置督导登录园所信息
  schoolSuperviseFitSchool,

  //督导导出
  exportSchool,
  exportSchoolCallback,

  findStuRegistered,
  findAuditState,
  findFootabllApplyEndTime,
  findGradesDetails,
  findTeacherGrades,
  findStuInfoByGradeId,
  updateGrade,
  findBySchoolId,
  updateInput,
  footballApply,
  findTeacherBySchoolId,
  findGradesOfid,
  deleteFile,
  atteTeacherDel,
  atteTeacherAdd,
  upadetTeacher,
  addStudent,
  findHasClass,
  updateStu,
  updatePlan,
  findTeacherDetails,
  findGradesOfTeacherId,
  changeSchool,
  updateStep1,
  findFootballApplayStatus,
  updateStep2,
  updateDeleteStatus,
  // 学校信息
  atteSchool,
  atteSchoolEdit,
  atteSchoolStatisticalInfo,
  // 站内信
  messageNotice,
  messageInfo,
  deleteStatus,
  //积分
  schoollIntegra,
  schoolRankingDetail,
  detailIntegra,
  findTeacherRanking,
  findStudentRanking,
  findTeacherRankingInfo,
  findListBySchoolId,
  //学员管理
  findChangeStuList,
  findAllStudent,
  findNoFullInfoStudent,
  updataByList,
  download,
  getQrcodeURL,
  saveBath,

  // 费用管理
  findDownContractList,
  findUpContractList,
  findWaitContract,
  payCourseList,
  findOrderList,
  findInvoiceList,
  addInvoice,
  findCreateOrder,
  findCostOrderById,
  updateCostOrderInfo,
  findCreateOrderList,
  findByOrderId,

  //教师
  findTeachersInfoById,

  //消息树
  findDocDownList,
  findDocUploadList,
  batchGraduation,
  coveryGraduation,

  //退出
  logout,

  testingByType,
  findSportsTest,
  addGrade,
  updateDeleteStatusGrade,
  addOrupdateBatchStudentS,
  uploadDoc,
  doc,
  // 转让园长
  changeDirector,

  //更新合同
  updateContract,
  //判断是否可测试
  eightyPercent,
  findTeacherRankingDetail,
  //新疆政采项目  获取认证状态和申报状态
  findStatus,
  //新疆政采项目  提交申报
  addApply,
  getTeacherIntegral,
  docHistoryAdd,
  //督导
  superviseInfo,
  superviseSubmit,
  superviseNumber,
  superviseNational,
  getAuthorizationTime
};

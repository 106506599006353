/* jshint esversion: 6 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: 张磊
 * @Date: 2021-05-18 19:29:45
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-31 15:10:54
 * @FilePath: /官网迭代/src/api/park.js
 */
import { request } from '@/utils/request'

// http://localhost:8090/sms/verifymobileSend?phone=13651070379 示范园手机验证
/**
 *首页 三条 新闻
 * @param {phone} params | 手机号
 * @returns
 */
const verifymobileSend = (params) => {
  return request({
    url: '/sms/verifymobileSend',
    params,
    method: 'get',
  })
}

// 完善示范园合同信息

// /platformContract/perfectInfo?platformContract
/**
 *首页 三条 新闻
 * @param {data} data | 完善合同信息所有数据
 * 
 */

const perfectInfo = (data) => {
  return request({
    url: `/platformContract/perfectInfo`,
    data,
    method: 'post',
  })
}

// 确认合同

// http://localhost:8090/platformContract/savecontract
/**
 *首页 三条 新闻
 * @param {data} data | 完善合同信息所有数据
 * 
 */

 const saveContract = (data) => {
  return request({
    url: `/platformContract/savecontract`,
    data,
    method: 'post',
  })
}

// 完善发票信息

// http://localhost:8090/platformContract/confirmInfo  post
/**
 * @param {data} data | 完善合同信息所有数据
 * 
 */

 const confirmInfo = (data) => {
  return request({
    url: `/platformContract/confirmInfo`,
    data,
    method: 'post',
  })
}
// 示范园 Demonstration 
// 公益Prefootball Education For All
// 上课改为课程,I Only Need  Enjoyment
// 测试Physical Data Archives
// 积分Kindergarten Quantitative Assessment





/**
 * 查询是否申请过示范园状态
 * @param {schoolId} params 
 * @returns 
 */

// http://localhost:8090/platformContract/findStatusBySchoolId 
const platformContractFindStatus = (params) => {
  return request({
    url: '/platformContract/findStatusBySchoolId',
    params,
    method: 'get',
  })
}

// http://localhost:8090/platformContract/index?id=11 
/**
 * 查询指定学校ID的示范园状态
 * @param {id} params 
 * @returns 
 */

const platformContractIndex = (params) => {
  return request({
    url: '/platformContract/index',
    params,
    method: 'get',
  })
}

// http://localhost:8090/platformContract/invoice 
/**
 * 发票类型提交 schoolId, invoiceName,invoiceType
 * @param {schoolId} params 学校id
 * @param {invoiceName} params 学校id
 * @param {invoiceType} params 学校id 0普票 1专票 2选择/输入票据
 * @returns 
 */
 const invoice = (params) => {
  return request({
    url: '/platformContract/invoice',
    params,
    method: 'get',
  })
}

// http://localhost:8090/invoiceType/all 获取其他发票类型
 const all = (params) => {
  return request({
    url: '/invoiceType/all',
    params,
    method: 'get',
  })
}
/**
 * 发票类型提交 schoolId, invoiceName,invoiceType
 * @param {schoolId} params 学校id
 */
// http://localhost:8090/atteSchool/getSchoolLevel
const invoiceType = (params) => {
  return request({
    url: '/atteSchool/getSchoolLevel',
    params,
    method: 'get',
  })
}
/**
 * 判断视频是否获取成功
 * @param {videoId} params 视频id
 */
// http://localhost:8090/getFreeCoursePlay
const getFreeCoursePlay = (params) => {
  return request({
    url: '/getFreeCoursePlay',
    params,
    method: 'get',
  })
}

export default {
  verifymobileSend,
  platformContractIndex,
  platformContractFindStatus,
  invoice,
  all,
  perfectInfo,
  invoiceType,
  confirmInfo,
  saveContract,
  getFreeCoursePlay
}

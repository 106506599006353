import constant from "@/assets/js/constant";

const common = {
  formatHttp: (url = "") => {
    // 判断域名是否包含http
    let http = "https://";
    if (url.includes("http://") || url.includes("https://")) http = "";
    return http + url;
  },
  serverImg: (img = "") => {
    // 服务器图片路径拼接
    if (img.includes("http://") || img.includes("https://")) return img;
    return img ? constant.SERVER_PRO + "/upload/" + img : constant.IMG_ERROR;
  },
  formatMoney: (money, digit = 2) => {
    var tpMoney = "0.00";
    if (undefined != money) {
      tpMoney = money;
    }
    tpMoney = new Number(tpMoney);
    if (isNaN(tpMoney)) {
      return "0.00";
    }
    tpMoney = tpMoney.toFixed(digit) + "";
    var re = /^(-?\d+)(\d{3})(\.?\d*)/;
    while (re.test(tpMoney)) {
      tpMoney = tpMoney.replace(re, "$1,$2$3");
    }

    return tpMoney;
  },
  numLimit: (value) => {
    // 大于99的数字变成99+
    return value > 99 ? "99+" : value;
  },
  distance: (value) => {
    // 距离米和千米
    if (value === null || value === undefined) {
      return "--";
    } else if (value < 1000) {
      return value + " m";
    } else {
      return (value / 1000).toFixed(1) + " km";
    }
  },
  amount: (value) => {
    // 数量万
    if (value === null || value === undefined) {
      return "--";
    } else if (value < 10000) {
      return value;
    } else {
      return (value / 10000).toFixed(1) + " 万";
    }
  },
  bindCardNo: (value) => {
    // 卡号加***
    if (value) {
      const arr1 = value.substr(value.length - 4);
      const arr2 = value.substr(0, 4);
      return arr2 + " **** **** **** " + arr1;
    }
  },
  sliceString: (str, max) => {
    // 字符串限制长度 超出部分隐藏显示...
    if (!str || str.length === 0) {
      return "";
    }
    if (!max) {
      max = 20;
    }
    if (str.length > max) {
      str = str.substring(0, max);
      str += "...";
    }
    return str;
  },
  arrToStr: (arr = []) => {
    // 数组处理为字符串用‘，’隔开
    var str = "";
    var len = arr.length;
    for (var i = 0; i < len; i++) {
      if (i >= 1) {
        str += ",";
      }
      str += arr[i];
    }
    return str;
  },
  lessTen: (num = 0) => {
    // 数字小于10显示01-09
    var newNum = parseInt(num);
    return newNum < 10 ? "0" + newNum : newNum;
  },
  // capital: (str = '') => { // 数字转换为大写汉字
  //   str = str + ''
  //   var len = str.length - 1
  //   var idxs = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
  //   var num = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  //   return str.replace(/([1-9]|0+)/g, function ($, $1, idx, full) {
  //     var pos = 0
  //     if ($1[0] !== '0') {
  //       pos = len - idx
  //       if (idx === 0 && $1[0] === 1 && idxs[len - idx] === '十') {
  //         return idxs[len - idx]
  //       }
  //       return num[$1[0]] + idxs[len - idx]
  //     } else {
  //       var left = len - idx
  //       var right = len - idx + $1.length
  //       if (Math.floor(right / 4) - Math.floor(left / 4) > 0) {
  //         pos = left - left % 4
  //       }
  //       if (pos) {
  //         return idxs[pos] + num[$1[0]]
  //       } else if (idx + $1.length >= len) {
  //         return ''
  //       } else {
  //         return num[$1[0]]
  //       }
  //     }
  //   })
  // },
  secondToTime: (sec = 0) => {
    // 秒数转换成时分秒格式
    if (sec < 3600) {
      return (
        common.lessTen(Math.floor(parseInt(sec) / 60)) +
        ":" +
        common.lessTen(parseInt(sec) % 60)
      );
    } else {
      return (
        common.lessTen(Math.floor(parseInt(sec) / 3600)) +
        ":" +
        common.lessTen(Math.floor((parseInt(sec) % 3600) / 60)) +
        ":" +
        common.lessTen(parseInt(sec) % 60)
      );
    }
  },
  handlePhone(val) {
    // 手机号中间四位隐藏
    var phone = val.substr(0, 3) + "****" + val.substr(7);
    return phone;
  },
  secondToDate(result) {
    var h =
      Math.floor(result / 3600) < 10
        ? "0" + Math.floor(result / 3600)
        : Math.floor(result / 3600);
    var m =
      Math.floor((result / 60) % 60) < 10
        ? "0" + Math.floor((result / 60) % 60)
        : Math.floor((result / 60) % 60);
    var s =
      Math.floor(result % 60) < 10
        ? "0" + Math.floor(result % 60)
        : Math.floor(result % 60);
    return (result = h + ":" + m + ":" + s);
  },
  getDateTime: () => {
    // 获取当前时间
    var dt = new Date();
    var year = dt.getFullYear();
    var month = dt.getMonth() + 1;
    var day = dt.getDate();
    var h = dt.getHours();
    var m = dt.getMinutes();
    var s = dt.getSeconds();
    month = (parseInt(month) < 10 ? "0" : "") + month;
    day = (parseInt(day) < 10 ? "0" : "") + day;
    h = (parseInt(h) < 10 ? "0" : "") + h;
    m = (parseInt(m) < 10 ? "0" : "") + m;
    s = (parseInt(s) < 10 ? "0" : "") + s;
    var currentdate =
      "当前时间：" +
      year +
      "年" +
      month +
      "月" +
      day +
      "日" +
      " " +
      h +
      "点" +
      m +
      "分" +
      s +
      "秒";
    return currentdate;
  },
  getWeekday: (dt, str) => {
    // 获取传入日期 str为空时默认返回星期几
    str = str || "星期";
    var index = dt.getDay();
    var arr = ["天", "一", "二", "三", "四", "五", "六"];
    var wd = arr[index];
    return str + wd;
  },
  getDateDiff: (dt) => {
    // 获取传入时间与当前时间的差
    dt = new Date(dt).getTime();
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var month = day * 30;
    var now = new Date().getTime();
    var diff = now - dt;
    if (diff < 0) {
      return;
    }
    var monthC = diff / month;
    var weekC = diff / (7 * day);
    var dayC = diff / day;
    var hourC = diff / hour;
    var minC = diff / minute;
    var result = "";
    if (monthC >= 1) {
      result = "" + parseInt(monthC) + "个月前";
    } else if (weekC >= 1) {
      result = "" + parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
      result = "" + parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
      result = "" + parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
      result = "" + parseInt(minC) + "分钟前";
    } else {
      result = "刚刚";
    }
    return result;
  },
  getDateByCount: (dt, acount, sign) => {
    // 传入日期dt 获取acount天后的日期 sign默认为/
    sign = sign || "/";
    var dd = new Date(dt);
    dd.setDate(dd.getDate() + acount * 1);
    // 获取acount天后的日期

    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    // 获取当前月份的日期
    var d = dd.getDate();
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    return y + sign + m + sign + d;
  },
  dateFormat: (date, fmt = "yyyy-MM-dd HH:mm:ss") => {
    // 服务器时间格式化
    date = new Date(date);
    var o = {
      "M+": date.getMonth() + 1, // 月份
      "d+": date.getDate(), // 日
      "h+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时 (12小时制)
      "H+": date.getHours(), // 小时 (24小时制)
      "m+": date.getMinutes(), // 分
      "s+": date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    var week = {
      "0": "/u65e5",
      "1": "/u4e00",
      "2": "/u4e8c",
      "3": "/u4e09",
      "4": "/u56db",
      "5": "/u4e94",
      "6": "/u516d",
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    if (/(E+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (RegExp.$1.length > 1
          ? RegExp.$1.length > 2
            ? "/u661f/u671f"
            : "/u5468"
          : "") + week[date.getDay() + ""]
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  },
  getQueryString: (key) => {
    // 接受URL地址参数 key为传入参数
    var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  getParams: (url) => {
    // 获取链接中包含的参数
    const paramObj = {};
    const params = url.split("?")[1];
    if (!params) return paramObj;
    const keyValueArr = params.split("&");
    keyValueArr.forEach((item) => {
      const keyValue = item.split("=");
      paramObj[keyValue[0]] = decodeURIComponent(keyValue[1]);
    });
    return paramObj;
  },
  executeExp: (r, v) => {
    // 进行校验
    if (!v) return false;
    return r.test(v);
  },
  isMobile: (v = "") => {
    // 判断是否为手机号码
    // var r = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/;
    var r = /^[1]\d{10}$/;
    return !v ? false : r.test(v);
  },
  isMobile2: (v = "") => {
    // 判断是否为固定电话号码
    var r = /^(0[0-9]{2,3})([2-9][0-9]{6,7})+([0-9]{1,4})?$/;
    return !v ? false : r.test(v);
  },
  checkNumLen: (v = "") => {
    // 判断在校学生人数长度 不能超过6位
    // var r = /^\d{1,6}$/;
    // return !v ? false : r.test(v);
    if (v == 0) {
      return false;
    } else {
      var r = /^\d{1,6}$/;
      return !v ? false : r.test(v);
    }
  },
  isCardID: (v = "") => {
    // 验证身份证的有效性
    var r = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    // return this.e
    // xecuteExp(r, v)
    return !v ? false : r.test(v);
  },
  isEmail: (v = "") => {
    var r = /^([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    return !v ? false : r.test(v);
  },
  idChinese: (v = "") => {
    // 判断是否为汉字
    var r = /^\s*$/g;
    return !v ? false : r.test(v);
  },
  isPlateNumber: (v = "") => {
    // 判断是否为车牌号
    var r = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
    return !v ? false : r.test(v);
  },
  isBankNumber: (bankcardnumberResult) => {
    // 判断是否是银行卡号
    //长度校验
    if (
      bankcardnumberResult == "" ||
      bankcardnumberResult.length < 16 ||
      bankcardnumberResult.length > 19
    ) {
      return {
        boole: false,
        title: "银行卡号位数必须在16~19之间，请完整输入银行卡号！",
      };
    }
    //开头6位校验
    var strBin =
      "10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99";
    if (strBin.indexOf(bankcardnumberResult.substring(0, 2)) == -1) {
      return {
        boole: false,
        title: "银行卡号开头6位不符合规范，请检查后重新输入！",
      };
    }
    //全数字校验
    var num = /^\d*$/;
    if (!num.exec(bankcardnumberResult)) {
      return {
        boole: false,
        title: "银行卡号必须全为数字，请检查后重新输入！",
      };
    }
    //Luhm验证
    var lastNum = bankcardnumberResult.substr(
      bankcardnumberResult.length - 1,
      1
    ); //取出最后一位（与luhm进行比较）
    var first15Num = bankcardnumberResult.substr(
      0,
      bankcardnumberResult.length - 1
    ); //前15或18位
    var newArr = new Array();
    for (var i = first15Num.length - 1; i > -1; i--) {
      //前15或18位倒序存进数组
      newArr.push(first15Num.substr(i, 1));
    }
    var arrJiShu = new Array(); //奇数位*2的积 <9
    var arrJiShu2 = new Array(); //奇数位*2的积 >9
    var arrOuShu = new Array(); //偶数位数组
    for (var j = 0; j < newArr.length; j++) {
      if ((j + 1) % 2 == 1) {
        //奇数位
        if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2);
        else arrJiShu2.push(parseInt(newArr[j]) * 2);
      } //偶数位
      else arrOuShu.push(newArr[j]);
    }
    var jishu_child1 = new Array(); //奇数位*2 >9 的分割之后的数组个位数
    var jishu_child2 = new Array(); //奇数位*2 >9 的分割之后的数组十位数
    for (var h = 0; h < arrJiShu2.length; h++) {
      jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
      jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
    }
    var sumJiShu = 0; //奇数位*2 < 9 的数组之和
    var sumOuShu = 0; //偶数位数组之和
    var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
    var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
    var sumTotal = 0;
    for (var m = 0; m < arrJiShu.length; m++) {
      sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
    }
    for (var n = 0; n < arrOuShu.length; n++) {
      sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
    }
    for (var p = 0; p < jishu_child1.length; p++) {
      sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
      sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
    }
    //计算总和
    sumTotal =
      parseInt(sumJiShu) +
      parseInt(sumOuShu) +
      parseInt(sumJiShuChild1) +
      parseInt(sumJiShuChild2);
    //计算Luhm值
    var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
    var luhm = 10 - k;

    if (lastNum == luhm) {
      return {
        boole: true,
        title: "成功",
      };
    } else {
      return {
        boole: false,
        title: "银行卡号不合法，请检查后重新输入！",
      };
    }
  },
  isPrime: (v = "") => {
    // 判断数字是否为质数
    return !/^.?$|^(..+?)\1+$/.test("1".repeat(v));
  },
  isPhoto: (u = "") => {
    // 判断图片格式是否符合要求
    var _index = u.lastIndexOf(".");
    var _type = u.substring(_index + 1);
    var _types = ["jpg", "png", "gif"];
    return !(_types.indexOf(_type) <= -1);
  },
  isMoney: (v = "") => {
    // 判断是否为整数，或者最多保留两位小数
    var r = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
    return r.test(v);
  },
  isAge: (v = "") => {
    // 判断是否为正整数(1-120)
    var r = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
    return r.test(v);
  },
  // 判断设备类型
  device() {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
      return "android";
    }
    if (isiOS) {
      return "ios";
    }
  },
  // input 失去焦点后页面错误
  handleOnresize(callback) {
    let isReset = true; // 是否归位
    if (common.device() === "ios") {
      document.body.addEventListener("focusin", () => {
        // 软键盘弹出的事件处理
        isReset = false;
      });
      document.body.addEventListener("focusout", () => {
        // 软键盘收起的事件处理
        isReset = true;
        // 当焦点在弹出层的输入框之间切换时先不归位
        setTimeout(() => {
          if (isReset) window.scroll(0, 0); // 失焦后强制让页面归位
        }, 300);
      });
    } else if (common.device() === "android") {
      const h = document.documentElement.clientHeight;
      window.onresize = function() {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight < h) {
          // 当软键盘弹起，在此处操作
          isReset = false;
        } else {
          // 当软键盘收起，在此处操作
          isReset = true;
          setTimeout(() => {
            if (isReset) {
              window.scroll(0, 0); // 失焦后强制让页面归位
            }
          }, 300);
        }
        callback && callback(isReset);
      };
    }
  },
  debounce(func, delay) {
    let timer;
    return (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  },
  // 数字超过万显示w
  handleNum(value) {
    if (value === null || value === undefined) {
      return "--";
    } else if (value < 10000) {
      return value;
    } else {
      return (value / 10000).toFixed(1) + "w";
    }
  },
  //活动日期处理
  formatActivityDate(activity) {
    activity ? activity : "";
    let dateStr = "";
    let arr = ["零", "一", "二", "三", "四", "五", "六", "日"];
    if (activity.category === constant.ACT_TYPE.CYCLE) {
      if (activity.cycleType === constant.DATE_CATEGORY_LABEL.YEAR) {
        dateStr = `每年 ${activity.cycleMonthStart}月${activity.cycleDayStart}日`;
      }

      if (activity.cycleType === constant.DATE_CATEGORY_LABEL.MONTH) {
        dateStr = `每月 ${activity.cycleDayStart}号`;
      }

      if (activity.cycleType === constant.DATE_CATEGORY_LABEL.WEEK) {
        dateStr = `每周 ${arr[activity.cycleWeekStart]}`;
      }
    } else {
      // dateStr = dateFormat(new Date()) + dateFormat(new Date(activity.activityDate[1]))
      let one =
        activity.activityDate && activity.activityDate[0]
          ? activity.activityDate[0]
          : "";
      dateStr = common.dateFormat(one, "yyyy.MM.dd");
    }
    return dateStr;
  },
  formatActivityDateDetail(activity) {
    activity ? activity : "";
    let dateStr = "";
    let arr = ["零", "一", "二", "三", "四", "五", "六", "日"];
    if (activity.category === constant.ACT_TYPE.CYCLE) {
      if (activity.cycleType === constant.DATE_CATEGORY_LABEL.YEAR) {
        dateStr = `每年 ${activity.cycleMonthStart}月${activity.cycleDayStart}日 - ${activity.cycleMonthEnd}月${activity.cycleDayEnd}日`;
      }

      if (activity.cycleType === constant.DATE_CATEGORY_LABEL.MONTH) {
        dateStr = `每月 ${activity.cycleDayStart}号 - ${activity.cycleDayEnd}号`;
      }

      if (activity.cycleType === constant.DATE_CATEGORY_LABEL.WEEK) {
        dateStr = `每周 ${arr[activity.cycleWeekStart]} - 每周${
          arr[activity.cycleWeekEnd]
        }`;
      }
    } else {
      // dateStr = dateFormat(new Date()) + dateFormat(new Date(activity.activityDate[1]))
      let one =
        activity.activityDate && activity.activityDate[0]
          ? activity.activityDate[0]
          : "";
      let two =
        activity.activityDate && activity.activityDate[0]
          ? activity.activityDate[1]
          : "";
      dateStr =
        common.dateFormat(one, "yyyy.MM.dd") +
        " 至 " +
        common.dateFormat(two, "yyyy.MM.dd");
    }
    return dateStr;
  },
  //活动时间处理
  formatActivityTime(activity) {
    let one =
      activity.activityTime && activity.activityTime[0]
        ? activity.activityTime[0]
        : "";
    let two =
      activity.activityTime && activity.activityTime[1]
        ? activity.activityTime[1]
        : "";
    return one + "-" + two;
  },
  // 浮点型计算
  floatCompute: {
    //加
    formatParams(values) {
      const params = [];
      values.forEach((value) =>
        params.push((value = Math.floor(Number(value) * 100)))
      );
      return params;
    },
    // values is a array
    add(values) {
      let result = 0;
      const params = this.formatParams(values);
      params.forEach((param) => (result += param));
      result = result / 100;
      return result;
    },
    //减
    subtract(values) {
      let result = 0;
      const params = this.formatParams(values);
      result = params[0] - params[1];
      result = result / 100;
      return result;
    },
    //乘
    multiply(values) {
      let result = 1;
      const params = this.formatParams(values);
      params.forEach((param) => (result *= param));
      result = result / Math.pow(100, params.length);
      return result;
    },
    //除
  },
  PrefixZero(num, n) {
    return (Array(n).join(0) + num).slice(-n);
  },
  // 大写金额转化
  convertCurrency(money) {
    // 汉字的数字
    const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    // 基本单位
    const cnIntRadice = ["", "拾", "佰", "仟"];
    // 对应整数部分扩展单位
    const cnIntUnits = ["", "万", "亿", "兆"];
    // 对应小数部分单位
    const cnDecUnits = ["角", "分", "毫", "厘"];
    // 整数金额时后面跟的字符
    const cnInteger = "整";
    // 整型完以后的单位
    const cnIntLast = "圆";
    // 最大处理的数字
    const maxNum = 9999999999999999.99;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = "";
    // 分离金额后用的数组，预定义
    let parts;
    if (money === "") {
      return "";
    }
    money = parseFloat(money);
    if (money >= maxNum) {
      // 超出最大处理数字
      return "";
    }
    if (money === 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger;
      return chineseStr;
    }
    // 转换为字符串
    money = money.toString();
    if (money.indexOf(".") === -1) {
      integerNum = money;
      decimalNum = "";
    } else {
      parts = money.split(".");
      integerNum = parts[0];
      decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      var zeroCount = 0;
      var IntLen = integerNum.length;
      for (var i = 0; i < IntLen; i++) {
        let n = integerNum.substr(i, 1);
        let p = IntLen - i - 1;
        let q = p / 4;
        let m = p % 4;
        if (n === "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0];
          }
          // 归零
          zeroCount = 0;
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
        }
        if (m === 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q];
        }
      }
      chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== "") {
      var decLen = decimalNum.length;
      for (let i = 0; i < decLen; i++) {
        let n = decimalNum.substr(i, 1);
        if (n !== "0") {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i];
        }
      }
    }
    if (chineseStr === "") {
      chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === "") {
      chineseStr += cnInteger;
    }
    return chineseStr;
  },
  // 中文、英文、.
  validateName(str) {
    const reg = /^([\u4e00-\u9fa5_a-zA-Z]|·)+$/;
    return reg.test(str);
  },
  //校验-输入文字个数  中文为两个字符
  textNumberCheck(markStr, maxLength) {
    let N = 0;
    if (this.validateName(markStr)) {
      for (let z = 0; z < markStr.length; z++) {
        if (/[\u4e00-\u9fa5]/i.test(markStr[z])) {
          //中文
          N = N + 2;
        } else {
          //英文
          N++;
        }
      }
    } else {
      return false;
    }

    if (N > maxLength * 2) {
      return false;
    } else {
      return true;
    }
  },
  //校验性别
  validateSex(str) {
    var reg = /^(男|女)$/;
    return reg.test(str);
  },
  //校验出生日期
  validateBirthday(str) {
    const reg = /^(18|19|20)\d{2}\/((0[1-9])|(1[0-2]))\/(0[1-9]|[1-2][0-9]|3[0-1])$/;
    return reg.test(str);
  },
  //请校验身份证
  validateIdCard(idCard) {
    //15位和18位身份证号码的正则表达式
    var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    //如果通过该验证，说明身份证格式正确，但准确性还需计算
    if (regIdCard.test(idCard)) {
      if (idCard.length == 18) {
        var idCardWi = new Array(
          7,
          9,
          10,
          5,
          8,
          4,
          2,
          1,
          6,
          3,
          7,
          9,
          10,
          5,
          8,
          4,
          2
        ); //将前17位加权因子保存在数组里
        var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
        var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
        for (var i = 0; i < 17; i++) {
          idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
        }
        var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
        var idCardLast = idCard.substring(17); //得到最后一位身份证号码
        //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
        if (idCardMod == 2) {
          if (idCardLast == "X" || idCardLast == "x") {
            return true;
          } else {
            return false;
          }
        } else {
          //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
          if (idCardLast == idCardY[idCardMod]) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  //校验字符长度
  validateLength(markStr, maxLength) {
    let N = 0;
    for (let z = 0; z < markStr.length; z++) {
      if (/[\u4e00-\u9fa5]|"|《|》|“|”|，|。|、/i.test(markStr[z])) {
        //中文
        N = N + 2;
      } else {
        //英文
        N++;
      }
    }
    if (N > maxLength * 2) {
      return false;
    } else {
      return true;
    }
  },
  //校验-整数7位以内、小数2位以内 不可为0 可以是 0.1这种 不可以输入00 012这种
  integerSevenDecimalTwoNoContainsZero(val) {
    if (val == 0) {
      return false;
    }
    let reg = /^([1-9][0-9]{0,6}|[0-9]{1,7}(\.\d{0,2})+)$/;
    return reg.test(val);
  },
  //校验是否有中文
  validateChinese(val) {
    const reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    return reg.test(val);
  },
  //校验统一社会信用代码
  CheckSocialCreditCode(str) {
    var code = str.toUpperCase();

    let firstarray = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    let firstkeys = [3, 7, 9, 10, 5, 8, 4, 2];
    let secondarray = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "J",
      "K",
      "L",
      "M",
      "N",
      "P",
      "Q",
      "R",
      "T",
      "U",
      "W",
      "X",
      "Y",
    ];
    let secondkeys = [
      1,
      3,
      9,
      27,
      19,
      26,
      16,
      17,
      20,
      29,
      25,
      13,
      8,
      24,
      10,
      30,
      28,
    ];

    if (code.length != 18) {
      return false;
    }
    var reg = /^\w\w\d{6}\w{9}\w$/;
    if (!reg.test(code)) {
      return false;
    }

    reg = /^[1,5,9,Y]\w\d{6}\w{9}\w$/;
    if (!reg.test(code)) {
      return false;
    }

    reg = /^(11|12|13|19|51|52|53|59|91|92|93|Y1)\d{6}\w{9}\w$/;
    if (!reg.test(code)) {
      return false;
    }

    reg = /^(11|12|13|19|51|52|53|59|91|92|93|Y1)\d{6}\w{9}\w$/;
    if (!reg.test(code)) {
      return false;
    }

    var firstkey = this.calc(code.substr(8), firstarray, firstkeys, 11);

    var firstword;
    if (firstkey < 10) {
      firstword = firstkey;
    }
    if (firstkey == 10) {
      firstword = "X";
    } else if (firstkey == 11) {
      firstword = "0";
    }
    if (firstword != code.substr(16, 1)) {
      return false;
    }

    var secondkey = this.calc(code, secondarray, secondkeys, 31);

    var secondword = secondarray[secondkey];
    if (!secondword || secondword != code.substr(17, 1)) {
      return false;
    }
    var word = code.substr(0, 16) + firstword + secondword;
    if (code != word) {
      return false;
    }
    return true;
  },
  calc(code, array1, array2, b) {
    var count = 0;
    for (var i = 0; i < array2.length; i++) {
      var a = code[i];
      count += array2[i] * array1.indexOf(a);
    }
    var remainder = count % b;
    return remainder === 0 ? 0 : b - remainder;
  },
  // 校验是否全部为中文
  validateIsAllChinese(str) {
    const reg = /^([\u4e00-\u9fa5])+$/;
    return reg.test(str);
  },
};

export default common;

import { request } from "@/utils/request";
import { requestApplet } from "@/utils/request_applet";
import qs from 'qs';

const getProducts = (params) => {
  return request({
    url: "/setMeal/getProducts",
    method: "get",
    params,
  });
};

//获取套餐列表
const getPackages = (params) => {
    return request({
      url: "/setMeal/getPackages",
      method: "get",
      params,
    });
  };

//获取套餐列表
const getPackageDetail = (params) => {
    return request({
      url: "/setMeal/getPackageDetail",
      method: "get",
      params,
    });
  };

//获取订单支付信息
const getPayInfo = (params) => {
  return request({
    url: "/setMeal/getPayInfo",
    method: "GET",
    params
  });
};

//获取合同单号
const postBuyNow = (data) => {
  return request({
    url: "/proContract/Order/postBuyNow",
    method: "POST",
    data
  });
};

//获取订单列表
const setMealgetOrders = (params) => {
  return request({
    url: "/setMeal/getOrders",
    method: "GET",
    params
  });
};
//上传凭证
const uploadProof = (params) => {
  return request({
    url: "/setMeal/uploadProof",
    method: "GET",
    params
  });
};

//提交合同
const commitContract = (data) => {
  return request({
    url: "/proContract/Order/commitContract",
    method: "PUT",
    data
  });
};

//添加订单
const addOrder = (data) => {
  return request({
    url: "/setMeal/addOrder",
    method: "POST",
    data
  });
};
//待支付创建二维码 

const payOrderCreateNative=(params)=>{
    return request({
      url:"/setMeal/payOrderCreateNative",
      method: "GET",
      params
    })
}

//添加订单
const createNative = (data) => {
  return request({
    url: "/setMeal/createNative",
    method: "POST",
    data
  });
};

//检查支付成功
const checkPay = (params) => {
  return request({
    url: "/setMeal/checkPay",
    method: "GET",
    params
  });
};

//获取最新购买订单
const getLatestOrder = (params) => {
  return request({
    url: "/setMeal/getLatestOrder",
    method: "GET",
    params
  });
};

//获取金币详情
const getGoldApplyInfo = (params) => {
  return request({
    url: "/product/order/getGoldApplyInfo",
    method: "GET",
    params
  });
};

//获取小程序二维码
const getunlimitedqrcode = (data) => {
  return requestApplet({
    url: "/goldcoin/v2/user/getunlimitedqrcode",
    method: "POST",
    data
  });
};

//当前登录用户的订单列表
const applyorderList = (data) => {
  return requestApplet({
    url: "/goldcoin/v2/channel/applyorder/list/inconversation",
    method: "POST",
    data
  });
};

export default {
    getProducts,
    getPackages,
    getPackageDetail,
    getPayInfo,
    postBuyNow,
    setMealgetOrders,
    uploadProof,
    commitContract,
    addOrder,
    payOrderCreateNative,
    createNative,
    checkPay,
    getLatestOrder,
    getunlimitedqrcode,
    getGoldApplyInfo,
    applyorderList
};

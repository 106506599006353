import { request } from "@/utils/request";

const getJSON = (cName) => {
  return request({
    url: `../components/datav/map/${cName}.json`,
    method: "get",
  });
};

// 获取省市区联动
const cascaded = () => {
  return request({
    url: `/area/cascaded/second`,
    method: "post",
  });
};

// 获取数据
const getDatav = (params) => {
  return request({
    url: `/systemStatistics/getSystemSchoolInfoByArea`,
    method: "get",
    params,
  });
};

export default {
  getJSON,
  cascaded,
  getDatav,
};

import { request } from "@/utils/request_zh";

//查询智慧园所基本信息
const getSmartBaseInfo = (id) => {
  return request({
    url: `/atteSchoolSmartinfoAnnex/getSmartBaseInfo/${id}`,
    method: "get",
  });
};

//查询智慧园所数量
const getSmartSchoolInfo = (id) => {
  return request({
    url: `/atteSchoolSmart/getSmartSchoolInfo/${id}`,
    method: "get",
  });
};

//AtteSchoolSmart根据ID查询学校状态
const checkSchoolStatus = (id) => {
  return request({
    url: `/atteSchoolSmart/checkSchoolStatus/${id}`,
    method: "get",
  });
};
//data_management/school/onlinegrade园所获取园所在线班级
const geteRankingGradeList = () => {
  return request({
    url: `/data_management/school/onlinegrade`,
    method: "get",
  });
};
//data_management/school/peoplenum当前园所获取园所人数
const geteRankingPeopleNum = () => {
  return request({
    url: `/data_management/school/peoplenum`,
    method: "get",
  });
};
///data_management/school/classdata获取上课数据
const geteRankingClassData = (params) => {
  return request({
    url: `/data_management/school/classdata`,
    method: "get",
    params,
  });
};
//获取上课排名数据
const geteRankingClassRankingData = () => {
  return request({
    url: `/data_management/school/classdata/rank`,
    method: "get",
  });
};
//获取家庭互动
const geteRankingFamilyInteraction = () => {
  return request({
    url: `/data_management/family/interaction`,
    method: "get",
  });
};
//获取区域积分排名
const geteRankingAreaList = () => {
  return request({
    url: `/data_management/school/integral/rank`,
    method: "get",
  });
};
//获取测试人数
const geteRankingTestNum = (params) => {
  return request({
    url: `/data_management/testnum`,
    method: "get",
    params,
  });
};
//获取体质测试数据
const geteRankingTZTestRate = (params) => {
  return request({
    url: `/data_management/foundation/testdata`,
    method: "get",
    params,
  });
};
//获取运动测试数据
const geteRankingSportTestRate = (params) => {
  return request({
    url: `/data_management/sport/testdata`,
    method: "get",
    params,
  });
};
//成长训练当前园所获取测试人数图表(页面最上面那一部分)
const geteGrowthTrainingTestdateChartMonthTimes = (params) => {
  return request({
    url: `/growth_training/testdata/chart`,
    method: "get",
    params,
  });
};
//成长训练当前园所获取测试人数图表(页面最上面那一部分)
const geteGrowthTrainingTestdateChartMonthNum = (params) => {
  return request({
    url: `/growth_training/testdata/chart`,
    method: "get",
    params,
  });
};
//成长训练当前园所获取测试人数图表(页面最上面那一部分)
const geteGrowthTrainingTestdateChartHistoryNum = (params) => {
  return request({
    url: `/growth_training/testdata/chart`,
    method: "get",
    params,
  });
};
//成长训练区域园所评分排名
const geteGrowthTrainingSchoolScoreRank = (pageNum, pageSize) => {
  return request({
    url: `/growth_training/school/score/rank/${pageNum}/${pageSize}`,
    method: "get",
  });
};
//成长训练32项测试数据平均值图表
const geteGrowthTrainingThirtyTwoIndexChart = (params) => {
  return request({
    url: `/growth_training/thirty-two/index/chart`,
    method: "get",
    params,
  });
};
//成长训练体质测试项列表（select）
const geteGrowthTrainingFoundationIndexList = (params) => {
  return request({
    url: `/growth_training/foundation/indexlist`,
    method: "get",
    params,
  });
};
//成长训练单项测试数据平均值图表
const geteGrowthTrainingSportIndexlist = (params) => {
  return request({
    url: `/growth_training/sport/indexlist`,
    method: "get",
    params,
  });
};
//成长训练当前园所累计测试率图表
const geteGrowthTrainingtesTrateChart = (params) => {
  return request({
    url: `/growth_training/testrate/chart`,
    method: "get",
    params,
  });
};
//成长训练单项测试数据图表
const growthTrainingIndexCharts = (params) => {
  return request({
    url: `/growth_training/index/chart`,
    method: "get",
    params,
  });
};
//获取积分排名
const geteRankingLevelList = (params) => {
  return request({
    url: `/data_management/school/levelintegral/rank`,
    method: "get",
    params,
  });
};
//获取区域积分排名
const getRankingAreaScoreList = () => {
  return request({
    url: `/data_management/school/areaintegral/rank`,
    method: "get",
  });
};
//AtteSchoolSmartinfoAnnex 添加设置
const atteSchoolSmartinfoAnnex = (data) => {
  return request({
    url: `/atteSchoolSmartinfoAnnex`,
    method: "post",
    data,
  });
};

// /school/add添加智慧园所
const schoolAdd = (data) => {
  return request({
    url: `/school/add`,
    method: "post",
    data,
  });
};

// 修改智慧园所
const schoolModify = (data) => {
  return request({
    url: `/school/modify`,
    method: "post",
    data,
  });
};

// 屏幕样式列表
const screenList = () => {
  return request({
    url: `/school/screen/style/list`,
    method: "get",
  });
};

// 文件上传
const uploadFile = (data) => {
  return request({
    url: `/upload/file`,
    method: "post",
    data,
  });
};

// 园所概括
const schoolDetail = (id) => {
  return request({
    url: `/school/detail/${id}`,
    method: "get",
  });
};

// 获取列表游戏
const aiGameList = (id) => {
  return request({
    url: `/ai_game/list?classVideoId=${id}`,
    method: "get",
  });
};

// 获取列表游戏
const getGameList = (id) => {
  return request({
    url: `/upload/getplayinfo?videoId=${id}`,
    method: "get",
  });
};

// 获取挑战录播视频列表
// 获取列表游戏
const gameLogList = (params) => {
  return request({
    url: `/ai_game_log/list`,
    method: "get",
    params,
  });
};

export default {
  getSmartBaseInfo,
  getSmartSchoolInfo,
  checkSchoolStatus,
  geteRankingGradeList,
  geteRankingPeopleNum,
  geteRankingClassData,
  geteRankingClassRankingData,
  geteRankingFamilyInteraction,
  geteRankingAreaList,
  geteRankingTestNum,
  geteRankingTZTestRate,
  geteRankingSportTestRate,
  geteRankingLevelList,
  getRankingAreaScoreList,
  atteSchoolSmartinfoAnnex,
  schoolAdd,
  screenList,
  uploadFile,
  schoolDetail,
  aiGameList,
  gameLogList,
  schoolModify,
  geteGrowthTrainingSchoolScoreRank,
  geteGrowthTrainingThirtyTwoIndexChart,
  geteGrowthTrainingFoundationIndexList,
  geteGrowthTrainingSportIndexlist,
  geteGrowthTrainingtesTrateChart,
  geteGrowthTrainingTestdateChartMonthTimes,
  geteGrowthTrainingTestdateChartMonthNum,
  geteGrowthTrainingTestdateChartHistoryNum,
  growthTrainingIndexCharts,
  getGameList,
};

import { request } from '@/utils/request'


// 获取视频凭证
const getPlayAuth = (params) => {
  return request({
    url: '/vod/video/getPlayAuth/'+params,
    method: 'get'
  })
}

// 获取视频凭证
// http://localhost:8090/getPlayInfo?videoId=2d122ef41f4d425a9ac56fa8fd294867&definition= 获取视频信息
/**
 * 
 * @param {videoId} params  视频id
 * @returns 
 */
const getPlayInfo = (params) => {
  return request({
    url: '/getPlayInfo',
    method: 'get',
    params
  })
}
// http://localhost:8090/getPlayInfoByToken?videoId=2d122ef41f4d425a9ac56fa8fd294867&definition= 获取视频信息
/**
 * 
 * @param {videoId} params  视频id
 * @returns 
 */
 const getPlayInfoByToken = (params) => {
  return request({
    url: '/getPlayInfoByToken',
    method: 'get',
    params
  })
}
// http://localhost:8090/supervision/findByUID?uid&type 督导组数据查询
/**
 * 
 * @param {uid} params 用户id userId
 * @param {type} params 查询数据的类型 0:国家级别，1：省级  2：市级  3：县区级 4:省级服务商
 * @returns 
 */
const findByUID = (params) => {
  return request({
    url: 'supervision/findByUID',
    method: 'get',
    params
  })
}

// http://localhost:8090/supervision/school?uid&phone&type 督导组查看所辖区园所列表数据
/**
 * 
 * @param {uid} params 用户id userId
 * @param {phone} params 用户电话号
 * @param {type} params 获取数据的类型  0：第一批足特  1：第二批足特  2：示范园
 * @returns 
 */
 const school = (params) => {
  return request({
    url: 'supervision/school',
    method: 'get',
    params
  })
}

// http://localhost:8090/user/1 获取电话
const getPhone = (id) => {
  return request({
    url: `user/${id}`,
    method: 'get',
  })
}
// http://localhost:8090/supervision/provinceList   获取省列表 无参数
const provinceList = () => {
  return request({
    url: `supervision/provinceList`,
    method: 'get',
  })
}

//http://localhost:8090/sportsProject/findSportProject?type=four&level=I&pid=2 
const findSportProject = (params) => {
  return request({
    url: `sportsProject/findSportProject`,
    method: 'get',
    params
  })
}

// http://localhost:8090/classRecords/addNewClassRecord  视频播放大于一半或完成，调用
/**
 * 
 * @param {gradeId} data  班级id
 * @param {studentIds} data  学生id列表
 * @param {teacherId} data  教师id列表
 * @param {userId} data  用户id
 * @param {videoId} data  videoid
 * @param {classTime} data  课程播放秒数
 * @param {evaluatestatus} data  默认'0'
 * @param {classstatus} data  默认 '1'
 * @returns 
 */
const addNewClassRecord = (data) => {
  return request({
    url: `classRecords/addNewClassRecord`,
    method: 'post',
    data
  })
}
// http://localhost:8090/classRecords/startAddClassRecord  视频播放大于1分钟，调用(一键上课)
/**
 * 
 * @param {gradeId} data  班级id
 * @param {studentIds} data  学生id列表
 * @param {teacherId} data  教师id列表
 * @param {userId} data  用户id
 * @param {videoId} data  videoid
 * @param {classTime} data  课程播放秒数
 * @param {evaluatestatus} data  默认'0'
 * @param {classstatus} data  默认 '1'
 * @returns 
 */
 const startAddClassRecord = (data) => {
  return request({
    url: `classRecords/startAddClassRecord`,
    method: 'post',
    data
  })
}

// http://localhost:8090/classRecords/startVideoRecord  视频播放大于1分钟，调用(除一键上课外)
/**
 * 
 * @param {gradeId} data  班级id
 * @param {studentIds} data  学生id列表
 * @param {teacherId} data  教师id列表
 * @param {userId} data  用户id
 * @param {videoId} data  videoid
 * @param {classTime} data  课程播放秒数
 * @param {evaluatestatus} data  默认'0'
 * @param {classstatus} data  默认 '1'
 * @returns 
 */
 const startVideoRecord = (data) => {
  return request({
    url: `classRecords/startVideoRecord`,
    method: 'post',
    data
  })
}

// http://localhost:8090/video/updateViews/3 免费视频调取接口
/**
 * 
 * @param {id} data  视频id
 
 */
const addVideo = (id) => {
  return request({
    url: `video/updateViews/${id}`,
    method: 'get',
  })
}




export default {
  getPlayAuth,
  getPlayInfo,
  getPlayInfoByToken,
  findByUID,
  school,
  getPhone,
  provinceList,
  findSportProject,
  addNewClassRecord,
  startAddClassRecord,
  addVideo,
  startVideoRecord
}

<template>
  <div class="report">
    <div class="report-tit">
      {{ $store.state.schoolInfo.atteSchool.schoolName }}-{{
        $route.query.classReport
      }}
    </div>
    <div class="report-sub-tit">
      <span>{{ $route.query.grade }}·{{ $route.query.class }}</span>
      <span>系统平台班级成长测评报告</span>
    </div>
    <div class="report-des">
      <!-- <p class="report-des-time">最近测试时间:<span>2021.08.09</span></p> -->
      <p class="report-des-cont">
        学员测评报告主要来自于全国足球特色幼儿园系统平台的专业测评。我们针对幼儿园阶段不同年级的孩子提供标准化的测试项目。测评项目分体质测试
        和运动测试两大模块。体质测试分身高、体重、视力、臂展、肺活量、胸围、坐姿体前屈；运动测试分跑动、跳跃、敏捷、平衡、协调、力量、物
        体控制-手、物体控制-脚。
      </p>
    </div>
    <div class="report-sub-line-tit mt30">
      <p class="title-line"></p>
      <p class="title-name">评级概况</p>
    </div>
    <div class="report-des">
      <!-- <p class="report-des-time">最近测试时间:<span>2021.08.09</span></p> -->
      <p class="report-des-cont">
        反映孩子参与PDA测试项目评级（优秀、良好与需努力）的比例，并从两次对比中可以观察到测试评级比例的变化情况。
      </p>
    </div>
    <div class="report-body">
      <p class="report-name">体质测试</p>
      <div class="report-body-cont">
        <div
          class="report-body-cont-item"
          v-for="(item, index) in tzList"
          :key="index"
        >
          <div class="report-body-cont-item-top">
            <img
              :src="require(`@/assets/img/test/Icon_Report_${index}.png`)"
              alt=""
            />
            <div class="right">
              <p class="name">{{ item.testingFoundation.title }}</p>
              <p class="value">
                {{ item.avgData ? item.avgData : "0"
                }}<span>{{ handleDw(item.testingFoundation.title) }}</span>
              </p>
            </div>
          </div>
          <div class="report-body-cont-item-bottom">
            全国平均值{{
              item.avgVal || "0" + handleDw(item.testingFoundation.title)
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="report-motion">
      <p class="report-name">运动测试</p>
      <div class="report-motion-cont">
        <div class="report-motion-cont-chart">
          <div class="chart-box">
            <p>2020.02.01体测结果综合评级</p>
            <div id="chart-3" class="chart"></div>
          </div>
          <div class="chart-box">
            <p>2020.02.01体测结果综合评级</p>
            <div id="chart-4" class="chart"></div>
          </div>
        </div>
        <div class="colors">
          <div class="c1"><i></i>需努力</div>
          <div class="c2"><i></i>合格</div>
          <div class="c3"><i></i>良好</div>
          <div class="c4"><i></i>优秀</div>
        </div>
      </div>
    </div>
    <div class="report-prescription">
      <div class="report-sub-line-tit">
        <p class="title-line"></p>
        <p class="title-name">测试记录</p>
      </div>
      <!-- <div class="report-prescription-table">
        <p>体质测试</p>
        <table class="layui-table table-style" lay-skin="line">
          <thead>
            <tr>
              <th v-for="(item, index) in tzList" :key="index">
                {{ item.testingFoundation.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(item, index) in tzList" :key="index">
                {{ item.avgData || "" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="report-prescription-table mt30">
        <p>运动测试</p>
        <table class="layui-table table-style" lay-skin="line">
          <thead>
            <tr>
              <th v-for="(item, index) in ydList" :key="index">
                {{ item.testingSports.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(item, index) in ydList" :key="index">
                {{ item.avgData || "--" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "schoolReport",
  data() {
    return {
      tzList: [
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
      ],
      ydList: [{ createtime: "", testingSports: { title: "" } }],
    };
  },
  methods: {
    initChart(id, colors, data) {
      var chart = echarts.init(document.getElementById(id));
      // var app = {};
      let option = null;
      option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        grid: {
          left: 0,
          right: 0,
        },
        series: [
          {
            // name: '2019年9月26日体测结果综合评级',
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            // label: {
            // formatter: "{d}%",
            // },
            labelLine: {
              show: false,
              length: 5,
              length2: 5,
            },
            label: {
              show: false,
              position: "center",
            },
            color: colors,
            data: data,
          },
        ],
      };
      if (option && typeof option === "object") {
        chart.setOption(option, true);
      }
    },
    //   单位转化
    handleDw(val) {
      if (val) {
        let str = "";
        if (val.indexOf("/") != -1) {
          str = val.split("/")[1];
        }
        let res = "";
        if (str) {
          let temp = str.split("");
          temp.unshift("/");
          res = temp.join("");
        }
        return res == "视力" ? "" : res;
      }
    },
    // 体质测试
    getTZ() {
      let data = {
        gradeId: this.$route.query.gradeId,
        isEnd: this.$route.query.isEnd,
        semesterId: this.$route.query.semesterId,
      };
      this.api.pes.findReportByGradeId(data).then((res) => {
        if (res.flag == true) {
          this.tzList = res.data;
          this.tzList.forEach((item) => {
            if (item.createtime) {
              item.createtime = this.common.dateFormat(
                item.createtime * 1000,
                "yyyy-MM-dd"
              );
            }
          });
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 运动测试
    getYD() {
      let arr;
      if (this.$route.query.grade == "大班") {
        arr = 0;
      } else if (this.$route.query.grade == "中班") {
        arr = 1;
      } else {
        arr = 2;
      }
      let data = {
        semesterId: this.$route.query.semesterId,
        gradeId: this.$route.query.gradeId,
        isEnd: this.$route.query.isEnd,
        type: arr,
      };
      this.api.pes.findReport2ByGradeId(data).then((res) => {
        this.ydList = res.data;
      });
    },
  },
  mounted() {
    this.getTZ();
    this.getYD();
    var id_3 = "chart-3";
    var colors_3 = ["#FF6E77", "#FFCC77", "#71E5B8", "#4F7CFF"];
    var data_3 = [
      { value: 0, name: "需努力" },
      { value: 0, name: "合格" },
      { value: 0, name: "良好" },
      { value: 0, name: "优秀" },
    ];

    var id_4 = "chart-4";
    var colors_4 = ["#FF6E77", "#FFCC77", "#71E5B8", "#4F7CFF"];
    var data_4 = [
      { value: 0, name: "需努力" },
      { value: 13.56, name: "合格" },
      { value: 86.44, name: "良好" },
      { value: 0, name: "优秀" },
    ];
    this.$nextTick(() => {
      this.initChart(id_3, colors_3, data_3);
      this.initChart(id_4, colors_4, data_4);
    });
  },
};
</script>
<style scoped lang="scss">
.mt30 {
  margin-top: 30px !important;
}
.report {
  width: 1354px;
  margin: 0 auto;
  padding: 0 40px;
  background: #fff;
  .report-name {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #ba3037;
  }
  .report-tit {
    padding: 38px 0 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .report-sub-tit {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    > span {
      padding: 0 10px;
    }
  }
  .report-des {
    margin-top: 16px;
    .report-des-time {
      font-size: 16px;
      font-weight: 400;
      color: #888888;

      > span {
        color: #333333;
      }
    }
    .report-des-cont {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      // text-indent: 2em;
    }
  }
  .report-body {
    margin-top: 26px;
    .report-body-cont {
      //@include flex(row, space-between, center);
      flex-wrap: wrap;
      display:flex;
      
      .report-body-cont-item {
        margin-bottom: 20px;
        width: 316px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        margin-right:1.5%;

        .report-body-cont-item-top {
          @include flex(row, flex-start, center);
          > img {
            margin: 10px 0 0 15px;
            width: 75px;
            height: 82px;
          }
          .right {
            margin-left: 4px;
            .name {
              padding-top: 20px;
              font-size: 18px;
              color: #333333;
            }
            .value {
              margin-top: 4px;
              font-size: 30px;
              font-weight: bold;
              color: #333333;
              > span {
                font-size: 16px;
                color: #888888;
              }
            }
          }
        }
        .report-body-cont-item-bottom {
          margin-top: 12px;
          border-top: 1px solid #f1f1f1;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #888888;
          padding-left: 20px;
        }
      }
      .report-body-cont-item:nth-child(4n){
    margin-right:0;
    }
    }
    
  }
  .report-motion {
    margin-top: 20px;
    .report-motion-tit {
      @include flex(row, space-between, center);
      .report-motion-tit-legend {
        font-size: 14px;
        color: #333333;

        > p {
          margin-left: 10px;
          display: inline-block;
        }
        .fail {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #ff6e77;
            border-radius: 2px;
          }
        }
        .good {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #71e5b8;
            border-radius: 2px;
          }
        }
        .excellent {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #4f7cff;
            border-radius: 2px;
          }
        }
      }
    }
    .report-motion-cont {
      margin-bottom: 20px;
      // @include flex(row,space-between,center);
      // flex-wrap: wrap;
    }
  }
  .report-prescription {
    // margin-top:20px;
    padding-bottom: 100px;
    .report-prescription-des {
      margin-top: 0;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      text-indent: 2em;
    }
  }
  .report-prescription-table {
    margin-top: 20px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 4px;

    > p {
      padding: 8px 0 0 0;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      opacity: 0.9;
    }
    .table-style {
      margin-top: 6px;
      thead {
        tr {
          height: 42px;
          background: #ba3037;
          color: #ffffff;
          th {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      tbody {
        tr td {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .tips {
    margin-top: 30px;
    padding-bottom: 60px;
    .tips-cont {
      line-height: 28px;
      font-size: 16px;
      color: #333333;
      opacity: 0.9;
    }
  }
  .report-sub-line-tit {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    .title-line {
      margin-right: 12px;
      margin-top: 3px;
      float: left;
      width: 4px;
      height: 18px;
      background: #ba3037;
      border-radius: 2px;
    }
    .title-name {
      margin-top: -2px;
      float: left;
    }
  }
  .report-motion-cont {
    @include flex(row, flex-start, center);
    .report-motion-cont-chart {
      width: 640px;
      @include flex(row, space-between, center);
      .chart-box {
        width: 320px;
        text-align: center;
        .chart {
          margin: -50px auto;
          width: 320px;
          height: 320px;
        }
      }
    }
    .colors {
      margin-left: 150px;
      color: #333333;
      font-size: 14px;
      > div {
        margin-top: 20px;
      }
    }
    .colors i {
      width: 24px;
      height: 12px;
      background: #ff6e77;
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.15rem;
      margin-top: -2px;
    }

    .colors .c1 i {
      background-color: #ff6e77;
    }

    .colors .c2 i {
      background-color: #ffcc77;
    }

    .colors .c3 i {
      background-color: #71e5b8;
    }

    .colors .c4 i {
      background-color: #4f7cff;
    }
  }
}
</style>
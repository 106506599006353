import api from "../api/index";
import { CodeToText } from "element-china-area-data";
export default {
  // 获取学校信息  账户信息中心
  getSchoolInfo(store) {
    api.user.findUserCenter().then((res) => {
      // 信息页面展示内容
      store.commit("setSchoolInfo", res.data);
    });
  },
  //   获取教师信息
  getTeacherInfo(store, id) {
    api.user.getTeacherInfo(id).then((res) => {
      store.commit("setTeacherInfo", res.data);
    });
  },
  //   获取头部教师信息
  getFindTeachersInfo(store, id) {
    api.nAdmin.findTeachersInfo({id}).then((res) => {
      store.commit("setFindTeachersInfo", res.data);
    });
  },
  //   获取大屏数据
  getFullData(store, data) {
    api.datav.getDatav(data).then((res) => {
      console.log(res, "大屏数据");
      store.commit("setFullData", res.data);
    });
  },
  // 获取学生信息
  //   getStudentInfo(store){

  //   },
  register(store, that) {
    let data = { ...that.register };
    data.city =
      CodeToText[data.city[0]] +
      "/" +
      CodeToText[data.city[1]] +
      "/" +
      CodeToText[data.city[2]];
    api.user.schoolRegister(data).then((res) => {
      if (res.flag) {
        // let userForm = {
        //   username: data.contactsMobile,
        //   password: data.password,
        // };
        // store.dispatch("login", { userForm, that });
        that.$message.success("注册成功，立即登录");
        store.commit("setLoginState", "login");
      } else {
        that.$message.error(res.message);
      }
    });
  },
  // 账号登录
  login(store, result) {
    let that = result.that;
    api.user.login(result.userForm).then((res) => {
      console.log(res);
      if (res.flag) {
        store.commit("setUserInfo", res.data);
        store.commit("setLoginState", "");
        store.dispatch("getSchoolInfo");
        store.commit("setFirstLogin", 1);
        store.commit("setSuperviseId", res.data.superviseId);

        if (res.data.superviseId) {
          that.$router.push({
            path: "supervision",
          });
          return false;
        }

        if (res.data.teacherId) {
          store.dispatch("getTeacherInfo", res.data.teacherId);
        }
        let num = 3;
        let timer = setInterval(() => {
          console.log(num);
          if (num == 1) {
            clearInterval(timer);
            let closeBtn = document.body.getElementsByClassName("v-modal")[0];
            closeBtn.click();
            console.log(that.$store.state.goUrl,'that.$store.state.goUrl')
            if(store.state.goUrl){
              that.$router.push({
                path: store.state.goUrl
              });
            }else{
              that.$router.push({
                path: store.state.userInfo.teacherId
                  ? "school/home"
                  : "school/home",
              });
            }
            store.commit("clearLoginUrlState");
          } else {
            num = num - 1;
          }
        }, 1000);
        that
          .$confirm(
            res.message + `页面将在${num}s后进行跳转至校园中心`,
            "提示",
            {
              confirmButtonText: "立即跳转",
              showCancelButton: false,
              type: "success",
            }
          )
          .then(() => {
            if(store.state.goUrl){
              that.$router.push({
                path: store.state.goUrl
              });
            }else{
              that.$router.push({
                path: store.state.userInfo.teacherId
                  ? "/school/home"
                  : "/school/home",
              });
            }
            store.commit("clearLoginUrlState");
          });
          
      } else {
        that.$message.error(res.message);
      }
    });
  },

  // 手机号登录
  PhoneLogin(store, result) {
    let that = result.that;
    api.user.phoneLogin(result.data).then((res) => {
      console.log(res);
      if (res.flag) {
        store.commit("setUserInfo", res.data);
        store.commit("setLoginState", "");
        store.dispatch("getSchoolInfo");
        if (res.data.teacherId) {
          store.dispatch("getTeacherInfo", res.data.teacherId);
        }
        let num = 3;
        let timer = setInterval(() => {
          console.log(num);
          if (num == 1) {
            clearInterval(timer);
            let closeBtn = document.body.getElementsByClassName("v-modal")[0];
            closeBtn.click();
            if(store.state.goUrl){
              that.$router.push({
                path: store.state.goUrl
              });
            }else{
              that.$router.push({
                path: store.state.userInfo.teacherId
                  ? "/school/home"
                  : "/school/home",
              });
            }
            store.commit("clearLoginUrlState");
          } else {
            num = num - 1;
          }
        }, 1000);
        that
          .$confirm(
            res.message + `页面将在${num}s后进行跳转至校园中心`,
            "提示",
            {
              confirmButtonText: "立即跳转",
              showCancelButton: false,
              type: "success",
            }
          )
          .then(() => {
            console.log(store.state.goUrl,'store.state.goUrl')
            if(store.state.goUrl){
              console.log('11111')
              console.log('11');
              that.$router.push({
                path: store.state.goUrl
              });
            }else{
              that.$router.push({
                path: store.state.userInfo.teacherId
                  ? "/school/home"
                  : "school/home",
              });
            }
            store.commit("clearLoginUrlState");
          });
        
      } else {
        that.$message.error(res.message);
      }
    });
  },

  // 退出登录
  out(store, that) {
    api.admin.logout().then((res) => {
      if (res.flag) {
        localStorage.removeItem("userInfo");
        store.commit("setLoginState", '');
        store.commit("setTeacherInfo", {});
        store.commit("setSchoolInfo", {});
        store.commit("setUserInfo", {});
        store.commit("setFindTeachersInfo", {});
        store.commit("clearLoginUrlState");
      }
      let num = 3;
      let timer = setInterval(() => {
        console.log(num);
        if (num == 1) {
          clearInterval(timer);
          let closeBtn = document.body.getElementsByClassName("v-modal")[0];
          console.log(closeBtn);
          closeBtn.click();
          that.$router.push({
            path: "/home",
          });
        } else {
          num = num - 1;
        }
      }, 1000);
      that
        .$confirm(res.message + `页面将在${num}s后进行跳转`, "提示", {
          confirmButtonText: "立即跳转",
          showCancelButton: false,
          type: res.flag ? "success" : "error",
        })
        .then(() => {
          console.log(that.$confirm);
          that.$router.push({
            path: "/home",
          });
        })
        .catch(() => {
          that.$router.push({
            path: "/home",
          });
        });
    });
  },
  //按钮鉴权
  checkAuth(store,result){
    let params = {
      authId:result.id,
    };
     api.user.checkAuth(params).then((res)=>{
       console.log(res,'dddd')
       if(res.code == 20000){
        result.fn && result.fn(result.params);
       }else{
        if(result.fail){
          result.fail();
        }else{
          // result.that.$message.error(res.message);
          store.commit('setAuthorityState',true);
        }
       }
       
     })
  }
};

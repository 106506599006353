<template>
  <div class="header">
    <div class="top">
      <div class="logo_block">
        <div class="logo"></div>
        <div class="title">全国足球特色幼儿园系统平台</div>
      </div>
      <div class="prefootball">-PREFOOTBALL-</div>
    </div>
    <!--新疆集采扣-->
    <!-- <div
      class="xj_block"
      v-if="$store.state.isCloseXjzc && $store.state.isHome"
    >
      <img
        @click="closeXj"
        class="close"
        src="../assets/img/header/Icon_Close_Red.png"
        alt=""
        width="12"
        height="12"
      />
      <img
        @click="shenbaoFn"
        class="shenbao"
        src="../assets/img/header/Btn_ZC.png"
        alt=""
        width="300"
        height="140"
      />
    </div> -->
    <div class="product tab" @click="toProduct">
      <div class="title">产品中心</div>
    </div>
    <div v-if="!$store.state.userInfo.token" class="login tab" @click="login">
      <!-- <span class="user"></span> -->
      <div class="title">注册/登录</div>
    </div>
    <div class="login tab" @click="toAdmin" v-else>
      <div class="title">校园中心</div>
    </div>
    <div class="content">
      <div class="block" v-if="xiaoshi.flag">
        <div
          class="item tab"
          v-for="(item, index) in tabList"
          @click="jump(item)"
          :class="{ is_tab: $store.state.headerTabTag === index }"
          :key="item.title"
        >
          <div class="icon">
            <img :src="item.src" alt="" />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="referral">{{ item.referral }}</div>
        </div>
      </div>
      <!-- <div class="bg">
          <div class="bg1">
            <img src="./../assets/img/header/组 10.png" alt="" class="sfy">
          </div>
          <div class="bg2">
            <img src="./../assets/img/header/组 9.png" alt="" class="gy">
          </div>
          <div class="bg3">
            <img src="./../assets/img/header/组 8.png" alt="" class="kc">
          </div>
          <div class="bg4">
            <img src="./../assets/img/header/组 7.png" alt="" class="cs">
          </div>
          <div class="bg5">
            <img src="./../assets/img/header/组 6.png" alt="" class="jf">
          </div>
        </div> -->
      <!-- <div class="div1"><img src="./../assets/img/header/zi.png" alt=""></div>
          <div class="div2"><img src="./../assets/img/header/zi2.png" alt=""></div>
          <div class="div3"><img src="./../assets/img/header/zi3.png" alt=""></div>
          <div class="div4"><img src="./../assets/img/header/zi4.png" alt=""></div>
          <div class="div5"><img src="./../assets/img/header/zi5.png" alt=""></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBlock",
  data() {
    return {
      tabList: this.constant.publicTabList,
      xiaoshi: {
        flag: true,
      },
    };
  },
  methods: {
    jump(value) {
      if (this.$route.path !== value.path) {
        if (value.path === "/park") {
          if (!this.$store.state.userInfo.teacherId) {
            this.api.park
              .platformContractIndex({ id: this.$store.state.userInfo.userId })
              .then((res) => {
                if (res.flag) {
                  localStorage.setItem("parkinfo", JSON.stringify(res.data));
                  this.$router.push({
                    path: value.path,
                  });
                } else {
                  if (this.$store.state.userInfo.token) {
                    this.$notice({
                      message: res.message,
                    }).isShow();
                  } else {
                    this.$store.commit("setLoginState", "login");
                  }
                }
              });
          } else {
            this.$notice({
              message: "请使用园长账号访问示范园专区",
            }).isShow();
          }
        } else {
          // 正常跳转
          this.$router.push({
            path: value.path,
          });
        }
      }
    },
    login() {
      this.$store.commit("setLoginState", "login");
    },
    toAdmin() {
      let path = "";
      if (this.$store.state.userInfo.teacherId) {
        path = "/school/home";
      } else {
        path = "/school/home";
      }
      this.$router.push({
        path,
      });
    },
    toProduct(){
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", ["login",'/product']);
      }else{
        this.$router.push({
          path:'/product',
        });
      }
    },
    closeXj() {
      //关闭新疆政采项目申报
      this.$store.commit("setIsCloseXjzc", 0);
    },
    shenbaoFn() {
      //点击新疆政采项目申报
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", "login");
      } else if (this.$store.state.userInfo.teacherId) {
        this.$notice({
          message: "请用园长账号登录进行申报",
        }).isShow();
        return;
      } else {
        this.$router.push({
          path: "/xjShenbao",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .div1 img {
//   position: absolute;
//   padding-top: 2px;
//   left: 314px;
//   top: 182px;
//   width: 110px;
// }
// .div2 img {
//   position: absolute;
//   padding-top: 2px;
//   left: 600px;
//   top: 182px;
//   width: 130px;
// }
// .div3 img {
//   position: absolute;
//   padding-top: 2px;
//   left: 896px;
//   top: 182px;
//   width: 130px;
// }
// .div4 img {
//   position: absolute;
//   padding-top: 2px;
//   left: 1200px;
//   top: 182px;
//   width: 110px;
// }
// .div5 img {
//   position: absolute;
//   padding-top: 2px;
//   left: 1466px;
//   top: 182px;
//   width: 180px;
// }
// .bg {
//   margin-left: 240px;
//   width: 1434px;
//   height: 246px;
//   overflow: hidden;
//   // background-color: blue;
// }
// .bg1{
//   // margin: auto;
//   width: 266px;
//   height: 241px;
//   float: left;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   margin-left: 10px;
//   margin-right: 20px;
//   display: table-cell;
// 	vertical-align: middle;
//   text-align: center;
//   border-radius: 25px;
//   background: linear-gradient(159deg, #5d91e8 0%, #003275 100%);
// }
// .bg1:hover {
//   position: relative;
//   top: -5px;
// }
// .bg2{
//   width: 266px;
//   height: 241px;
//   float: left;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   margin-right: 20px;
//   border-radius: 25px;
//   display: table-cell;
// 	vertical-align: middle;
//   text-align: center;
//   background: linear-gradient(159deg, #5d91e8 0%, #003275 100%);
// }
// .bg2:hover {
//   position: relative;
//   top: -5px;
// }
// .bg3{
//   width: 266px;
//   height: 241px;
//   float: left;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   margin-right: 20px;
//   border-radius: 25px;
//   display: table-cell;
// 	vertical-align: middle;
//   text-align: center;
//   background: linear-gradient(159deg, #5d91e8 0%, #003275 100%);
// }
// .bg3:hover {
//   position: relative;
//   top: -5px;
// }
// .bg4{
//   width: 266px;
//   height: 241px;
//   float: left;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   margin-right: 20px;
//   border-radius: 25px;
//   display: table-cell;
// 	vertical-align: middle;
//   text-align: center;
//   background: linear-gradient(159deg, #5d91e8 0%, #003275 100%);
// }
// .bg4:hover {
//   position: relative;
//   top: -5px;
// }
// .bg5{
//   width: 270px;
//   height: 241px;
//   float: left;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   border-radius: 25px;
//   display: table-cell;
// 	vertical-align: middle;
//   text-align: center;
//   background: linear-gradient(159deg, #5d91e8 0%, #003275 100%);
// }
// .bg5:hover {
//   position: relative;
//   top: -5px;
// }
// .sfy {
//   margin-top: 10px;
//   width: 144px;
//   height: 211px;
// }
// .gy {
//   margin-top: 10px;
//   width: 189px;
//   height: 208px;
// }
// .kc {
//   margin-top: 10px;
//   width: 184px;
//   height: 213px;
// }
// .cs {
//   margin-top: 10px;
//   width: 174px;
//   height: 209px;
// }
// .jf {
//   margin-top: 10px;
//   width: 270px;
//   height: 208px;
// }
.header {
  width: 100%;
  position: relative;
  height: 575px;
  padding-top: 64px;
  box-sizing: border-box;
  @include bg("../assets/img/header/top_banner.png");
  .xj_block {
    width: 320px;
    height: 150px;
    @include flex();
    position: absolute;
    top: 50px;
    left: 36px;
    .shenbao {
      width: 320px;
      height: 150px;
      cursor: pointer;
    }
    .close {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 23px;
      top: 23px;
      cursor: pointer;
    }
  }
  .login {
    width: 176px;
    height: 54px;
    background: #ffffff;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 500;
    color: #b23733;
    @include flex();
    position: absolute;
    top: 36px;
    right: 36px;

    .user {
      width: 40px;
      height: 40px;
      @include bg("../assets/img/header/user.png");
    }
  }
  .product{
    width: 176px;
    height: 54px;
    background: #ffffff;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 500;
    color: #b23733;
    position: absolute;
    top: 35px;
    right: 238px;
    line-height: 54px;
    text-align: center;
  }

  .top {
    .logo {
      width: 137px;
      height: 137px;
      margin-right: 17px;
      @include bg("../assets/img/header/logo.png");
    }

    .title {
      font-size: 68px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .logo_block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prefootball {
    font-size: 36px;
    font-family: Krona One;
    font-weight: 400;
    color: #ffffff;
    line-height: 52px;
    text-align: center;
    position: relative;
    bottom: 20px;
    letter-spacing: 5px;
  }

  .content {
    width: 100%;
    // height: 472px;
    box-sizing: border-box;
    margin-top: 97px;
    padding: 0 0 59px 0;
    position: absolute;
    bottom: 0;
    z-index: 1;

    .block {
      width: 1434px;
      margin: 0 auto;
      @include flex(row, space-between, center);

      .item {
        // width: 17%;
        // width: 274px;
        height: 214px;
        // background: linear-gradient(159deg, #5d91e8 0%, #003275 100%);
        border-radius: 25px;
        box-sizing: border-box;

        // padding: 32px 0 21px 0;

        @include flex(column, center, center);

        .icon {
          margin-top: 20px;
          width: 274px;
          height: 214px;
          // overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          margin: 16px 0 9px 0;
          line-height: 31px;
          // padding-bottom: 10px;
          // border-bottom: 1px solid #fff;
        }

        .referral {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 20px;
          color: #ffffff;
          text-align: center;
        }
      }

      .item:hover {
        position: relative;
        top: -5px;
      }
    }
  }
}
</style>
<template>
  <div class="dialog_block">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      @close="isClose"
      :visible.sync="show"
    >
      <div class="close pa cp" @click="isClose"></div>
      <div class="icon w150 ma">
        <template v-if="type == 'success'">
          <img class="w" src="../../assets/img/common/sucess.png" />
        </template>
        <template v-else-if="type == 'warn'">
          <img class="w" src="../../assets/img/common/warn.png" />
        </template>
        <template v-if="type == 'fail'">
          <img class="w" src="../../assets/img/common/fail.png" />
        </template>
      </div>
      <div class="con f28 tc">{{ message }}</div>
      <div class="btnBox tc mb25 mt20">
        <el-button
          class="confirmBtn"
          type="danger"
          round
          @click="func ? isqueding() : isClose()"
          >确定</el-button
        >
        <el-button class="cancelBtn" type="danger" round @click="isClose"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "tablePage",
  components: {},
  props: {
    type: {
      type: String,
      default: "fail",
    },
    message: {
      type: String,
      default: "",
    },
    func: {
      type: Boolean,//测试报告体验新版添加事件判断
    },
  },
  watch: {},
  data() {
    return {
      show: false,
      title: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShow() {
      this.show = true;
    },
    isClose() {
     
      this.show = false;
    },
    isqueding() {
      this.show = false;
      let token = JSON.parse(localStorage.getItem("userInfo")).token
      console.log(process.env.VUE_APP_NODE)
      if (this.func) {
        window.open(process.env.VUE_APP_NODE +'?token='+token)
      
      }
    },
  },
};
</script>

<style lang="scss">
.dialog_block .el-dialog {
  @include whb(678px, "", solid 5px #cb1b31 !important);
  position: relative;
  .close {
    @include whb(36px, 36px, solid 4px #fff);
    @include bds();
    @include bgc(#535151);
    @include bg("../../assets/img/common/closewh.png", 15px 15px);
    right: -15px;
    top: -15px;
    &:hover {
      @include bgc(#2e8ded);
    }
  }
  .con {
    color: $theme-red;
    margin: 37px 0 40px 35px;
  }
  .btnBox {
    @include flex();
    color: $theme-white;
  }
  .confirmBtn,
  .cancelBtn {
    @include whb(220px, 66px, solid 4px #fff);
    @include bds(33px);
    font-size: 26px;
  }
  .confirmBtn {
    background-color: #cb1b31;
    &:hover {
      background-color: #d03246;
    }
  }
  // #f5d1d6   #f6d6da 原确认
  // #cb1b31   #d03246 原取消
  .cancelBtn {
    @include bgc(#f5d1d6);
    margin-left: 40px;
    &:hover {
      @include bgc(#f6d6da);
    }
  }
}
</style>

/* jshint esversion: 6 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: 张磊
 * @Date: 2021-05-22 17:23:58
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-23 22:47:09
 * @FilePath: /官网迭代/src/api/buy.js
 */
import { request } from '@/utils/request'


// http://localhost:8090/semester/findSemesterList?schoolId=11 获取学期列表
/**
 * 获取学期列表
 * @param {schoolId} params 学校id
 * @returns
 */
const findSemesterList = (params) => {
  return request({
    url: `/semester/findSemesterList`,
    params,
    method: 'get',
  })
}

// http://localhost:8090/contractOrder/addContractInfo?schoolId=11  
/**
* 立即购买课程 生成订单号
* @param {schoolId} params 学校id
* @returns
*/
const addContractInfo = (params) => {
  return request({
    url: `/contractOrder/addContractInfo`,
    params,
    method: 'get',
  })
}

// http://localhost:8090/contractOrder/updateContractOrder  确认交易
const updateContractOrder = (data) => {
  return request({
    url: `/contractOrder/updateContractOrder`,
    data,
    method: 'post',
  })
}

// http://localhost:8090/wxpay/createNative?outtradeno=C20210521102521809169&totalFee=1 创建二维码
const createNative = (params) => {
  return request({
    url: `/wxpay/createNative`,
    params,
    method: 'get',
  })
}

// http://localhost:8090/wxpay/query/payOrder?outtradeno=1111111 查询订单状态
const payOrder = (params) => {
  return request({
    url: `/wxpay/query/payOrder`,
    params,
    method: 'get',
  })
}

// 查询学期列表：http://localhost:8090/semester/findSemesterListByIds?semesters=1,2 
const findSemesterListByIds = (params) => {
  return request({
    url: `/semester/findSemesterListByIds`,
    params,
    method: 'get',
  })
}
// 查询课程价格：http://localhost:8090/priceTemplate/12 
const priceTemplate = (type) => {
  return request({
    url: `/priceTemplate/${type}`,
    method: 'get',
  })
}
export default {
  findSemesterList,
  addContractInfo,
  updateContractOrder,
  createNative,
  payOrder,
  findSemesterListByIds,
  priceTemplate
}

// import createVuexAlong from '@/components/vuex-along'
import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  // plugins: [createVuexAlong({
  //   name: 'xsb-vuex-along', // 保存的集合名字
  //   local: { // 存入localStorage
  //     list: ['currentUser', 'wxUserInfo']
  //   },
  //   session: { // 过滤， 将其他的存入 sessionStorage
  //     list: [],
  //     isFilter: true
  //   }
  // })]
})

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/api";
import common from "@/assets/js/common";
import constant from "@/assets/js/constant";
import preview from "vue-photo-preview";
import ElementUI from "element-ui";
import clipboard from "clipboard";
import "swiper/dist/css/swiper.css";

import * as filter from "@/filter";
console.log(filter);
// 引入echarts
import * as echarts from "echarts";

// import * as echarts from 'echarts'
import Notice from "@/components/common/warningDialog.vue";
import Hint from "@/components/common/newDialog.vue";
import dataV from "@jiaminghi/data-view";

import VideoPlayer from "vue-video-player";

const hls = require("videojs-contrib-hls");

import animated from 'animate.css'

Vue.use(animated)
Vue.use(hls);
Vue.use(VideoPlayer);
Vue.use(dataV);
Vue.use(preview);
Vue.use(ElementUI);

import $ from "jquery";
Vue.prototype.$ = $;

// 初始化样式
import "@/assets/style/base.css";
import "@/assets/style/pda.css";
import "@/assets/style/mix.scss";
import "@/assets/style/_variables.scss";
import "@/assets/style/global.scss";
import "@/assets/style/newskin.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/element_index.css";
import "vue-photo-preview/dist/skin.css";
import  "@/assets/css/font.css";

Vue.config.productionTip = false;

/**
 * @description 全局注册应用配置
 */
Vue.prototype.$echarts = echarts;
Vue.prototype.clipboard = clipboard;
Vue.prototype.api = api;
Vue.prototype.common = common;
Vue.prototype.constant = constant;
//时间格式

// 自定义全局过滤器
function fixRem() {
  var windowWidth =
    document.documentElement.clientWidth ||
    window.innerWidth ||
    document.body.clientWidth;
  windowWidth = windowWidth < 1180 ? 1180 : windowWidth;
  var rootSize = 100 * (windowWidth / 1920);
  // console.log(rootSize)
  // var rootSize = 100 * (1180 / 1920)
  var htmlNode = document.getElementsByTagName("html")[0];
  htmlNode.style.fontSize = rootSize + "px";
}
fixRem();
window.addEventListener("resize", fixRem, false);

// Component - 组件配置对象
// props - 传递给它的属性
function create(Component, props) {
  // 1.构建Component的实例
  const vm = new Vue({
    render(h) {
      // h是createElement
      // 它可以返回一个vnode
      return h(Component, { props });
    },
  }).$mount(); // 不设置挂载目标，依然可以转换vnode为真实节点$el
  // 2.把 Vue 实例关联的DOM 元素挂载到 body 上
  document.body.appendChild(vm.$el);

  // 3.获取组件实例
  const comp = vm.$children[0];
  comp.remove = () => {
    document.body.removeChild(vm.$el);
    vm.$destroy();
  };

  return comp;
}

// Vue.prototype.constant = constant
// install(Vue) {
Vue.prototype.$notice = function (options) {
  return create(Notice, options);
};
Vue.prototype.$hint = function (options) {
  return create(Hint, options);
};
// }

//注册一下 过渡器
Object.keys(filter).forEach((key) => {
  Vue.filter(key, filter[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

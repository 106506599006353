const CONSTANT = {
  URL: "https://oss.prefootball.cn",
  ZH_URL: process.env.NODE_ENV == "development" ? "http://47.104.234.128:10003/wisdom_school" : "https://wisdom.prefootball.cn/wisdom_school",//智慧幼儿园上传图片
  UPLOAD_URL: process.env.NODE_ENV == "development" ? "http://39.106.9.174:8086" : "https://www.prefootball.cn:8090",
  // UPLOAD_URL: "http://47.104.234.128:8086", //小小运动会
  ZH_UPLOAD_URL: process.env.NODE_ENV == "development" ? "http://47.104.234.128:10003/wisdom_school" : "https://wisdom.prefootball.cn/wisdom_school",//智慧幼儿园上传图片
  //UPLOAD_URL: "http://10.34.3.168:8086", //本地
  //UPLOAD_URL: "https://www.prefootball.cn:8090/", //生产
  URL_admin: "https://footballcn.oss-cn-beijing.aliyuncs.com",
  OLD_VERSION:
    process.env.NODE_ENV == "development"
      ? "http://39.106.9.174:8088/admin/schoolHome"
      : "https://www.prefootball.cn/admin/schoolHome",
  REPORT_SCHOOL:
    process.env.NODE_ENV == "development"
      ? "http://39.106.9.174:8091/newReport/#/pages/home/home"
      : "https://www.prefootball.cn/newReport/#/pages/home/home",
  REPORT_CHILDREN:
    process.env.NODE_ENV == "development"
      ? "http://39.106.9.174:8091/newReport/#/pages/home/childrenReport"
      : "https://www.prefootball.cn/newReport/#/pages/home/childrenReport",
  publicTabList: [
    // 头部 公用展示内容
    {
      src: require("@/assets/img/header/示范园.png"),
      tabSrc: require("@/assets/img/header/示范园.png"),
      // title: '示范园',
      // tabSrc: require('./../assets/img/header/zi.png'),
      // referral:'Model Kindergarten',
      path: "/park",
    },
    {
      src: require("@/assets/img/header/公益.png"),
      tabSrc: require("@/assets/img/header/公益.png"),
      // title: '公益',
      // referral: 'Prefootball Education For All',
      path: "/publicBenefit",
    },
    {
      src: require("@/assets/img/header/课程.png"),
      tabSrc: require("@/assets/img/header/课程.png"),
      // title: '课程',
      // referral: 'I only Need Enjoyment',
      path: "/course_class",
    },
    {
      src: require("@/assets/img/header/测试.png"),
      tabSrc: require("@/assets/img/header/测试.png"),
      // title: '测试',
      // referral: 'Physical Data Archives',
      path: "/test",
    },
    {
      src: require("@/assets/img/header/积分.png"),
      tabSrc: require("@/assets/img/header/积分.png"),
      // title: '积分',
      // referral: 'Quantitative Assessmenent',
      path: "/integralList",
    },
  ],
  integralType: {
    integral: 0, //积分
    activeIntegral: 1, //活动积分
  },
  // 课程类别  LGA 分类 调接口用
  courseType: {
    experience: {
      ispremium: 0,
      title: "体验课",
    },
    pay: {
      ispremium: 1,
      title: "小班课程",
    },
    ispremium: {
      //是否付费
      pay: 1, //付费
      free: 0, //免费
    },
  },
  // 所属年纪  0：大班 1： 中班  2：小班
  grade: {
    big: 0,
    middle: 1,
    small: 2,
  },
  difficulty: {
    small: 0, //初级
    middle: 1, //中级
    big: 2, //高级
    0: "初级",
    1: "中级",
    2: "高级",
  },
  stuStatus: {
    atSchool: 0, // 在校
    graduate: 2, // 毕业
    goSchool: 1, //转学
  },
  // 园长端侧边栏
  schoolBar: [
    {
      title: "学校管理",
      show: true,
      children: [
        {
          title: "学校主页",
          path: "/admin/schoolHome",
          query: {},
        },
        {
          title: "班级管理",
          path: "/admin/classManagement",
          query: {},
        },
        {
          title: "教师管理",
          path: "/admin/teacherManagement",
          query: {},
        },
        {
          title: "待入校关联",
          path: "/admin/waitRelevance",
          query: {},
        },
        {
          title: "学校信息",
          path: "/admin/schoolInfo",
          query: {},
        },
        {
          title: "转让园长",
          path: "/admin/makeOver",
          query: {},
        },
        {
          title: "我的积分",
          path: "/integral",
          query: {},
        },
        {
          title: "站内信",
          path: "/admin/letter",
          query: {},
        },
      ],
    },
    {
      title: "学员管理",
      show: true,
      children: [
        {
          title: "学员列表",
          path: "/admin/studentList",
          query: {},
        },
        {
          title: "已配课未完善",
          path: "/admin/withClassNoFullInfo",
          query: {},
        },
        {
          title: "毕业学员列表",
          path: "/admin/graduationStudentList",
          query: {},
        },
        {
          title: "转学学员列表",
          path: "/admin/transferStudentOut",
          query: {},
        },
        {
          title: "转入学员操作",
          path: "",
          query: {},
        },
      ],
    },
    {
      title: "费用管理",
      show: true,
      children: [
        {
          title: "缴费记录",
          path: "/admin/paymentRecords",
          query: {},
        },
        {
          title: "转账凭证",
          path: "/admin/transferVoucher",
          query: {},
        },
        {
          title: "课程详情",
          path: "/admin/courseDetailList",
          query: {},
        },
        {
          title: "发票列表",
          path: "/admin/invoiceList",
          query: {},
        },
        {
          title: "合同管理",
          path: "/admin/contract",
          query: {},
        },
      ],
    },
  ],
  //教师端侧边栏
  teacherBar: [
    {
      title: "学校管理",
      show: true,
      children: [
        {
          title: "我的主页",
          path: "/admin/teacherHome",
          query: {},
        },
        {
          title: "一键上课",
          path: "/attendClass",
          query: {},
        },
        {
          title: "我的培训",
          path: "",
          query: {},
        },
        {
          title: "我的证书",
          path: "",
          query: {},
        },
        {
          title: "我的积分",
          path: "/admin/integralTeacherList",
          query: {},
        },
        {
          title: "站内信",
          path: "/admin/letter",
          query: {},
        },
      ],
    },
  ],
};

export default CONSTANT;

<template>
  <div class="login" v-show="alertShow">
    <div
      class="layui-layer layui-layer-page pop-login"
      v-show="alertShow === 'login' || alertShow === 'loginPhone'"
    >
      <div class="close1 tab" @click="close"></div>
      <div class="layui-layer-content">
        <div class="form-login layui-layer-wrap">
          
          <div class="head">
            <div
              :class="{ active: alertShow === 'login' }"
              @click="$store.commit('setLoginState', 'login')"
            >
              账号登录
            </div>
            <div
              :class="{ active: alertShow === 'loginPhone' }"
              @click="$store.commit('setLoginState', 'loginPhone')"
            >
              手机号登录
            </div>
            <div style="color: #c1c1c1">
              <!-- 扫码登录 -->
            </div>
          </div>
          <div class="row">
            <div v-show="alertShow === 'login'">
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    @keyup="inputphone"
                    maxlength="11"
                    type="text"
                    v-model="userForm.username"
                    class="form-control"
                    placeholder="请输入手机号"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    type="password"
                    v-model="userForm.password"
                    class="form-control"
                    name="password"
                    placeholder="请输入登录密码"
                  />
                </div>
              </div>
            </div>
            <div v-show="alertShow === 'loginPhone'">
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    @keyup="inputphones"
                    v-model="loginPhone"
                    class="form-control"
                    name="account"
                    maxlength="11"
                    placeholder="请输入手机号"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-inline">
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="loginPhoneCode"
                      class="form-control"
                      name="verify_code"
                      maxlength="4"
                      placeholder="请输入验证码"
                    />
                    <button class="btn-yzm" @click="getPhoneCode">
                      {{
                        loginPhoneCodeTime ? loginPhoneCodeTime + "s" : "获取"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-show="alertShow === 'loginPhone'">
              <div class="text-center tab" @click="Phonelogin" @keyup.enter="Phonelogin">
                <button class="btn-sign">登录</button>
              </div>
            </div>
            <div class="col-md-12" v-show="alertShow === 'login'">
              <div class="text-center tab" @click="login" @keyup.enter="login">
                <button class="btn-sign">登录</button>
              </div>
            </div>
            <div class="links">
              <a
                @click="$store.commit('setLoginState', 'register')"
                class="pull-left reg tab"
                >注册幼儿园</a
              >
              <a
                class="pull-right forgot tab"
                @click="$store.commit('setLoginState', 'forgetPassword')"
                >忘记密码</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-reg" v-show="alertShow === 'register'">
      <div class="close2 tab" @click="close"></div>
      <div class="form-reg" id="form-reg">
        <div class="title">注册幼儿园</div>
        <div class="form tab active">
          <form class="form-vertical">
            <input type="hidden" name="group_id" value="2" />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="text"
                    v-model="register.contacts"
                    class="form-control"
                    name="contacts"
                    placeholder="联系人姓名"
                  />
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="text"
                    v-model="register.contactsMobile"
                    class="form-control"
                    name="mobile"
                    placeholder="手机号（登录账号）"
                  />
                </div>
                <div class="form-inline">
                  <div class="form-group yzm-group">
                    <span class="rq">*</span>
                    <input
                      type="text"
                      v-model="register.registerCode"
                      class="form-control"
                      name="verify_code"
                      placeholder="请输入验证码"
                      maxlength="4"
                    />
                    <div class="btn-yzm" @click="getRegisterCode">
                      {{ registerCodeTime ? registerCodeTime + "s" : "获取" }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="password"
                    style="display:block;width:0px;height:0px;opacity:0;"
                    placeholder=""
                    resize="none"
                  />
                  <input
                    type="password"
                    v-model="register.password"
                    class="form-control"
                    name="password"
                    placeholder="登录密码"
                  />
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="text"
                    style="display:block;width:0px;height:0px;opacity:0;"
                    name="email"
                    placeholder="E-mail"
                  />
                  <input
                    type="text"
                    v-model="register.contactsEmail"
                    class="form-control"
                    name="email"
                    placeholder="E-mail"
                  />
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <el-select
                    class="form-control"
                    popper-class="alert_select"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    v-model="register.type"
                    placeholder="请选择"
                  >
                    <el-option value="" label="请选择学校类型"></el-option>
                    <el-option value="0" label="公立">公立</el-option>
                    <el-option value="1" label="私立">私立</el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="text"
                    v-model="register.schoolName"
                    class="form-control"
                    name="school_name"
                    placeholder="学校名称"
                  />
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="number"
                    v-model="register.number"
                    class="form-control"
                    name="number"
                    placeholder="在校人数"
                  />
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <div
                    class="form-control"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <el-cascader
                      size="large"
                      popper-class="register_alert"
                      :options="areaoptions"
                      v-model="register.city"
                    >
                    </el-cascader>
                  </div>
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="register.address"
                    name="address"
                    placeholder="详细地址"
                  />
                </div>
                <div class="form-group">
                  <span class="rq">*</span>
                  <input
                    type="text"
                    v-model="register.xydm"
                    class="form-control"
                    name="xydm"
                    placeholder="社会统一信用代码或办学许可证号"
                  />
                </div>
              </div>
            </div>
            <div class="agree">
              <b>
                <input
                  tabindex="3"
                  v-model="isRegisterCheck"
                  type="checkbox"
                  id="IsRemember"
                  name="IsRemember"
                  value="true"
                  style="margin: 0"
                />
                &nbsp;&nbsp;我已阅读并同意<a
                  href="/全国足球特色幼儿园系统平台用户注册协议.pdf"
                  target="_Blank"
                  style="color: #4688f1"
                  >《全国足球特色幼儿园系统平台用户注册协议》
                </a>
              </b>
              <span class="ts"
                >此处为园长注册，老师不需要注册，老师账号由园长账号分配</span
              >
            </div>
            <div class="btn-next" @click="registerBtn">注册</div>
          </form>
        </div>
      </div>
    </div>
    <!-- 忘记密码 -->
    <div class="pop-forgot" v-show="alertShow === 'forgetPassword'">
      <div class="close2 tab" @click="close"></div>
      <div class="title">忘记密码</div>
      <div class="form">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="account"
                v-model="forget.forgetPhone"
                placeholder="请输入手机号"
                id="fogot_mobile"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-inline">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="verify_code"
                  v-model="forget.code"
                  placeholder="请输入验证码"
                  id="fogots_code"
                />
                <button class="btn-yzm tab" @click="getForgetPhoneCode">
                  {{
                    forget.forgetPhoneCodeTime
                      ? forget.forgetPhoneCodeTime + "s"
                      : "获取"
                  }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="action">
              <button type="submit" @click="next" class="btn-next">
                下一步
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 设置新密码 -->
    <div class="pop-forgot" v-show="alertShow === 'newPassword'">
      <div class="close2 tab" @click="close"></div>
      <div class="title">设置新密码</div>
      <div class="form">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="forget.newpassword"
                name="newpassword"
                placeholder="请设置新密码"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="forget.againpassword"
                name="passwordRepeat"
                placeholder="请确认新密码"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="action">
              <button class="btn-next tab" @click="forgetSubmit">提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { regionData } from "element-china-area-data";
export default {
  name: "login",
  components: {},
  props: {},
  watch: {
    "$store.state.loginState": {
      immediate: true,
      handler: function (val) {
        console.log(val);
        this.alertShow = val;
      },
    },
  },
  data() {
    return {
      userForm: {
        // 账号登录提交数据
        username: "",
        password: "",
      },
      loginPhone: "", // 手机号登录 所需的电话号码绑定
      loginPhoneCode: "", // 手机登录验证码
      loginPhoneCodeTime: "", // 手机号登录 验证码倒计时
      areaoptions: [...regionData], //注册 地区数据
      alertShow: "",
      register: {
        // 注册提交数据
        contacts: "",
        contactsMobile: "",
        contactsEmail: "",
        schoolName: "",
        number: "",
        address: "",
        xydm: "",
        city: "",
        type: "",
        password: "", // 注册密码
        registerCode: "",
      },
      // 忘记密码设置
      forget: {
        forgetPhone: "",
        code: "",
        newpassword: "",
        againpassword: "",
        forgetPhoneCodeTime: "", // 忘记密码 验证码倒计时
      },
      registerCodeTime: "", // 注册 验证码倒计时

      isRegisterCheck: false, // 是否同意协议
    };
  },
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true); //开启监听键盘按下事件
  },
  mounted() {},
  methods: {
    //按下enter键
    handleKeyDown(e){
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        if(this.alertShow === 'loginPhone'){
          this.Phonelogin();
        }else if(this.alertShow === 'login'){
          this.login();
        }
      }
    },
    close() {
      this.$store.commit("setLoginState", "");
    },
    // 账号登录
    login() {
      // console.log(this.userForm);
      if (!this.userForm.username) {
        this.$notice({
          message: "请输入登录账户",
        }).isShow();
        return;
      }else{
        if (!this.common.isMobile(this.userForm.username)) {
          this.$notice({
            message: "请输入正确格式的手机号码",
          }).isShow();
          return;
        }
      }
      if (!this.userForm.password) {
        this.$notice({
          message: "请输入登录密码",
        }).isShow();
        return;
      }
      let that = this;
      let userForm = that.userForm;
      this.$store.dispatch("login", { userForm: userForm, that });
    },
    //手机号数字校验
    inputphone() {
      this.userForm.username = this.userForm.username.replace(/[^\d]/g,'')
    },
    inputphones() {
      this.loginPhone = this.loginPhone.replace(/[^\d]/g,'')
    },
    // 手机号登录
    Phonelogin() {
      if (!this.loginPhone) {
        this.$notice({
          message: "请输入手机号码",
        }).isShow();
        return;
      }else{
        if (!this.common.isMobile(this.loginPhone)) {
          this.$notice({
            message: "请输入正确格式的手机号码",
          }).isShow();
          return;
        }
      }
      if (!this.loginPhoneCode) {
        this.$notice({
          message: "请输入验证码",
        }).isShow();
        return;
      }
      let that = this;
      this.$store.dispatch("PhoneLogin", {
        data: { phone: this.loginPhone, code: this.loginPhoneCode },
        that,
      });
    },
    registerBtn() {
      if (!this.register.contacts) {
        this.$notice({
          message: "请输入联系人名称",
        }).isShow();
        return;
      }else{
        if (!this.common.textNumberCheck(this.register.contacts,25)) {
          this.$notice({
            message: "请输入正确的联系人名称",
          }).isShow();
          return;
        }
      }
      if (!this.register.contactsMobile) {
        this.$notice({
          message: "请输入联系人手机号",
        }).isShow();
        return;
      }else{
        if (!this.common.isMobile(this.register.contactsMobile)) {
          this.$notice({
            message: "请输入正确格式的手机号码",
          }).isShow();
          return;
        }
      }
      if (!this.register.registerCode) {
        this.$notice({
          message: "请输入验证码",
        }).isShow();
        return;
      }
      if(!this.register.password){
        this.$notice({
          message: "请输入登录密码",
        }).isShow();
        return;
      }else{
        if (!this.common.validateLength(this.register.password,16)) {
          this.$notice({
            message: "请输入正确登录密码",
          }).isShow();
          return;
        }
      }
      if (!this.register.contactsEmail) {
        this.$notice({
          message: "请输入邮箱",
        }).isShow();
        return;
      }else{
        if(!this.common.validateLength(this.register.contactsEmail,25)){
          this.$notice({
            message: "请输入正确邮箱",
          }).isShow();
          return;
        }else{
          if (!this.common.isEmail(this.register.contactsEmail)) {
            this.$notice({
              message: "请输入正确邮箱",
            }).isShow();
            return;
          }
        }
      }
      if (!this.register.schoolName) {
        this.$notice({
          message: "请输入学校名称",
        }).isShow();
        return;
      }else{
        if(!this.common.validateLength(this.register.schoolName,50)){
          this.$notice({
            message: "请输入正确学校名称",
          }).isShow();
          return;
        }
      }
      if (!this.register.number) {
        this.$notice({
          message: "请输入现有学生人数",
        }).isShow();
        return;
      }
      if (!this.common.checkNumLen(this.register.number)) {
        this.$notice({
          message: "请输入正确的学生人数",
        }).isShow();
        return;
      }
      if (!this.register.address) {
        this.$notice({
          message: "请输入详细地址",
        }).isShow();
        return;
      }else{
        if(!this.common.validateLength(this.register.address,50)){
          this.$notice({
            message: "请输入正确详细地址",
          }).isShow();
          return;
        }
      }
      if (!this.register.city) {
        this.$notice({
          message: "请选择所在城市",
        }).isShow();
        return;
      }
      if (!this.register.xydm) {
        this.$notice({
          message: "请输入社会统一信用代码或办学许可证号",
        }).isShow();
        return;
      }else{
        if(!this.common.validateLength(this.register.xydm,127.5)){
          this.$notice({
            message: "请输入正确社会统一信用代码或办学许可证号",
          }).isShow();
          return;
        }
      }
      console.log(this.register.xydm.length);
      // if (
      //   /[\u4E00-\u9FA5]/g.test(this.register.xydm)
      // ) {
      //   this.$notice({
      //     message: "社会统一信用代码或办学许可证号格式不对",
      //   }).isShow();
      //   return;
      // }
      if (!this.register.type) {
        this.$notice({
          message: "请选择学校类型",
        }).isShow();
        return;
      }
      
      if (!this.isRegisterCheck) {
        this.$notice({
          message: "请同意《全国足球特色幼儿园系统平台用户注册协议》",
        }).isShow();
        return;
      }
      this.$store.dispatch("register", this);
    },
    //  获取注册手机号验证码
    getRegisterCode() {
      if (!this.registerCodeTime) {
        if (!this.register.contactsMobile) {
          this.$notice({
            message: "请输入联系人手机号",
          }).isShow();
          return;
        }
        if (!this.common.isMobile(this.register.contactsMobile)) {
          this.$notice({
            message: "请输入正确格式的手机号码",
          }).isShow();
          return;
        }

        this.registerCodeTime = 180;
        let timer = setInterval(() => {
          this.registerCodeTime = this.registerCodeTime - 1;
          if (this.registerCodeTime == 0) {
            this.registerCodeTime = "";
            clearInterval(timer);
          }
        }, 1000);
        this.api.user
          .registerSend({
            phone: this.register.contactsMobile,
          })
          .then((res) => {
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "发送成功",
              }).isShow();
            } else {
              this.$notice({
                message: res.message,
              }).isShow();
            }
          });
      } else {
        this.$notice({
          message: "请在倒计时结束后再次发送验证码",
        }).isShow();
      }
    },
    // 手机号登录获取验证码
    getPhoneCode() {
      if (!this.loginPhoneCodeTime) {
        if (!this.loginPhone) {
          this.$notice({
            message: "请输入联系人手机号",
          }).isShow();
          return;
        }
        if (!this.common.isMobile(this.loginPhone)) {
          this.$notice({
            message: "请输入正确格式的手机号码",
          }).isShow();
          return;
        }

        this.loginPhoneCodeTime = 180;
        let timer = setInterval(() => {
          this.loginPhoneCodeTime = this.loginPhoneCodeTime - 1;
          if (this.loginPhoneCodeTime == 0) {
            this.loginPhoneCodeTime = "";
            clearInterval(timer);
          }
        }, 1000);
        this.api.user
          .phoneLoginCode({
            phone: this.loginPhone,
          })
          .then((res) => {
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "发送成功",
              }).isShow();
            } else {
              this.$notice({
                message: res.message,
              }).isShow();
            }
          });
      } else {
        this.$notice({
          message: "请在倒计时结束后再次发送验证码",
        }).isShow();
      }
    },
    // 获取忘记密码验证码
    getForgetPhoneCode() {
      if (!this.forget.forgetPhoneCodeTime) {
        if (!this.forget.forgetPhone) {
          this.$notice({
            message: "请输入联系人手机号",
          }).isShow();
          return;
        }
        if (!this.common.isMobile(this.forget.forgetPhone)) {
          this.$notice({
            message: "请输入正确格式的手机号码",
          }).isShow();
          return;
        }

        this.forget.forgetPhoneCodeTime = 180;
        let timer = setInterval(() => {
          this.forget.forgetPhoneCodeTime = this.forget.forgetPhoneCodeTime - 1;
          if (this.forget.forgetPhoneCodeTime == 0) {
            this.forget.forgetPhoneCodeTime = "";
            clearInterval(timer);
          }
        }, 1000);
        this.api.park
          .verifymobileSend({
            phone: this.forget.forgetPhone,
          })
          .then((res) => {
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "发送成功",
              }).isShow();
            } else {
              this.$notice({
                message: res.message,
              }).isShow();
            }
          });
      } else {
        this.$notice({
          message: "请在倒计时结束后再次发送验证码",
        }).isShow();
      }
    },
    // 忘记密码下一步操作  验证code
    next() {
      if (!this.forget.forgetPhone) {
        this.$notice({
          message: "请输入联系人手机号",
        }).isShow();
        return;
      }
      if (!this.common.isMobile(this.forget.forgetPhone)) {
        this.$notice({
          message: "请输入正确格式的手机号码",
        }).isShow();
        return;
      }
      this.api.user
        .noLoginVerifymobileCode({
          phone: this.forget.forgetPhone,
          code: this.forget.code,
        })
        .then((res) => {
          if (res.flag) {
            this.$store.commit("setLoginState", "newPassword");
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
        });
    },
    // 忘记密码提交
    forgetSubmit() {
      if (!this.forget.newpassword) {
        this.$notice({
          message: "请输入新密码",
        }).isShow();
        return;
      }else{
        if (!this.common.validateLength(this.forget.newpassword,16)) {
          this.$notice({
            message: "请输入正确登录密码",
          }).isShow();
          return;
        }
      }
      if (!this.forget.againpassword) {
        this.$notice({
          message: "请再输入一次",
        }).isShow();
        return;
      }else{
        if (!this.common.validateLength(this.forget.againpassword,16)) {
          this.$notice({
            message: "请输入正确登录密码",
          }).isShow();
          return;
        }
      }
      if (this.forget.againpassword !== this.forget.newpassword) {
        this.$notice({
          message: "两次密码不同，请重新输入",
        }).isShow();
        return;
      }
      this.api.user
        .updatePwdByPhone({
          phone: this.forget.forgetPhone,
          newPassword: this.forget.newpassword,
        })
        .then(() => {
          this.$store.commit("setLoginState", "login");
        });
    },
  },
};
</script>
<style lang="scss">
.register_alert {
  z-index: 1 !important;
}

.alert_select {
  min-width: 400px !important;
  z-index: 1 !important;

  .el-select-dropdown__item {
    padding-left: 10px;
  }
}

.login {
  .el-cascader .el-input {
    font-size: 0.18rem;
  }

  .el-input--suffix .el-input__inner {
    padding-left: 0 !important;
    font-size: 0.18rem;
    font-family: Alibaba PuHuiTi !important;
    color: #000;
  }

  .el-cascader .el-input .el-input__inner:focus,
  .el-cascader .el-input.is-focus .el-input__inner {
    border: none;
    padding-left: 0 !important;
    font-family: Alibaba PuHuiTi !important;
    color: #000;
  }

  input {
    font-size: 0.18rem;
    font-family: Alibaba PuHuiTi !important;
  }

  .el-cascader {
    width: 100%;

    ::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.18rem;
    }

    :-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.18rem;
    }

    ::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.18rem;
    }

    :-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.18rem;
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.18rem;
    }
  }

  .el-cascader .el-input .el-input__inner {
    padding-left: 0 !important;
  }

  .el-input__inner {
    border: none;
  }

  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.18rem;
  }

  :-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.18rem;
  }

  ::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.18rem;
  }

  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.18rem;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.18rem;
  }
}
</style>
<style lang="scss" scoped>
.login {
  width: 120vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}

.pop-reg {
  position: absolute;
  @include trans-center();
}

/* 登录弹窗 */
.pop-login.layui-layer {
  width: 12rem !important;
  height: 6.5rem !important;
  background: url(https://v.prefootball.cn/image/default/1A09D3EC1834450B9FC5CB8C8FB0B902-6-2.png)
    center no-repeat;
  background-size: cover;
  border-radius: 0.15rem;
  position: absolute;
  @include trans-center();
}

.pop-login .layui-layer-setwin {
  right: inherit;
  left: 0;
  top: 0;
}

.close1,
.close2 {
  width: 0.54rem;
  height: 0.54rem;
  background: url("../assets/img/header/closewh.png") center no-repeat;
  background-size: cover;
  position: absolute;
  left: 0.6rem;
  right: inherit;
  top: 0.45rem;
}

.close2 {
  background: url("../assets/img/header/closeg.png") center no-repeat;
}

.pop-login .layui-layer-setwin a:hover {
  background-position: center;
}

.pop-login .head,
.pop-reg .head {
  font-family: AlibabaPuHuiTiR;
  font-size: 0.24rem;
  line-height: 0.3rem;
  color: #c1c1c1;
  display: table;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
}

.pop-login .head > div,
.pop-reg .head > div {
  display: inline-block;
  margin-left: 0.3rem;
  cursor: pointer;
}

.pop-login .head > div.active,
.pop-reg .head > div.active {
  color: #cc1a30;
}

.pop-login .layui-layer-content {
  box-sizing: border-box;
  height: 100%;
  padding: 0.8rem 0.9rem;
  float: right;
}

.pop-login .form-login {
  width: 5rem;
  background-color: #ffffff;
  box-shadow: 0px 12px 55px 10px rgba(41, 57, 74, 0.63);
  border-radius: 0.25rem;
  padding: 0.5rem 0.4rem;
  box-sizing: border-box;
}

.pop-login .form-group {
  display: flex;
  margin-bottom: 0.3rem;
  position: relative;
}

.pop-login .form-group .help-block {
  line-height: 0.4rem;
  color: #cc1a30;
  position: absolute;
  right: 0.25rem;
  top: 0.025rem;
  display: block;
  height: 0.4rem;
  font-size: 0.18rem;
}

.pop-login .form-control {
  flex: 1;
  height: 0.5rem;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
}

.pop-login .links {
  margin-top: 0.3rem;
  font-size: 0.18rem;
  line-height: 0.18rem;
  display: flex;
  justify-content: space-between;
}

.pop-login .links a {
  color: #cc1a30;
}

.pop-login .links a:hover {
  text-decoration: underline;
}

.pop-login .btn-sign {
  width: 100%;
  height: 0.5rem;
  background-color: #cc1a30;
  font-size: 0.24rem;
  color: #fff;
  font-family: AlibabaPuHuiTiR;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

.pop-login .btn-sign:focus {
  outline: none;
}

.pop-login .btn-yzm,
.pop-forgot .btn-yzm,
.pop-reg .btn-yzm {
  width: 1rem;
  height: 0.5rem;
  background-color: #f5d1d6;
  border: 0;
  font-size: 0.24rem;
  color: #cb1b31;
  line-height: 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  margin-left: 0.2rem;
  cursor: pointer;
}

/* 注册弹窗 */
.pop-reg {
  border-radius: 0.15rem !important;
  background-color: #fff;
  width: 12rem !important;
  // height: 7rem !important;
  padding-bottom: 20px;
}

.pop-reg .layui-layer-setwin {
  right: inherit;
  left: 0;
  top: 0;
}

.pop-reg .layui-layer-setwin a {
  width: 0.54rem;
  height: 0.54rem;
  background: url("../assets/img/header/closewh.png") center no-repeat;
  background-size: cover;
  left: 0.6rem;
  right: inherit;
  top: 0.45rem;
}

.pop-reg .layui-layer-setwin a:hover {
  background-position: center;
}

.pop-reg #form-reg > .title {
  font-size: 0.3rem;
  font-family: "AlibabaPuHuiTi";
  color: #cb1b31;
  line-height: 0.3rem;
  height: 0.3rem;
  margin: 0.6rem 0rem;
  text-align: center;
}

.pop-reg .layui-layer-content {
  box-sizing: border-box;
  height: 100%;
  /* padding: .6rem .9rem; */
}

.pop-reg #form-reg {
  padding: 0rem 0.45rem;
}

.pop-reg .row {
  display: table;
  width: 100%;
}

.pop-reg .col-md-6 {
  display: table-cell;
  width: 50%;
  box-sizing: border-box;
  padding: 0rem 0.45rem;
}

.pop-reg .form-group {
  display: flex;
  margin-bottom: 0.2rem;
  position: relative;
}

.pop-reg .form-group .help-block {
  line-height: 0.4rem;
  color: #cc1a30;
  position: absolute;
  right: 0.25rem;
  top: 0.025rem;
  display: block;
  height: 0.4rem;
  font-size: 0.18rem;
}

.pop-reg .yzm-group .help-block {
  right: 1.45rem;
}

.pop-reg .form-control {
  flex: 1;
  height: 0.5rem;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  padding: 0rem 0.3rem;
  border-radius: 0.25rem;
}

.pop-reg .city-picker-span {
  border: 0;
  height: 0.48rem !important;
  line-height: 0.48rem !important;
}

.pop-reg .city-picker-span > .title > span {
  color: #333;
  padding: 0;
  font-size: 0.18rem;
  border-radius: 0;
}

.pop-reg .city-picker-span > .placeholder {
  color: #f5d1d6;
}

.pop-reg .agree {
  padding-left: 0.45rem;
}

.pop-reg .agree .ts {
  color: #cc1a30;
  margin-left: 0.45rem;
}

.pop-reg .btn-next {
  width: 4.2rem;
  height: 0.5rem;
  background-color: #cc1a30;
  font-size: 0.24rem;
  color: #fff;
  font-family: AlibabaPuHuiTiR;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 0.4rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @include flex();
}

.pop-reg .rq {
  position: absolute;
  left: 0.15rem;
  top: 0.2rem;
  color: #cb1b31;
  font-size: 0.2rem;
  line-height: 0.2rem;
}

.pop-common {
  border-radius: 0.15rem !important;
  border: solid 10px #cc1a30;
}

.pop-common .layui-layer-content {
  padding: 0.4rem 0.45rem;
  font-size: 0.2rem;
  line-height: 0.35rem;
}

.pop-common .tit {
  font-size: 0.24rem;
  text-align: center;
  color: #cc1a30;
  margin-bottom: 0.2rem;
}

.pop-common .btn {
  background: #cb1b31;
  color: #fff;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 0.25rem;
  margin-top: 0.2rem;
  text-align: center;
  display: block;
}

.pop-common .btn-blue {
  background: #228cec;
  color: #fff;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 0.25rem;
  margin: 0.2rem 0;
  text-align: center;
  display: block;
}

/* 忘记密码弹窗 */
.pop-forgot {
  border-radius: 0.15rem !important;
  background-color: #fff;
  width: 6.45rem !important;
  height: 4.4rem;
  padding: 0.5rem;
  position: absolute;
  @include trans-center();
  box-sizing: border-box;
}

.pop-forgot .layui-layer-setwin {
  right: inherit;
  left: 0;
  top: 0;
}

// .pop-forgot .layui-layer-setwin a {
//     width: .54rem;
//     height: .54rem;
//     background: url(../images/closeg.png) center no-repeat;
//     background-size: cover;
//     left: .6rem;
//     right: inherit;
//     top: .45rem;
// }

.pop-forgot .layui-layer-setwin a:hover {
  background-position: center;
}

.pop-forgot .title {
  font-size: 0.3rem;
  font-family: "AlibabaPuHuiTi";
  color: #cb1b31;
  line-height: 0.3rem;
  height: 0.3rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

.pop-forgot .layui-layer-content {
  box-sizing: border-box;
  height: 100%;
  padding: 0.6rem 0.9rem;
}

.pop-forgot .form-group {
  display: flex;
  margin-bottom: 0.2rem;
  position: relative;
}

.pop-forgot .form-group .help-block {
  line-height: 0.4rem;
  color: #cc1a30;
  position: absolute;
  right: 0.25rem;
  top: 0.025rem;
  display: block;
  height: 0.4rem;
  font-size: 0.18rem;
}

.pop-forgot .form-control {
  flex: 1;
  height: 0.5rem;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
}

.pop-forgot .action {
  padding: 0rem 0.25rem;
}

.pop-forgot .btn-next {
  width: 100%;
  height: 0.5rem;
  background-color: #cc1a30;
  font-size: 0.24rem;
  color: #fff;
  font-family: AlibabaPuHuiTiR;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 0.2rem;
}
</style>

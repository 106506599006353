/* 公用全局过滤器 */

/* 日期格式转换 */
export function formatDate(input) {
  if (!input) {
    return ''
  }
  const curDate = new Date(input)
  const year = curDate.getFullYear()
  const month = curDate.getMonth() + 1
  const date = curDate.getDate()
  return `${year}-${addZero(month)}-${addZero(date)}`
}


/* 时间格式转换 */
export function formatTime(input) {
  if (!input) {
    return ''
  }
  const curDate = new Date(input)
  const year = curDate.getFullYear()
  const month = curDate.getMonth() + 1
  const date = curDate.getDate()
  const hour = curDate.getHours()
  const min = curDate.getMinutes()
  const second = curDate.getSeconds()
  return `${year}-${addZero(month)}-${addZero(date)} ${addZero(hour)}:${addZero(min)}:${addZero(second)}`
}

/*  日期补0 */
export function addZero(n) {
  return n >= 10 ? n : '0' + n
}

/* 将日期数组切分为开始时间和结束时间 */
export function splitDate(dataArr) {
  var obj = {
    start: '',
    end: ''
  }
  if (dataArr && dataArr.length !== 0) {
    obj.start = dataArr[0]
    obj.end = dataArr[1]
  }
  return obj
}


// / 时间戳转换成时间
export function timestampToTime (cjsj) {
  var date = new Date(cjsj) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
  var D = date.getDate() + ' '
  var h = date.getHours() + ':'
  var m = date.getMinutes() + ':'
  var s = date.getSeconds()
  return Y+M+D+h+m+s

}

export  function add0(m){return m<10?'0'+m:m }
export  function format(shijianchuo)
{
//shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo);
  var y = time.getFullYear();
  var m = time.getMonth()+1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
}






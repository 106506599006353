<template>
  <div
    id="app"
    style="min-width:1180px;"
  >
    <index />
  </div>
</template>

<script>
import index from '@/views/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body .el-table th.gutter{
  display: table-cell!important;
}
</style>

import { request } from "@/utils/request";

//  http://10.34.3.149:8090/atteSchool/getSchoolBaseinfo  学校信息
/**
 *
 * @param {schoolId} params
 * @returns
 */
const getSchoolBaseinfo = (params) => {
    return request({
      url: "/atteSchool/getSchoolBaseinfo",
      method: "get",
      params,
    });
  };
//  http://10.34.3.149:8090/statisticsInfo/findByType  学校信息-折线图数据
/**
 *
 * @param {schoolId} params
 * @param {type} params
 * @returns
 */
 const findByType = (params) => {
  return request({
    url: "/statisticsInfo/findByType",
    method: "get",
    params,
  });
};

//  http://10.34.3.149:8090/atteSchool/findGradesBySchoolId  园长账号获取班级列表
/**
 *
 * @param {schoolId} params
 * @returns
 */
const findGradesBySchoolId = (params) => {
  return request({
    url: "/atteSchool/findGradesBySchoolId",
    method: "get",
    params,
  });
};

//  http://10.34.3.149:8090/course/findClassGradeInfo  园长账号获取老师/学生/视频列表
/**
 *
 * @param {schoolId} params
 * @returns
 */
 const findClassGradeInfo = (params) => {
  return request({
    url: "/course/findClassGradeInfo",
    method: "get",
    params,
  });
};
//  http://10.34.3.149:8090/course/findCourseConditions 园长账号获取老师/学生/视频列表
/**
 *
 * @param {schoolId} params
 * @param {gradeId} params
 * @returns
 */
 const findCourseConditions = (params) => {
  return request({
    url: "/course/findCourseConditions",
    method: "get",
    params,
  });
};
//  http://10.34.3.149:8090/platformContract/applyExample 示范园申报信息提交
/**
 *
 * @param {schoolId} params
 * @param {invoiceName} params
 * @param {invoiceType} params
 * @param {imgUrl} params
 * @param {paddress} params
 * @param {bankAccount} params
 * @param {bankName} params
 * @param {companyName} params
 * @param {sp_contacter} params
 * @param {idCard} params
 * @param {spSchoolName} params
 * @param {creditCode} params
 * @param {spEmail} params
 * @returns
 */
 const applyExample = (data) => {
  return request({
    url: "/platformContract/applyExample",
    method: "POST",
    data,
  });
};

//  http://10.34.3.149:8090/atteSchool/findBehalf 查询示范园申请状态与审核状态
/**
 *
 * @param {schoolId} params
 * @returns
 */
 const findBehalf = (params) => {
  return request({
    url: "/atteSchool/findBehalf",
    method: "PUT",
    params,
  });
};
//  http://10.34.3.149:8090/footballApply/newUpdateStep1 V2.0 新足特园申请第一步
/**
 *
 * @param {schoolId} params
 * @returns
 */
 const newUpdateStep1 = (data) => {
  return request({
    url: "/footballApply/newUpdateStep1",
    method: "PUT",
    data,
  });
};
//  http://10.34.3.149:8090/footballApply/newUpdateStep2 V2.0 新足特园申请第二步
/**
 *
 * @param {schoolId} params
 * @returns
 */
 const newUpdateStep2 = (data) => {
  return request({
    url: "/footballApply/newUpdateStep2",
    method: "PUT",
    data,
  });
};
//V2.0 在线课程 老师账号
//  http://10.34.3.149:8090/newClassroomOnline/findOnlineClassByTeacherId 
const findOnlineClassByTeacherId = (id) => {
  return request({
    url: `/newClassroomOnline/findOnlineClassByTeacherId?teacherId=${id}`,
    method: "get",
  });
};

//V2.0新版本迭代 获取学校默认各项排名结果
/**
 *
 * @param {schoolId} params
 * @returns
 */
//  http://10.34.3.149:8090/atteSchool/getSchoolRanking
const getSchoolRanking = (params) => {
  return request({
    url: `atteSchool/getSchoolRanking`,
    method: "get",
    params
  });
};

//V2.0新版本迭代 老师账号获取上课班级
/**
 *
 * @param {teacherId} params 教师id
 * @returns
 */
 const findGradesOfTid = (params) => {
  return request({
    url: "course/findGradesOfTid",
    params,
    method: "get",
  });
};

//V2.0 园所引导信息查询
/**
 *
 * @param {schoolId} params 园所id
 * @returns
 */
 const findNavGuidanceBySchoolId = (params) => {
  return request({
    url: "/atteSchool/findNavGuidanceBySchoolId",
    params,
    method: "get",
  });
};
//V2.0 老师引导信息查询
/**
 *
 * @param {gradeId} params 班级id
 * @returns
 */
 const findNavGuidanceByGradeId = (params) => {
  return request({
    url: "/atteSchool/findNavGuidanceByGradeId",
    params,
    method: "get",
  });
};

//V2.0 购课状态
/**
 *
 * @param {schoolId} params 学校id
 * @returns
 */
 const findPaidCourseStatus = (params) => {
  return request({
    url: "/schoolClass/findPaidCourseStatus",
    params,
    method: "get",
  });
};

//V2.0 根据老师id查询老师信息中心
/**
 *
 * @param {id} params 老师id
 * @returns
 */
 const findTeachersInfo = (params) => {
   console.log(params,'ddddddddd+++++++++++++++++++')
  return request({
    url: "/atteTeacher/findTeachersInfo",
    params,
    method: "get",
  });
};

//V2.0 班级上课进度
/**
 *
 * @param {gradeId} params 班级id
 * @returns
 */
 const findGradeProgress = (params) => {
 return request({
   url: "/atteGrade/findGradeProgress",
   params,
   method: "get",
 });
};

//  http://localhost:8090/atteGrade/ findSelectBySchoolId?schoolId=11  园班级名称下拉框
/**
 * @param {schoolId} params
 * @returns
 */
 const findSelectBySchoolId = (params) => {
  return request({
    url: "/atteGrade/findSelectBySchoolId",
    params,
    method: "get",
  });
};

export default {
    getSchoolBaseinfo,
    findGradesBySchoolId,
    findClassGradeInfo,
    findCourseConditions,
    applyExample,
    findBehalf,
    newUpdateStep1,
    newUpdateStep2,
    findByType,
    findOnlineClassByTeacherId,
    getSchoolRanking,
    findGradesOfTid,
    findNavGuidanceBySchoolId,
    findNavGuidanceByGradeId,
    findPaidCourseStatus,
    findTeachersInfo,
    findGradeProgress,
    findSelectBySchoolId
};
/* jshint esversion: 6 */
/*
 * @Descripttion:
 * @version:
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-07 18:03:04
 * @LastEditors: 李金燕 lijinyan@juren.com
 * @LastEditTime: 2022-06-28 09:27:55
 * @FilePath: /官网迭代/src/utils/request.js
 */
import axios from "axios";
import store from "../store";
import { Message } from "element-ui";
// import constant from '../assets/js/constant.js'
import route from "../router";

// 环境的切换
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "http://47.104.234.128:10003/wisdom_school";
} else if (process.env.NODE_ENV == "production") {
  //axios.defaults.baseURL = "https://www.prefootball.cn:8090"; //生产
  axios.defaults.baseURL = "https://wisdom.prefootball.cn/wisdom_school";
  // axios.defaults.baseURL = "http://39.106.9.174:8083";
  //axios.defaults.baseURL = "http://8.142.27.13:8080";
  //axios.defaults.baseURL = "http://10.34.3.168:8086";
  // axios.defaults.baseURL = "http://uat.prefootball.cn:8086";
  // axios.defaults.baseURL = "http://8.142.27.13:8090";  // 本地环境
} else {
  axios.defaults.baseURL = "http://47.104.234.128:10003/wisdom_school";
}

const service = axios.create({
  // baseURL: constant.BASE_URL,
  timeout: 60000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (store.state.userInfo.token) {
      config.headers["token"] = store.state.userInfo.token; //store.state.userInfo.token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

//  响应拦截
service.interceptors.response.use(
  (res) => {
    if (
      res.data.code == 20012 ||
      res.data.code == 20009 ||
      res.data.errCode == 10000
    ) {
      // 判断token是否过期，过期跳转首页重新登录
      store.commit("setLoginState", "login");
      // Message.error("请重新登陆，您的登陆信息已过期");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("schoolInfo");
      localStorage.removeItem("teacherInfo");
      // store.commit("setLoginState", "");
      store.commit("setUserInfo", {});
      store.commit("setSchoolInfo", {});
      store.commit("setTeacherInfo", {});
      route.push({ path: "/home" });
    }
    return res;
  },
  (err) => {
    Message.error(err.response.data.message);
    return err;
  }
);

export function request(options) {
  return new Promise((resolve, reject) => {
    service(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

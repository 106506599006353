<template>
  <div class="dialog_block">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      @close="isClose"
      :visible.sync="show"
    >
      <img
        class="closeImg"
        src="../../assets/img/home/home/Icon_Closed.png"
        @click="isClose"
      />
      <div class="bb">
        <img
          v-if="type == 'load'"
          class="imgg"
          src="../../assets/img/common/Pic_JQQD.png"
        />
        <img
          v-if="type == 'normal'"
          class="imgg"
          src="../../assets/img/common/icon_hint_normal.png"
        />
      </div>
      <div class="message">{{ message }}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "tablePage",
  components: {},
  props: {
    type: {
      type: String,
      default: "normal",
    },
    message: {
      type: String,
      default: "",
    },
  },
  watch: {},
  data() {
    return {
      show: false,
      title: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    isShow() {
      this.show = true;
    },
    isClose() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.dialog_block .el-dialog {
  border-radius: 0.18rem;
  @include whb(560px, "", solid 18px #fff !important);
  display: flex;
  flex-direction: column;
  .closeImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .bb {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .imgg {
    width: 179px;
    height: 162px;
  }
  .message {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #bbbbbb;
    margin-bottom: 79px;
  }
  .close {
    @include whb(36px, 36px, solid 4px #fff);
    @include bds();
    @include bgc(#535151);
    @include bg("../../assets/img/common/closewh.png", 15px 15px);
    right: -15px;
    top: -15px;
    &:hover {
      @include bgc(#2e8ded);
    }
  }
  .con {
    color: $theme-red;
    margin: 37px 0 40px 35px;
  }
  .btnBox {
    @include flex();
    color: $theme-white;
  }
  .confirmBtn,
  .cancelBtn {
    @include whb(220px, 66px, solid 4px #fff);
    @include bds(33px);
    font-size: 26px;
  }
  .confirmBtn {
    background-color: #cb1b31;
    &:hover {
      background-color: #d03246;
    }
  }
  // #f5d1d6   #f6d6da 原确认
  // #cb1b31   #d03246 原取消
  .cancelBtn {
    @include bgc(#f5d1d6);
    margin-left: 40px;
    &:hover {
      @include bgc(#f6d6da);
    }
  }
  .el-dialog__header {
    padding: 0px 0px 0px 0px;
  }
  .el-dialog__body {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
  }
}
</style>

/*
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-03-10 10:15:22
 * @LastEditors: lily
 * @LastEditTime: 2022-05-02 17:44:44
 */
const ZH_ROUTERS = [
  {
    // 园所概况
    name: "zhSchoolindex",
    path: "zhSchoolindex",
    component: () => import("@/views/zhSchool/index.vue"),
    meta: { isDatav: true },
  },
  {
    // 园所概况
    name: "general",
    path: "general",
    component: () => import("@/views/zhSchool/routeCon/general.vue"),
    meta: { isDatav: true },
  },
  {
    // 页面设置
    name: "settngs",
    path: "settngs",
    component: () => import("@/views/zhSchool/routeCon/config.vue"),
    meta: { isDatav: true },
  },
  {
    // 名誉排行
    name: "ranking",
    path: "ranking",
    component: () => import("@/views/zhSchool/routeCon/ranking.vue"),
    meta: { isDatav: true },
  },
  {
    // 互动教学
    name: "teaching",
    path: "teaching",
    component: () => import("@/views/zhSchool/routeCon/teaching.vue"),
    meta: { isDatav: true },
  },
  {
    // 成长训练
    name: "drill",
    path: "drill",
    component: () => import("@/views/zhSchool/routeCon/drill.vue"),
    meta: { isDatav: true },
  },
  {
    // 比赛闯关
    name: "game",
    path: "game",
    component: () => import("@/views/zhSchool/routeCon/game.vue"),
    meta: { isDatav: true },
  },
  {
    // 智慧赛场
    name: "competition",
    path: "competition",
    component: () => import("@/views/zhSchool/routeCon/competition.vue"),
    meta: { isDatav: true },
  },
];

export default ZH_ROUTERS;

import { request } from '@/utils/request'

/**
 *首页 三条 新闻
 * @param {categoryIds} Array | 0 首页 1:动态 2:政策 3: 培训
 * @returns
 */
const findNews = (params) => {
  return request({
    url: '/article/defaultnews',
    params,
    method: 'get',
  })
}

// http://localhost:8090/article/news?id=30 查询新闻内容
const news = (params) => {
  return request({
    url: '/article/news',
    params,
    method: 'get',
  })
}

//  http://localhost:8090/article/search/{{page}}/{{size}}?type=1
const newList = (data,params,) => {
  return request({
    url: `/article/search/${params.page}/${params.size}`,
    params:data,
    method: 'get',
  })
}

/**
 * 全国省份查询
 * @param {pid} number 省市名称    0 全国
 * @returns
 */
 const findByPid = () => {
  return request({
    url: '/area/findByPid?pid=0',
    method: 'get',
  })
}

/**
 * 全国幼儿园积分展示
 * @param {province} String 省市名称    type=0  limit=10
 * @param {type} string 类型  0 积分排行  1活跃积分排行
 * @param {limit} number 条数  
 * @returns
 */
 const integralShow = (params) => {
  return request({
    url: '/atteSchoolRanking/show',
    params,
    method: 'get',
  })
}

/**
 * 疑难解答查询
 * @param {question} String 问题
 * @returns
 */
 const qaListSearch = (data) => {
  return request({
    url: '/qaList/search',
    data,
    method: 'post',
  })
}

export default {
  findNews,
  newList,
  integralShow,
  findByPid,
  qaListSearch,
  news
}

<template>
  <div class="report">
    <div class="report-tit">
      {{ $store.state.schoolInfo.atteSchool.schoolName }}-{{
        $route.query.schoolReport
      }}
    </div>
    <div class="report-sub-tit">
      <span>系统平台园所成长测评报告</span>
    </div>
    <div class="report-des">
      <!-- <p class="report-des-time">最近测试时间:<span>2021.08.09</span></p> -->
      <p class="report-des-cont">
        园所测评报告将该园所在系统平台内每个班级的测试情况汇总并分析，按照基础和运动测试分类，从完成率和合格率维度进行展示，以便园所更好地开
        展足球特色工作。
      </p>
    </div>
    <div class="report-sub-line-tit mt30">
      <p class="title-line"></p>
      <p class="title-name">测试概况</p>
    </div>
    <div class="report-des">
      <!-- <p class="report-des-time">最近测试时间:<span>2021.08.09</span></p> -->
      <p class="report-des-cont">
        本次测试园所共有{{ gkInfo.gradeSum }}个班参加，{{
          gkInfo.stuSum || 0
        }}个幼儿参与；共有男生{{ gkInfo.boySum }}人，女生{{
          gkInfo.girlSum || 0
        }}人；小班{{ gkInfo.grade2StuCount || 0 }}人，中班{{
          gkInfo.grade1StuCount || 0
        }}人，大班{{ gkInfo.grade0StuCount || 0 }}人。总数据量信息为{{
          gkInfo.TestSum || 0
        }}条，分别为体质测试数据{{ gkInfo.TestType0 || 0 }}条，运动测试数据{{
          gkInfo.TestType1 || 0
        }}条。
      </p>
    </div>
    <div class="testchart">
      <div class="testchart-box">
        <div id="testchart-1"></div>
      </div>
      <div class="testchart-box">
        <div id="testchart-2"></div>
      </div>
    </div>
    <div class="report-prescription">
      <p class="report-name">测试完成率</p>
      <div class="report-prescription-table">
        <p>体质测试</p>
        <table class="layui-table table-style" lay-skin="line">
          <thead>
            <tr>
              <th></th>
              <th v-for="(item, index) in testingLogs0" :key="index">
                {{ item.testingFoundation.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>园所</b></td>
              <td v-for="(item, index) in testingLogs0" :key="index">
                {{ item.avgData }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="report-prescription-table">
        <p>运动测试</p>
        <table class="layui-table table-style" lay-skin="line">
          <thead>
            <tr>
              <th></th>
              <th v-for="(item, index) in testingLogs1" :key="index">
                {{ item.testingSports.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>园所</b></td>
              <td v-for="(item, index) in testingLogs1" :key="index">
                {{ item.avgData }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="report-name mt30">评价占比</p>
      <div class="report-prescription-table">
        <table
          class="layui-table table-style"
          lay-skin="line"
          style="border-radius: 4px"
        >
          <thead>
            <tr>
              <th></th>
              <th v-for="(item, index) in pjzbList.dbselectSix" :key="index">
                {{ item.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>合格率</b></td>
              <td v-for="(item, index) in pjzbList.dbselectSix" :key="index">
                {{ item.perValue == "0%" ? "--" : item.perValue }}
              </td>
            </tr>
            <tr>
              <td><b>优秀率</b></td>
              <td v-for="(item, index) in pjzbList.dbselectEight" :key="index">
                {{ item.perValue == "0%" ? "--" : item.perValue }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "schoolReport",
  data() {
    return {
      gkInfo: {},
      testingLogs0: [],
      testingLogs1: [],
      pjzbList: [],
    };
  },
  methods: {
    // 体质测试
    getGK() {
      let data = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      this.api.pes.findSchoolReports(data).then((res) => {
        this.loading = false;
        this.gkInfo = res.data;
        this.$nextTick(() => {
          this.echarts1();
          this.echarts2();
        });
        this.getPJZB();
      });
    },
    getWCL() {
      let data = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      this.api.pes.findSchoolTestPercent(data).then((res) => {
        this.testingLogs0 = res.data.testingLogs0 || [];
        this.testingLogs1 = res.data.testingLogs1 || [];
      });
    },
    getPJZB() {
      let data = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      this.api.pes.findPerEvaluation(data).then((res) => {
        if (res.flag) {
          this.pjzbList = res.data;
        }
      });
    },
    async getAVG() {
      let data1 = {
        semesterId: this.$route.query.semesterId,
        isEnd: this.$route.query.isEnd,
      };
      let data2 = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      let countryList = await this.api.pes.findCountyTestSportsAvg(data1);
      let provinceList = await this.api.pes.findProvinceTestSportsAvg(data2);
      let schoolList = await this.api.pes.findSchoolTestSportsAvg(data2);

      // 处理平均数据
      let arr = [];
      countryList.data.forEach((item1) => {
        provinceList.data.forEach((item2) => {
          schoolList.data.forEach((item3) => {
            if (
              item1.testingSports.testNo === item2.testingSports.testNo &&
              item1.testingSports.testNo === item3.testingSports.testNo
            ) {
              let data = {
                title: item1.testingSports.title,
                country: item1.avgData,
                province: item2.avgData,
                school: item3.avgData,
              };
              if (data.title === "平衡") {
                let left = { ...data };
                let right = { ...data };
                left.title = "平衡-左";
                left.country = left.country.split("/")[0] || 0;
                left.province = left.province.split("/")[0] || 0;
                left.school = left.school.split("/")[0] || 0;
                right.title = "平衡-右";
                right.country = right.country.split("/")[1] || 0;
                right.province = right.province.split("/")[1] || 0;
                right.school = right.school.split("/")[1] || 0;
                arr.push(left);
                arr.push(right);
              } else {
                arr.push(data);
              }
            }
          });
        });
      });
      this.echarts3(arr);
    },
    echarts1() {
      var data_1 = [
        { value: this.gkInfo.girlSum, name: "女生" },
        { value: this.gkInfo.boySum, name: "男生" },
      ];
      console.log(data_1);
      var testchart_1 = echarts.init(document.getElementById("testchart-1"));
      var option_1 = {
        title: {
          text: "集体中男女比例",
          left: 10,
          top: 10,
          textStyle: {
            color: "#000",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          right: 10,
          top: 10,
        },
        grid: {
          left: 0,
          right: 0,
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            label: {
              formatter: "{d}%",
            },
            color: ["#FF6E77", "#4F7CFF"],
            data: data_1,
          },
        ],
      };
      if (option_1 && typeof option_1 === "object") {
        testchart_1.setOption(option_1, true);
      }
    },
    echarts2() {
      var data_2 = [
        {
          name: "男生",
          type: "bar",
          barWidth: 25,
          stack: "总数",
          data: [
            this.gkInfo.grade2StuCount - this.gkInfo.grade2GirlCount,
            this.gkInfo.grade1StuCount - this.gkInfo.grade1GirlCount,
            this.gkInfo.grade0StuCount - this.gkInfo.grade0GirlCount,
          ],
          color: "#4F7CFF",
        },
        {
          name: "女生",
          type: "bar",
          stack: "总数",
          data: [
            this.gkInfo.grade2GirlCount,
            this.gkInfo.grade1GirlCount,
            this.gkInfo.grade0GirlCount,
          ],
          color: "#FF6E77",
        },
      ];
      var testchart_2 = echarts.init(document.getElementById("testchart-2"));
      var option_2 = {
        title: {
          text: "大中小班男女数量",
          left: 10,
          top: 10,
          textStyle: {
            color: "#333333",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["男生", "女生"],
          right: 10,
          top: 10,
        },
        grid: {
          left: 20,
          right: 20,
          containLabel: true,
          height: "64%",
        },
        yAxis: {
          type: "value",
          min: 0,
          minInterval: 1,
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#BBBBBB",
              fontSize: "12",
            },
          },
          axisTick: {
            lineStyle: {
              width: 0,
            },
          },
        },
        xAxis: {
          type: "category",
          data: ["小班", "中班", "大班"],
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#000",
              fontSize: "14",
            },
          },
          axisTick: {
            lineStyle: {
              width: 0,
            },
          },
        },
        series: data_2,
      };
      if (option_2 && typeof option_2 === "object") {
        testchart_2.setOption(option_2, true);
      }
    },
    echarts3(res) {
      var data = res;
      data.forEach((item) => {
        item.country = parseFloat(item.country);
        item.province = parseFloat(item.province);
        item.school = parseFloat(item.school);
      });
      var data_avgbar = [["product", "全国平均值", "全省平均值", "全园平均值"]];
      for (var i = 0; i < data.length; i++) {
        let title = data[i]["title"];
        let country = data[i]["country"];
        let province = data[i]["province"];
        let school = data[i]["school"];
        data_avgbar.push([title, country, province, school]);
      }
      var myChart_avgbar = echarts.init(document.getElementById("avgbar"));
      var option_avgbar = {
        tooltip: {},
        dataset: {
          source: data_avgbar,
        },
        legend: {
          width: 300,
          data: ["全国平均值", "全省平均值", "全园平均值"],
          right: 0,
          bottom: 60,
          orient: "vertical",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#000",
              fontSize: "14",
            },
            interval: 0,
          },
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 2,
            },
          },
        },
        grid: {
          left: 20,
          right: 180,
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#656565",
              fontSize: "12",
            },
          },
          axisTick: {
            lineStyle: {
              width: 0,
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            color: "#db606f",
          },
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            color: "#284691",
          },
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            color: "#e1be46",
          },
        ],
      };
      if (option_avgbar && typeof option_avgbar === "object") {
        myChart_avgbar.setOption(option_avgbar, true);
      }
    },
  },
  mounted() {
    console.log("1111111");
    this.getGK();
    this.getWCL();
    this.getAVG();
  },
};
</script>
<style scoped lang="scss">
.mt30 {
  margin-top: 30px;
}
.report {
  width: 1354px;
  margin: 0 auto;
  padding: 0 40px;
  background: #fff;
  .report-name {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #ba3037;
  }
  .report-tit {
    padding: 38px 0 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .report-sub-tit {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    > span {
      padding: 0 10px;
    }
  }
  .report-des {
    margin-top: 16px;
    .report-des-time {
      font-size: 16px;
      font-weight: 400;
      color: #888888;

      > span {
        color: #333333;
      }
    }
    .report-des-cont {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      // text-indent: 2em;
    }
  }
  .report-body {
    margin-top: 26px;
    .report-body-cont {
      //@include flex(row, space-between, center);
      flex-wrap: wrap;
      display:flex;
      .report-body-cont-item {
        margin-bottom: 20px;
        width: 260px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        margin-right:1.5%;
        .report-body-cont-item-top {
          @include flex(row, flex-start, center);
          > img {
            margin: 10px 0 0 15px;
            width: 75px;
            height: 82px;
          }
          .right {
            margin-left: 4px;
            .name {
              padding-top: 10px;
              font-size: 18px;
              color: #333333;
            }
            .value {
              margin-top: 14px;
              font-size: 30px;
              font-weight: bold;
              color: #333333;
              > span {
                font-size: 16px;
                color: #888888;
              }
            }
          }
        }
        .report-body-cont-item-bottom {
          margin-top: 12px;
          border-top: 1px solid #f1f1f1;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #888888;
          padding-left: 20px;
        }
      }
       .report-body-cont-item:nth-child(4n){
       margin-right:0;}
    }
  }
  .report-motion {
    margin-top: 40px;
    .report-motion-tit {
      @include flex(row, space-between, center);
      .report-motion-tit-legend {
        font-size: 14px;
        color: #333333;

        > p {
          margin-left: 10px;
          display: inline-block;
        }
        .fail {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #ff6e77;
            border-radius: 2px;
          }
        }
        .good {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #71e5b8;
            border-radius: 2px;
          }
        }
        .excellent {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #4f7cff;
            border-radius: 2px;
          }
        }
      }
    }
    .report-motion-cont {
      margin-bottom: 20px;
      // @include flex(row,space-between,center);
      // flex-wrap: wrap;
    }
  }
  .report-prescription {
    margin-top: 40px;
    padding-bottom: 100px;
    .report-prescription-des {
      margin-top: 0;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      text-indent: 2em;
    }
  }
  .report-prescription-table {
    margin-top: 20px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 4px;

    > p {
      padding: 8px 0 0 0;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      opacity: 0.9;
    }
    .table-style {
      border-collapse: collapse;
      // border-spacing: 0;
      margin-top: 6px;
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      /* 消除掉外边框 */
      // border-style:hidden ;
      thead {
        tr {
          height: 42px;
          background: #ba3037;
          color: #ffffff;
          th {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      tbody {
        tr td {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .tips {
    margin-top: 30px;
    padding-bottom: 60px;
    .tips-cont {
      line-height: 28px;
      font-size: 16px;
      color: #333333;
      opacity: 0.9;
    }
  }
  .report-sub-line-tit {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    .title-line {
      margin-right: 12px;
      margin-top: 3px;
      float: left;
      width: 4px;
      height: 18px;
      background: #ba3037;
      border-radius: 2px;
    }
    .title-name {
      margin-top: -2px;
      float: left;
    }
  }
  .report-motion-cont {
    @include flex(row, flex-start, center);
    .report-motion-cont-chart {
      width: 640px;
      @include flex(row, space-between, center);
      .chart-box {
        width: 320px;
        text-align: center;
        .chart {
          margin: 0 auto;
          width: 200px;
          height: 200px;
        }
      }
    }
  }
  .testchart {
    margin-top: 20px;
    @include flex(row, flex-start, center);
    .testchart-box {
      margin-right: 20px;
      width: 420px;
      height: 240px;
      background: #ffffff;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      > div {
        width: 420px;
        height: 240px;
      }
    }
  }
}
</style>
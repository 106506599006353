import { request } from "@/utils/request_sport";

//查询积分数据
const getRankList = (params) => {
  return request({
    url: `/v2/getScreenInfo`,
    params,
    method: "get",
  });
};
//查询单项积分数据
const getSingleRankList = (params) => {
  return request({
    url: `/v2/getScreenSingleInfo`,
    params,
    method: "get",
  });
};
export default {
  getRankList,
  getSingleRankList
};

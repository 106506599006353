/* jshint esversion: 6 */
/*
 * @Descripttion:
 * @version:
 * @Author: 张磊
 * @Date: 2021-05-10 19:35:16
 * @LastEditors: lily
 * @LastEditTime: 2022-05-02 15:30:10
 * @FilePath: /官网迭代/src/store/mutations.js
 */
import route from "../router";
import constant from "@/assets/js/constant";
export default {
  setHeaderTabTag(state, value) {
    if (state.headerTabTag !== value) {
      state.headerTabTag = value;
      route.push({
        path: constant.publicTabList[value].path,
      });
    }
  },
  setSchoolBar(state) {
    state.schoolBar = [...constant.schoolBar];
  },
  setUserInfo(state, res) {
    state.userInfo = res;
    localStorage.setItem("userInfo", JSON.stringify(res));
  },
  setLoginState(state, res) {
    console.log(typeof res,'pppppppppppppp');
    if(typeof res === 'string'){
      state.loginState = res;
    }else{  //多参数
      state.loginState = res[0];
      if(res[1]) state.goUrl = res[1];
      console.log(state.goUrl,'获取到了')
    }
  },
  clearLoginUrlState(state){
    state.goUrl = '';
  },
  setSchoolInfo(state, res) {
    state.schoolInfo = res;
    localStorage.setItem("schoolInfo", JSON.stringify(res));
  },
  setTeacherInfo(state, res) {
    state.teacherInfo = res;
    localStorage.setItem("teacherInfo", JSON.stringify(res));
  },
  setFindTeachersInfo(state, res) {
    state.findteachersInfo = res;
    localStorage.setItem("findTeachersInfo", JSON.stringify(res));
  },
  setOpenBuy(state, res) {
    state.isBuy = res;
  },
  setBuyStep(state, res) {
    state.buyStep = res;
    state.isBuy = true;
  },
  setFirstLogin(state, res) {
    state.firstLogin = res;
  },
  setIsHome(state, res) {
    state.isHome = res;
  },
  setIsCloseXjzc(state, res) {
    state.isCloseXjzc = res;
  },
  setFullData(state, res) {
    console.log(res, "大屏数据");
    state.fulllData.schoolCount = res.schoolCount.num;
    state.fulllData.schoolKind = res.schoolKind;
    state.fulllData.schoolPie = res.schoolPie;
    state.fulllData.SchoolCountDaily = res.SchoolCountDaily;
    state.fulllData.top5data = res.top5data;
    state.fulllData.mapData = res.mapData;
  },
  setRefres(state, res) {
    state.refreshList = res;
  },
  //superviseId
  setSuperviseId(state, res) {
    console.log("res", res);
    state.superviseId = res;
  },
  setSpotrsindex(state, res){
    state.spotrsindex = res;
   
  },
  isEnableEnroll(state, res){
    state.isEnableEnroll = res;
  },
  selectPage(state, res){
    state.prcstatus = res;
  },
  setAuthorityState(state,res){
    state.authorityStatus = res;
  },
  //切换主题风格
  upDate(state,res){
    state.skinpeeler = res
  }
};

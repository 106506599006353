/* jshint esversion: 6 */
/*
 * @Descripttion:
 * @version:
 * @Author: 张磊
 * @Date: 2021-05-07 18:03:04
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-22 08:48:05
 * @FilePath: /官网迭代/src/views/home/route.js
 */
const HOME_ROUTERS = [
  {
    name: "guide",
    path: "/guide",
    component: () => import("@/views/home/guide.vue"),
  },
  {
    name: "shenbao",
    path: "/shenbao",
    component: () => import("@/views/shenbao.vue"),
    meta: { isAppyPost: true },
  },
  {
    name: "xjShenbao",
    path: "/xjShenbao",
    component: () => import("@/views/shenbaoXj.vue"),
    meta: { isAppyPost: true },
  },
  {
    name: 'scanCode',
    path: '/scanCode',
    component: () => import('@/views/scanCode.vue'),
    meta: { isH5: true },
  },
  {
    name: "news",
    path: "/news",
    component: () => import("@/views/home/news.vue"),
  },
  {
    name: "newsDetail",
    path: "/newsDetail",
    component: () => import("@/views/home/newsDetail.vue"),
  },

  {
    name: "score",
    path: "/score",
    component: () => import("@/views/home/score.vue"),
  },
  {
    name: "question",
    path: "/question",
    component: () => import("@/views/home/question.vue"),
  },
  {
    name: "steup",
    path: "/steup",
    component: () => import("@/views/home/steup.vue"),
  },
  {
    name: "expert",
    path: "/expert",
    component: () => import("@/views/home/expert.vue"),
  },
  {
    name: "organ",
    path: "/organ",
    component: () => import("@/views/home/organ.vue"),
  },
  {
    name: "adviser",
    path: "/adviser",
    component: () => import("@/views/home/adviser.vue"),
  },
  {
    name: "instructions",
    path: "/instructions",
    component: () => import("@/views/home/instructions.vue"),
  },
];

export default HOME_ROUTERS;

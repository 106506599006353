import { request } from "@/utils/request";

//登录
const login = (data) => {
  return request({
    url: "/login",
    method: "post",
    data,
  });
};

// http://localhost:8090/user/updatePassword 修改密码
const updatePassword = (data) => {
  return request({
    url: "/user/updatePassword",
    method: "post",
    data,
  });
};
// http://localhost:8090/user/findUserCenter 账户中心
const findUserCenter = () => {
  return request({
    url: "/user/findUserCenter",
    method: "get",
  });
};

// http://localhost:8090/atteSchool/schoolRegister?school&password=123456&groupId=2 注册
/**
 *
 * @param {school} data 注册的学校信息【对象AtteSchool 】
 * @param {password} data 用户设置的密码
 * @param {groupId} data 用户组ID  园长注册为2
 * @returns
 */
const schoolRegister = (data) => {
  return request({
    url: "/atteSchool/schoolRegister",
    data,
    method: "post",
  });
};

// http://localhost:8090/atteTeacher/2  查询教师信息
const getTeacherInfo = (id) => {
  return request({
    url: `/atteTeacher/${id}`,
    method: "get",
  });
};

// http://localhost:8090/sms/registerSend?phone=13651070379 注册手机号验证
/**
 *
 * @param {phone} params 手机号
 * @returns
 */
const registerSend = (params) => {
  return request({
    url: `/sms/registerSend`,
    method: "get",
    params,
  });
};

// http://localhost:8090/sms/changePassSend?phone=13651070379 转让园长发送验证码
/**
 *
 * @param {phone} params 手机号
 * @returns
 */
const changePassSend = (params) => {
  return request({
    url: `/sms/changePassSend`,
    method: "get",
    params,
  });
};

// http://localhost:8090/sms/loginSend?phone=13651070379 手机号登陆获取验证码
/**
 *
 * @param {phone} params 手机号
 * @returns
 */
const phoneLoginCode = (params) => {
  return request({
    url: `/sms/loginSend`,
    method: "get",
    params,
  });
};

// http://localhost:8090/phoneLogin
/**
 * 手机号登录
 * @param {phone} params 手机号
 * @param {code} params 验证码
 * @returns
 */
const phoneLogin = (data) => {
  return request({
    url: `/phoneLogin`,
    method: "post",
    data,
  });
};

// http://localhost:8090/sms/verifymobileCode?phone=&code=   登录：验证码是否正确
const verifymobileCode = (params) => {
  return request({
    url: `/sms/verifymobileCode`,
    method: "get",
    params,
  });
};

// http://localhost:8090/sms/noLoginVerifymobileCode?phone=&code=  no：验证码是否正确
const noLoginVerifymobileCode = (params) => {
  return request({
    url: `/sms/noLoginVerifymobileCode`,
    method: "get",
    params,
  });
};

//http://localhost:8090/user/updatePwdByPhone 忘记密码重置密码
/**
 * 手机号登录
 * @param {phone} params 手机号
 * @param {newPassword} params 密码
 * @returns
 */
const updatePwdByPhone = (data) => {
  return request({
    url: `/user/updatePwdByPhone`,
    method: "post",
    data,
  });
};

// http://localhost:8090/user/schollDataCenter 示范园审核不通过回显数据
const schollDataCenter = (id) => {
  return request({
    url: `/atteSchool/${id}`,
    method: "get",
  });
};


// 权限
const checkAuth = (params) => {
  return request({
    url: `/product/service/checkAuth`,
    method: "get",
    params
  });
};

export default {
  login,
  updatePassword,
  findUserCenter,
  schoolRegister,
  getTeacherInfo,
  registerSend,
  changePassSend,
  phoneLoginCode,
  phoneLogin,
  verifymobileCode,
  noLoginVerifymobileCode,
  updatePwdByPhone,
  schollDataCenter,
  checkAuth,
};

<template>
  <div v-if="$route">
    <div v-if="$route.meta.isH5">
      <router-view style="min-height: 300px" />
    </div>
    <div v-else-if="$route.meta.isDatav">
      <router-view style="min-height: 300px" />
    </div>
    <div v-else-if="$route.meta.isRank">
      <router-view />
      <FooterBlock :data_obj="xiaoshi" />
      <login />
    </div>
    <div v-else-if="$route.meta.issports" style="background:#F2F2F2FF;">
    
      <issports ></issports> <!--   小小运动会 -->
      <router-view style="min-height: 300px" :data_obj="xiaoshi" />
      <FooterBlock :data_obj="xiaoshi" /> <login />
    </div>
    <div v-else>
      <publicBenefitHeader v-if="$route.meta.isPublicBenefit" />
      <adminHeader v-else-if="$route.meta.isAdmin" />
      <schoolNav v-else-if="$route.meta.isSchool" />
      <isuper v-else-if="$route.meta.isSuper"></isuper><!--如果督导就显示这个头部-->
    
      <HeaderBlock v-else-if="!$route.meta.isAppyPost" @aaa="bbb" />

      <router-view style="min-height: 300px" :data_obj="xiaoshi" />
      <FooterBlock :data_obj="xiaoshi" /> <login />
      <buyAlert v-if="$store.state.isBuy" />
    </div>
    <authority></authority>
  </div>
</template>

<script>
import HeaderBlock from "@/components/header.vue";
import FooterBlock from "@/components/footer.vue";
import login from "@/components/login.vue";
import authority from "@/components/authority.vue";
import publicBenefitHeader from "@/components/publicBenefitHeader.vue";
import adminHeader from "@/components/adminHeader.vue";
import buyAlert from "@/components/buyClass.vue";
import schoolNav from "@/components/schoolNav.vue";
import isuper from "@/components/isSuper.vue"
 import issports from "@/components/sports.vue"
export default {
  name: "Index",
  components: {
     HeaderBlock,
    FooterBlock,
    publicBenefitHeader,
    adminHeader,
    login,
    buyAlert,
    schoolNav,
    isuper,
    issports,
    authority
  },
  data() {
    return {
      xiaoshi: null,
      sports:true
    };
  },
  mounted() {
    console.log(this.$route.meta);
  },
  methods: {
    bbb(obj) {
      console.log("obj-------", obj);
      this.xiaoshi = obj;
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="header_admin_page">
      <div class="top">
        <div class="logo_block">
          <div class="logo"></div>
          <div class="title">全国足球特色幼儿园系统平台</div>
        </div>
        <div class="prefootball">-PREFOOTBALL-</div>
      </div>
      <!-- <div v-if="!$store.state.userInfo.token" class="login tab" @click="login">
        <span class="user"></span>
        <div class="title">注册/登录</div>
      </div>
      <div class="login tab" @click="toAdmin" v-else>
        <div class="title">校园中心</div>
      </div> -->
    </div>
    <div class="tab_bar">
      <div class="tab" @click="toJump('/shenbao')">足特园</div>
      <div class="tab" @click="toJump('/park')">示范园</div>
      <div class="tab" @click="toJump('/publicBenefit')">公益</div>
      <div class="tab" @click="toJump('/course_class')">上课</div>
      <div class="tab" @click="toJump('/test')">测试</div>
      <div class="tab" @click="toJump('/integral')">积分</div>
      <div class="tab" @click="toJump('/unfairness')">督导组</div>
      <div class="tab" @click="toJump('/pes')">测试报告</div>
      <div class="tab" @click="toJump('/sport')">小小运动会</div>
      <div class="tab" @click="toJump('/train')">师资培训</div>
      <div class="tab" @click="toJump('/shop')">商城</div>
      <div class="tab" @click="toJump('/instructions')">操作指引</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerAdmin",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    toJump(val) {
      if (this.$route.path !== val) {
        // this.$router.push({
        //   path: val,
        // });
        if (val === "/park") {
          if (!this.$store.state.userInfo.teacherId) {
            this.api.park
              .platformContractIndex({ id: this.$store.state.userInfo.userId })
              .then((res) => {
                if (res.flag) {
                  localStorage.setItem("parkinfo", JSON.stringify(res.data));
                  this.$router.push({
                    path: val,
                  });
                } else {
                  if (this.$store.state.userInfo.token) {
                    this.$notice({
                      message: res.message,
                    }).isShow();
                  } else {
                    this.$store.commit("setLoginState", "login");
                  }
                }
              });
          } else {
            this.$notice({
              message: "请使用园长账号访问示范园专区",
            }).isShow();
          }
        } else if(val === "/shenbao"){
          let schoolid = JSON.parse(localStorage.getItem("userInfo"));
          if (schoolid.token == undefined) {
            this.$store.commit("setLoginState", "login");
          } else {
            if (this.$store.state.userInfo.teacherId == null || this.$store.state.userInfo.teacherId == "") {
              this.api.admin
                .findFootballApplayStatus(this.$store.state.userInfo.schoolId)
                .then((res) => {
                  if (res.data.apply == 0) {
                    this.$router.push({
                      path: val,
                    });
                  } else if (res.data.apply == 1) {
                    this.$router.push({
                      path: val,
                    });
                  } else if (res.data.apply == 2) {
                    this.$router.push({
                      path: val,
                    });
                  } else if (res.data.apply == 4) {
                    this.$notice({
                      message: "不具备申请足特园的要求!",
                    }).isShow();
                  } else if (res.data.apply == 3) {
                    this.$notice({
                      type: "success",
                      message: "您已经是足特园用户不需要重复申请.",
                    }).isShow();
                  } else {
                    this.$router.push({
                      path: val,
                    });
                  }
                });
            } else {
              this.$notice({
                type: "success",
                message: "请用园长账号进行申报",
              }).isShow();
              return;
            }
          }
        } else {
          this.$router.push({
            path: val,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header_admin_page {
  width: 100%;
  position: relative;
  height: 383px;
  box-sizing: border-box;
  @include flex();
  @include bg("../assets/img/header/top_admin_banner.png");

  .login {
    width: 236px;
    height: 53px;
    background: #ffffff;
    border-radius: 22px;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #c2282a;
    @include flex();
    position: absolute;
    top: 35px;
    right: 76px;

    .user {
      width: 40px;
      height: 40px;
      @include bg("../assets/img/header/user.png");
    }
  }

  .top {
    .logo {
      width: 137px;
      height: 137px;
      margin-right: 17px;
      @include bg("../assets/img/header/logo.png");
    }

    .title {
      font-size: 68px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .logo_block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prefootball {
    font-size: 36px;
    font-family: Krona One;
    font-weight: 400;
    color: #ffffff;
    line-height: 52px;
    text-align: center;
    position: relative;
    bottom: 20px;
    letter-spacing: 5px;
  }
}

.tab_bar {
  width: 100%;
  height: 85px;
  background: #fff;
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #4a4341;
  @include flex();

  div {
    @include flex();
    padding: 0 25px;
    position: relative;
  }

  .active:after {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    bottom: -5px;
    border-bottom: 4px solid #cc1a30;
  }

  div:hover:after {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    bottom: -5px;
    border-bottom: 4px solid #cc1a30;
  }
}
</style>

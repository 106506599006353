
<template>
  <div class="head-nav">
    <div class="ranking-nav" v-if="$store.state.userInfo.superviseId">
      <p @click="goBack"><span>返回</span></p>
    </div>
    <div v-else class="head-nav-box">
      <div class="head_left">
        <ul>
          <li><router-link :to="{ path: '/' }">首页</router-link></li>
          <li>
            <router-link :to="{ path: '/school/home' }"><span class="school-home">校园中心</span></router-link>
          </li>
        </ul>
      </div>
      <div class="head_right">
        <ul>
          <li>
            <router-link to="/product">
              <div>
                <p class="icon_product">产品中心</p>
              </div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/nmessage">
              <div>
                <p class="icon_de">消息</p>
              </div>
            </router-link>
          </li>
          <li >
            <router-link to="/nletter">
              <div>
                <p class="icon_xx">站内信</p>
              </div>
            </router-link>
          </li> -->
          <li>
            <router-link :to="$store.state.userInfo.teacherId ? '/indexTeacher' : '/user'">
              <div>
                <p class="icon_tx">账号设置</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
 <script>
 export default {
   data(){
     return{}
   },
   methods:{
     //返回
     goBack(){
       console.log('返回幼儿园列表')
       this.$router.go(-1);
       // this.$router.push({
       //   path: '/supervision'
       // });
       //拿到当前所有的搜索条件
       // window.localStorage.removeItem('superviseSearch')
       // console.log('dddd')
     }
   }
 }
 </script>

<style lang="scss" scoped>
.ranking-nav{
  height: 68px;
  line-height:68px;
  background: #BA3037;
  >p{
    margin:0 auto;
    width:1434px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    cursor:pointer;
    background:url('../assets/img/rank/Icon_Ph_back.png') no-repeat left center;
    background-size:9px 15px;
    padding-left:17px;
    span{
      vertical-align: middle;
    }
  }
}
.head-nav {
  width: 100%;
  height: 68px;
  background: #ba3037;
  .head-nav-box {
    width: 1434px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }
  .router-link-exact-active .school-home{
    font-size: 24px;
    font-weight: bold;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon_tx {
      display: flex;
      align-items: center;
      a {
        color: #ffffff;
      }
      img {
        margin-right: 40px;
      }
    }
  }
  .head_left {
    ul li {
      padding-right: 40px;
      font-size: 18px;
      a {
        color: #ffffff;
      }
    }
  }
  .head_right {
    height:100%;
    ul {
      height:100%;
      li {
        font-size: 16px;
        height:100%;
        line-height: 68px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        a {
          display: block;
          color: #ffffff;
          height: 30px;
        }
      }
      li div,
      .router-link-exact-active div{
        padding-left:15px;
        padding-right: 25px;
        height: 30px;
        display: flex;
        align-items: center;
      }
      li
      {
        div:hover,
        .router-link-exact-active div{
          height: 30px;
          background: linear-gradient(90deg, #ECBEC0, #FDF8F9) left center;
          background-size:100% 30px;
          box-shadow: -27px 17px 59px 0px rgba(187, 48, 55, 0.3);
          border-radius: 15px;
          color: #BB3037;


          .icon_product{
            background: url(../assets/img/newschool/Icon_Btn_Message_H.png) no-repeat center left 0;
            background-size:12px 14px;
            // color:#ba3037;
          }
        }
      }

      li
      {
        div:hover,
        .router-link-exact-active div{

          .icon_product{
            background: url(../assets/img/product/Icon_CPZX_C.png) no-repeat center left 0;
            background-size:16px 16px;
          }
          .icon_de{
            background: url(../assets/img/newschool/Icon_Btn_Message_H.png) no-repeat center left 0;
            background-size:12px 14px;
          }
          .icon_xx{
            background: url(../assets/img/newschool/Icon_Btn_ZNX_H.png) no-repeat center left 0;
            background-size:16px 12px;
            
          }
          .icon_tx{
            background: url(../assets/img/newschool/Icon_Btn_ZHSZ.png) no-repeat center left 0;
            background-size:16px 16px;
            
          }
        }
      }
      
    }
  }
}
.router-link-exact-active {
  // font-size: 22px;
  display: inline-block;
  width:100%;
  height:100%;
}
.icon_de {
  background: url(../assets/img/school/icon_xx.png) no-repeat center left 0;
  background-size:12px 14px;
  padding-left: 20px;
  // padding-right: 22px;
}
.icon_product{
  background: url(../assets/img/product/Icon_CPZX.png) no-repeat center left 0;
  background-size:16px 16px;
  padding-left: 25px;
  // padding-right: 22px;
}
.icon_xx {
  background: url(../assets/img/school/icon_xinxi.png) no-repeat center left 0;
  background-size:16px 12px;
  padding-left: 25px;
  // padding-right: 22px;
}
.icon_tx {
  background: url(../assets/img/school/icon_admin.png) no-repeat center left 0;
  background-size: 16px 16px;
  padding-left: 25px;
}
</style>

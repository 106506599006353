/* jshint esversion: 6 */
/*
 * @Descripttion:
 * @version:
 * @Author: 张磊
 * @Date: 2021-05-12 19:52:55
 * @LastEditors: lily
 * @LastEditTime: 2022-05-02 16:28:05
 * @FilePath: /官网迭代/src/store/state.js
 */
import constant from "@/assets/js/constant.js";
export default {
  fruitIdsPk: [], // 配课，数据缓存
  fruitIds: [], // 园所管理里，批量分班和恢复功能，数据缓存
  headerTabTag: 0, // 首页选定的tab
  userInfo: JSON.parse(localStorage.getItem("userInfo")) || {
    schoolId: "11",
    userId: "21",
  },
  schoolInfo: JSON.parse(localStorage.getItem("schoolInfo")) || {},
  teacherInfo: JSON.parse(localStorage.getItem("teacherInfo")) || {},
  findteachersInfo: JSON.parse(localStorage.getItem("findTeachersInfo")) || {},
  loginState: "", // login/register/forget/''
  schoolBar: [...constant.schoolBar],
  teacherBar: [...constant.teacherBar],
  isBuy: false, //是否打开支付进程弹窗
  buyStep: 1, // 支付进度
  firstLogin: 0, //是否显示申报提醒弹窗
  isHome: 0, //当前是否是首页1是,0否
  isCloseXjzc: 1, //是否显示新疆政采项目申报弹窗 1 显示  0不显示
  superviseId: "",
  fulllData: {
    // 学校人数
    schoolCount: "",
    // 公立私立
    schoolKind: "",
    // 足特园 示范园
    schoolPie: "",

    SchoolCountDaily: "",
    // top5
    top5data: "",
    // 地图数据
    mapData: "",
  },
  //收购合同列表
  refreshList: false,
  isModel: false,
  //小小运动会首页id
  spotrsindex:"",
  isEnableEnroll:'',
  goUrl:'', //小小运动会登录后跳转页面
  prcstatus:'', //小小运动会选中页面
  authorityStatus:false,  //暂无权限弹窗
  // 切换主题
  skinpeeler:'OceanIndex'
};

const TRAIN_ROUTERS = [
    {
      name: 'train',
      path: '/train',
      component: () => import('@/views/train/index.vue'),
    },
    {
      name: 'trainCategory',
      path: '/trainCategory',
      component: () => import('@/views/train/trainCategory.vue'),
    },
    {
      name: 'trainList',
      path: '/trainList',
      component: () => import('@/views/train/trainList.vue'),
    },
    {
      name: 'trainDetail',
      path: '/trainDetail',
      component: () => import('@/views/train/trainDetail.vue'),
    },
    {
      name: 'trainDetail',
      path: '/trainDetail',
      component: () => import('@/views/train/trainDetail.vue'),
    },
    {
      name: 'trainOnline',
      path: '/trainOnline',
      component: () => import('@/views/train/trainOnline.vue'),
    },
   {
    name: 'sign',
    path: '/sign',
    component: () => import('@/views/train/sign.vue'),
   }
]
  
  export default TRAIN_ROUTERS
  
<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_left">
        <div class="code_block mr44">
          <div class="icon">
            <img
              src="../assets/img/home/home/footer/Icon_Logo_XNTY.png"
              alt=""
            />
          </div>
        </div>
        <div class="code_block mr43">
          <div class="icon">
            <img
              src="../assets/img/home/home/footer/Icon_Logo_XJH.png"
              alt=""
            />
          </div>
        </div>
        <div class="code_block mr48">
          <div class="icon">
            <img
              src="../assets/img/home/home/footer/Icon_Logo_JYTX.png"
              alt=""
            />
          </div>
        </div>
        <div class="code_block mr58">
          <div class="icon">
            <img
              src="../assets/img/home/home/footer/Icon_Logo_TJH.png"
              alt=""
            />
          </div>
        </div>
        <div class="code_block mr45">
          <div class="icon">
            <img
              src="../assets/img/home/home/footer/Icon_Logo_XXYDH.png"
              alt=""
            />
          </div>
        </div>
        <div class="code_block">
          <div class="icon">
            <img
              src="../assets/img/home/home/footer/Icon_Logo_HHDD.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer_middle">
      <div class="bg">
        <div class="text">全国青少年校园足球工作领导小组办公室</div>
        <div class="text m30">全国足球特色幼儿园项目指定服务机构</div>
        <div class="text">全国幼儿足球专家委员会协调小组</div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="footer_bottom_l">
        <div class="text">
          地址:北京市朝阳区三里屯SOHO C座1502<br />服务顾问电话：400-831-9799
        </div>
        <!-- <div class="text">服务顾问电话：400-831-9799</div> -->
      </div>
    </div>
    <div class="provider">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备16020119号-3</a>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "FooterBlock",
  data() {
    return {
      tabList: this.constant.publicTabList,
    };
  },
  props: ["data_obj"],
  mounted() {
    console.log("this.data_obj-----", this.data_obj);
  },
  methods: {
    tab(value) {
      this.$store.commit("setHeaderTabTag", value);
    },
    sss() {
      this.$router.push({ path: "/home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.go_home {
  width: 0.8rem;
  height: 0.5rem;
  position: fixed;
  right: 0;
  bottom: 20vh;
  background: #cc1a30;
  font-size: 0.17rem;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 0.5rem;
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
  z-index: 2;
}

.footer {
  width: 100%;
  // height: 464px;
  @include bg("../assets/img/home/home/footer/Pic_Bg_W.png");
  color: #ffffff;
  box-sizing: border-box;
  padding-top: 53px;
  padding-bottom: 17px;

  .footer_top {
    @include flex(row, center, flex-start);

    .footer_top_left {
      margin-bottom: 30px;
      // flex: 1;
      @include flex(row, flex-end, flex-start);

      .code_block {
        @include flex(column, flex-start, center);
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 300;

        .icon {
          width: 84px;
          height: 60px;
          box-sizing: border-box;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .mr44 {
        margin-right: 44px;
      }
      .mr43 {
        margin-right: 43px;
      }
      .mr45 {
        margin-right: 45px;
      }
      .mr48 {
        margin-right: 48px;
      }
      .mr58 {
        margin-right: 58px;
      }
    }
  }
  .footer_middle {
    @include flex();
    .bg {
      @include bg("../assets/img/home/home/footer/Pic_W_Line.png");
      height: 69px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
      }
      .m30 {
        margin-left: 35px;
        margin-right: 35px;
      }
      .flex {
        flex: 1;
      }
    }
  }
  .footer_bottom {
    @include flex();
    margin-top: 18px;
    .footer_bottom_l {
      height: 41px;
      border: 1px solid #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-left: 9px;
        margin-right: 9px;
      }
    }
  }

  .provider {
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    margin-top: 13px;
  }
}
</style>
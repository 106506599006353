/* jshint esversion: 6 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: 张磊
 * @Date: 2021-05-19 20:48:54
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-20 00:53:59
 * @FilePath: /官网迭代/src/api/train.js
 */
import { request } from "@/utils/request";

// http://10.34.3.149:8090/cultivateCategory/findTCCByPid?pid=1 教师培训查询
/**
 *
 * @param {pid} params  0 初始化  1,2,3 根据模块不同传入
 * @returns
 */
const findTCCByPid = (params) => {
  return request({
    url: "/cultivateCategory/findTCCByPid",
    method: "get",
    params,
  });
};

// http://localhost:8090/cultivateVideo/findCultivateVideoByCategoryId?categoryVidoId=5  教师培训分类
/**
 *
 * @param {categoryVidoId} params  视频id
 * @returns
 */
const findCultivateVideoByCategoryId = (params) => {
  return request({
    url: "/cultivateVideo/findCultivateVideoByCategoryId",
    method: "get",
    params,
  });
};

//http://localhost:8090/commentPost/findCommentPost
/**
 * 评论列表
 * @param {articleId} params
 * @param {pid} params  评论id
 * @param {sort} params   desc
 * @param {pageno} params  分页页码
 * @param {pagesize} params  分页条数
 * @returns
 */
const findCommentPost = (data) => {
  return request({
    url: "/commentPost/findCommentPost",
    method: "post",
    data,
  });
};

// http://localhost:8090/commentPost
/**
 * 评论提交
 * @param {userId} params
 * @param {articleId} params
 * @param {pid} params
 * @param {siteId} params
 * @param {content} params
 * @returns
 */
const commentPost = (data) => {
  return request({
    url: "/commentPost",
    method: "post",
    data,
  });
};

// http://localhost:8090/commentPost/addZan?id=233
/**
 * 评论提交
 * @param {id} params
 * @returns
 */
 const addZan = (params) => {
  return request({
    url: "/commentPost/addZan",
    method: "get",
    params,
  });
};

 //线上培训  //trainActivity

const trainInfo = (params) => {
  return request({
    url: "/trainActivity",
    method: "get",
    params,
  });
};

// 线上培训 提交
const submitTrainInfo = (data) => {
  return request({
    url: "/trainActivity",
    data,
    method: "POST",
  });
};


export default {
  findTCCByPid,
  findCultivateVideoByCategoryId,
  findCommentPost,
  commentPost,
  addZan,
  //线上培训
  trainInfo,
  submitTrainInfo,
};

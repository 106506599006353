import { request } from "@/utils/request";

/**
 * 体验课程|付费 节数
 * @param {ispremium} Number 是否付费 0 未付费 1已付费
 * @param {title} String 标题
 * @returns
 */
const findCourseCategory = (params) => {
  return request({
    url: "/course/findCourseCategory",
    params,
    method: "get",
  });
};



// http://localhost:8090/video/findCoursesOfTeacher?courseId=2&isPremium=0  LGA/PGS/MPS课程列表查询
/**
 *
 * @param {ispremium} params 是否付费 0 未付费 1已付费
 * @param {courseId} params 课程id
 * @returns
 */
const findCoursesOfTeacher = (params) => {
  return request({
    url: "video/findCoursesOfTeacher",
    params,
    method: "get",
  });
};
// http://localhost:8090/video/findCoursesOfTeacher_V2  PGS课程列表查询
/**
 *
 * @param {Ispremium} params 是否付费 0 未付费 1已付费
 * @param {courseId} params 课程id
 * @param {isPremium} params 课程id
 * @returns
 */
const findCoursesOfTeacher_V2 = (params) => {
  return request({
    url: "video/findCoursesOfTeacher_V2",
    params,
    method: "get",
  });
};

// http://localhost:8090/video/findCourseInfoByCourseId?courseId=2&isPremium=0  体验课封面
/**
 *
 * @param {ispremium} params 是否付费 0 未付费 1已付费
 * @param {courseId} params 课程id
 * @returns
 */
const findCourseInfoByCourseId = (params) => {
  return request({
    url: "video/findCourseInfoByCourseId",
    params,
    method: "get",
  });
};

// http://localhost:8090/course/findGradesOfTeacherId?teacherId=139679  一键上课 班级列表
/**
 *
 * @param {teacherId} params 教师id
 * @returns
 */
const findGradesOfTeacherId = (params) => {
  return request({
    url: "course/findGradesOfTeacherId",
    params,
    method: "get",
  });
};

const findTestGrade = (params) => {
  return request({
    url: "/atteTeacher/findTestGrade",
    params,
    method: "get",
  });
};

// http://localhost:8090/course/findEntryClass?schoolId=11&gradeId=2&gradeType=1&teacherId=2
/**
 * 一键上课老师/学生/视频列表
 * @param {schoolId} params 教师id
 * @param {gradeId} params 教师id
 * @param {gradeType} params 教师id
 * @param {teacherId} params 教师id
 * @returns
 */
const findEntryClass = (params) => {
  return request({
    url: "course/findEntryClass",
    params,
    method: "get",
  });
};

// http://localhost:8090/nots/findNotsByVideoId/1/10?videoId=2&userId=22
/**
 * 一键上课老师/学生/视频列表
 * @param {videoId} data 教师id
 * @param {userId} data 教师id
 * @param {page} params 教师id
 * @param {sice} params 教师id
 * @returns
 */
const findNotsByVideoId = (params, data) => {
  return request({
    url: `nots/findNotsByVideoId/${params.page}/${params.size}`,
    params: data,
    method: "get",
  });
};

// http://localhost:8090/nots/add
/**
 * 提交笔记
 * @param {videoId} data 教师id
 * @param {userId} data 教师id
 * @param {contents} data 教师id
 * @returns
 */
const add = (data) => {
  return request({
    url: `nots/add`,
    data,
    method: "post",
  });
};

// http://localhost:8090/nots/delete/7633
/**
 * 提交笔记
 * @param {id} data 评论id
 * @returns
 */
const notsDelete = (id) => {
  return request({
    url: `/nots/delete/${id}`,
    method: "delete",
  });
};

// http://localhost:8090/testingPlan/findTestPlanBySportId?sportId=1  教案播放
/**
 * 一键上课老师/学生/视频列表
 * @param {sportId} params 教案id
 * @returns
 */
const findTestPlanBySportId = (params) => {
  return request({
    url: `/testingPlan/findTestPlanBySportId`,
    params,
    method: "get",
  });
};

// http://39.106.9.174:8086/course/findCourseType  V2.0 课程分类LGA课程、PGS、MPS
/**
 * @returns
 */
const findCourseType = (params) => {
  return request({
    url: `/course/findCourseType`,
    params,
    method: "get",
  });
};

// http://39.106.9.174:8086/atteGrade/findGradeType V2.0 班级类型
/**
 * @returns
 */
const findGradeType = (data) => {
  return request({
    url: `atteGrade/findGradeType`,
    data,
    method: "get",
  });
};

// http://39.106.9.174:8086/atteGrade/findVideoInfo?courseType=1&gradeType=1  V2.0 传班级类型列表
/**
 * courseType  班级类型
 * gradeType   年级类型
 * @returns
 */
const findVideoInfo = (params) => {
  return request({
    url: `/video/findVideoInfo`,
    params,
    method: "get",
  });
};

const videoFindVideoCountInfo = (params) => {
  return request({
    url: "/video/findVideoCountInfo",
    params,
    method: "get",
  });
};

export default {
  findCourseCategory,
  findCoursesOfTeacher,
  findCourseInfoByCourseId,
  findGradesOfTeacherId,
  findTestGrade,
  findEntryClass,
  findNotsByVideoId,
  add,
  notsDelete,
  findTestPlanBySportId,
  findCoursesOfTeacher_V2,
  findCourseType,
  findGradeType,
  findVideoInfo,
  videoFindVideoCountInfo
};

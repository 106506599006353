const SCHOOL_ROUTERS = [
  {
    name: "ranking",
    path: "/school/ranking",
    component: () => import("@/views/school/ranking.vue"),
    meta: { isRank:true,},
  },
];

export default SCHOOL_ROUTERS;

import Vue from "vue";
import store from "../store";
import Router from "vue-router";
import HOME_ROUTERS from "@/views/home/route";
import COURSE_ROUTERS from "@/views/course/route";
import TRAIN_ROUTERS from "@/views/train/route";
import ADMIN_ROUTES from "@/views/admin/route";
import ZH_ROUTES from "@/views/zhSchool/route";
import TEST_ROUTES from "@/views/test/route";
import SCHOOL_ROUTES from "@/views/school/route";
import PUBLICBENEFIT_ROUTERS from "@/views/publicBenefit/route";
Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [

    {
      path: "/",
      component: () => import("@/views/home/index.vue"),
    },
    {
      path: "/homenew",
      component: () => import("@/views/home/home.vue"),
      meta: { isRank: true },
    },
    {
      path: "/LGAHome",
      component: () => import("@/views/home/LGAHome.vue"),
      meta: { isRank: true },
    },
    {
      path: "/privacy",
      component: () => import("@/views/home/privacy.vue"),
      meta: { isH5: true },
    },
    {
      path: "/PESHome",
      component: () => import("@/views/home/PESHome.vue"),
      meta: { isRank: true },
    },
    {
      path: "/growthAnalyse",
      component: () => import("@/views/pes/growthAnalyse.vue"),
      meta: { isRank: true },
    },
    {
      path: "/allCountrydb",
      component: () => import("@/views/pes/allCountrydb.vue"),
      meta: { isRank: true },
    },
    {
      path: "/childrenCf",
      component: () => import("@/views/pes/childrenCf.vue"),
      meta: { isRank: true },
    },
    {
      path: "/schoolZHFX",
      component: () => import("@/views/pes/schoolZHFX.vue"),
      meta: { isRank: true },
    },
    {
      path: "/gradeZHFX",
      component: () => import("@/views/pes/gradeZHFX.vue"),
      meta: { isRank: true },
    },
    {
      path: "/childrenZHBG",
      component: () => import("@/views/pes/childrenZHBG.vue"),
      meta: { isRank: true },
    },
    {
      path: "/childrenCX",
      component: () => import("@/views/pes/childrenCX.vue"),
      meta: { isRank: true },
    },
    {
      path: "/doZZBG",
      component: () => import("@/views/pes/doZZBG.vue"),
      meta: { isRank: true },
    },
    {
      path: "/courseCenter",
      component: () => import("@/views/home/courseCenter.vue"),
      meta: { isRank: true },
    },
    {
      path: "/courseInfo",
      component: () => import("@/views/home/courseInfo.vue"),
      meta: { isRank: true },
    },
    // 首页
    {
      path: "/home",
      component: () => import("@/views/home/index.vue"),
    },
    // pes评估
    {
      path: "/pes",
      component: () => import("@/views/pes/index.vue"),
      meta: { isLogin: true },
    },
    //小小运动会
    {
      path: "/sport",
      component: () => import("@/views/sports/index.vue"),
      meta: { issports: true },
    },
    {
      path: "/signup",
      component: () => import("@/views/sports/signup.vue"),
      meta: { issports: true },
    },
    {
      path: "/sportsnews",
      component: () => import("@/views/sports/news.vue"),
      meta: { issports: true },
    },
    // 小小运动会详情
    {
      path: "/sportDetail",
      component: () => import("@/views/sports/detail.vue"),
      meta: { issports: true },
    },

    // 小小运动会 项目
    {
      path: "/project",
      component: () => import("@/views/sports/project.vue"),
      meta: { issports: true },
    },
    // 小小运动会 项目&训练方案
    {
      path: "/trainProgram",
      component: () => import("@/views/sports/trainProgram.vue"),
      meta: { issports: true },
    },
    // 小小运动会 新闻
    {
      path: "/schedule",
      component: () => import("@/views/sports/schedule.vue"),
      meta: { issports: true },
    },
    // 小小运动会 排行榜
    {
      path: "/sportsRank",
      component: () => import("@/views/sports/rank.vue"),
      meta: { issports: true },
    },
    // 小小运动会 项目/训练方案详情
    {
      path: "/sportsClassVideo",
      component: () => import("@/views/sports/classVideo.vue"),
      meta: { issports: true },
    },
    // 产品中心
    {
      path: "/product",
      component: () => import("@/views/product/index.vue"),
      meta: { isSchool: true },
    },
    // 积分
    {
      path: "/integral",
      component: () => import("@/views/integral/index.vue"),
    },
    {
      path: "/integralList",
      component: () => import("@/views/integral/list.vue"),
    },
    {
      name: "integralTeacherList",
      path: "/integralTeacherList",
      component: () => import("@/views/admin/school/integralTeacherList.vue"),
      // meta: { isAdmin: true },
    },
    //商城
    {
      path: "/shop",
      component: () => import("@/views/shop/index.vue"),
    },
    //示范园
    {
      path: "/park",
      component: () => import("@/views/park/index.vue"),
    },
    // 管理
    {
      path: "/admin",
      component: () => import("@/views/admin/index.vue"),
      children: [...ADMIN_ROUTES],
    },
    // 个人中心
    {
      path: "/user",
      component: () => import("@/views/user/index.vue"),
      meta: { isSchool: true },
    },
    // 个人中心
    {
      path: "/indexTeacher",
      component: () => import("@/views/user/indexTeacher.vue"),
      meta: { isSchool: true },
    },
    {
      name: "nmessage",
      path: "/nmessage",
      component: () => import("@/views/schoolAdmin/message.vue"),
      meta: {
        isSchool: true,
      },
    },

    {
      //站内信
      name: "nletter",
      path: "/nletter",
      component: () => import("@/views/schoolAdmin/letter.vue"),
      meta: {
        isSchool: true,
      },
    },
    {
      //站内信
      name: "nletterDetail",
      path: "/nletter/nletterDetail",
      component: () => import("@/views/schoolAdmin/letterDetail.vue"),
      meta: {
        isRank: true,
      },
    },
    // 大屏数据展示
    {
      path: "/datav",
      component: () => import("@/views/datav/index.vue"),
      meta: { isDatav: true },
    },
    // 指挥幼儿园
    // 大屏数据展示
    {
      path: "/zhSchool",
      component: () => import("@/views/zhSchool/index.vue"),
    },
    // 已购买智慧幼儿园
    {
      path: "/zh",
      component: () => import("@/views/zhSchool/index.vue"),
      children: [...ZH_ROUTES],
      redirect: "zh/general",
      meta: { isLogin: true },
    },
    // 已购买智慧幼儿园
    {
      path: "/purchase",
      component: () => import("@/views/zhSchool/purchase.vue"),
      meta: { isDatav: true },
    },
    // 未购买智慧幼儿园
    {
      path: "/unpurchased",
      component: () => import("@/views/zhSchool/unpurchased.vue"),
      meta: { isDatav: true },
    },
    {
      path: "/ai",
      component: () => import("@/views/zhSchool/zhAi.vue"),
      meta: { isDatav: true },
    },
    // 在线课堂大屏数据展示
    {
      path: "/onlineClass",
      component: () => import("@/views/datav/onlineClass.vue"),
      meta: { isDatav: true },
    },
    // 督导组
    {
      path: "/supervision",
      component: () => import("@/views/supervision/index.vue"),
      meta: {
        isSuper: true,
      },
    },
    {
      path: "/kindergartenTable",
      component: () => import("@/views/supervision/kindergartenTable.vue"),
      meta: {
        isSuper: true,
      },
    },
    {
      path: "/setInfoSupervision",
      component: () => import("@/views/supervision/setInfoSupervision.vue"),
      meta: {
        isSuper: true,
      },
    },
    {
      path: "/unfairnessInfo",
      component: () => import("@/views/unfairness/unfairnessInfo.vue"),
      meta: { isLogin: true },
    },
    {
      path: "/unfairnessData",
      component: () => import("@/views/unfairness/unfairnessData.vue"),
      meta: { isLogin: true },
    },
    {
      path: "/sportValueShow",
      component: () => import("@/views/sports/sportValueShow.vue"),
      meta: { isDatav: true },
    },
    {
      path: "/sportRank",
      component: () => import("@/views/sports/sportsRank.vue"),
      meta: { isDatav: true },
    },
    {
      path: "/sportNew",
      component: () => import("@/views/sports/sportNew.vue"),
      meta: { isDatav: true },
    },
    {
      path: "/sportXj",
      component: () => import("@/views/sports/xjSportsRank.vue"),
      meta: { isDatav: true },
    },
    {
      path: "/sportSingle",
      component: () => import("@/views/sports/sportSingle.vue"),
      meta: { isDatav: true },
    },
    {
      //标准体质测试
      path: "/normalTzTest",
      component: () => import("@/views/admin/test/normalTzTest.vue"),
      meta: {
        isRank: true,
      },
    },
    {
      //标准运动测试
      path: "/normalYdTest",
      component: () => import("@/views/admin/test/normalYdTest.vue"),
      meta: {
        isRank: true,
      },
    },
    {
      //pes报告
      path: "/pesNew",
      component: () => import("@/views/admin/test/pesNew.vue"),
      meta: {
        isRank: true,
      },
    },
    {
      path: "/monthInfo",
      component: () => import("@/views/admin/test/monthInfo.vue"),
      meta: {
        isRank: true,
      },
    },
    {
      path: "/reportInfo",
      component: () => import("@/views/admin/test/reportInfo.vue"),
      meta: {
        isRank: true,
      },
    },
    // {
    //   path: "/schoolInfo",
    //   component: () => import("@/views/admin/school/schoolInfo.vue"),
    //   // meta: { isAdmin: true },
    // },
    ...TEST_ROUTES,
    ...HOME_ROUTERS,
    ...COURSE_ROUTERS,
    ...TRAIN_ROUTERS,
    ...PUBLICBENEFIT_ROUTERS,
    ...SCHOOL_ROUTES,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
router.beforeEach((to, from, next) => {
  console.log(to, from, "ddddd");
  if (
    to.matched.some((item) => {
      return item.meta.isLogin;
    })
  ) {
    if (store.state.userInfo.token) {
      // console.log(store.state.userInfo.token);
      let len =
        store.state.userInfo.resourceUrls &&
        store.state.userInfo.resourceUrls.filter((item) => {
          return item.url == to.path;
        });

      if (len && len.length && !len[0].flag) {
        console.log(1111111111111111111111111111111);
        store.commit("setAuthorityState", true);
        //Vue.prototype.$message.error("没有权限");
        next(from.path);
      } else {
        next();
      }
    } else {
      store.commit("setLoginState", "login");
    }
  } else {
    let len =
      store.state.userInfo.resourceUrls &&
      store.state.userInfo.resourceUrls.filter((item) => {
        return item.url == to.path;
      });
    console.log(to.path)
    console.log(len, "lenlenlenlenlen");
    if (len && len.length && !len[0].flag) {
      // Vue.prototype.$message.error('没有权限');
      store.commit("setAuthorityState", true);
      next(from.path);
    } else {
      next();
    }
  }
});
export default router;

import { request } from '@/utils/request'

//http://localhost:8090/atteGrade/findByGradeType?schoolId=11&type=2   学生报告-班级列表
/**
 *
 * @param {schoolId} params 学校ID
 * @param {type} params 学校ID
 * @returns
 */
const findByGradeType = (params) => {
  return request({
    url: '/atteGrade/findByGradeType',
    method: 'get',
    params,
  })
}

//http://42.193.121.89:8090/atteStudent/findByGradeId?gradeId=3  学生报告-班级列表
/**
 *
 * @param {gradeId} params 班级ID
 * @returns
 */
const findByGradeId = (params) => {
  return request({
    url: '/atteStudent/findByGradeId',
    method: 'get',
    params,
  })
}

//http://localhost:8090/testingLog/findByStuId?schoolId=11&stuId=5  学生报告-班级列表
/**
 *
 * @param {schoolId} params 学校ID
 * @param {stuId} params 学生ID
 * @returns
 */
const findByStuId = (params) => {
  return request({
    url: '/testingLog/findByStuId',
    method: 'get',
    params,
  })
}

//http://localhost:8090/testingLog/findByGradeId?schoolId=11&gradeId=3  班级报告-报告列表
/**
 *
 * @param {schoolId} params 学校ID
 * @param {gradeId} params 班级ID
 * @returns
 */
const getGradeReportList = (params) => {
  return request({
    url: '/testingLog/findByGradeId',
    method: 'get',
    params,
  })
}

//   http://localhost:8090/testingLog/findBySchoolId?schoolId=11  园报告-列表
/**
 *
 * @param {schoolId} params 学校ID
 * @returns
 */
const findBySchoolId = (params) => {
  return request({
    url: '/testingLog/findBySchoolId',
    method: 'get',
    params,
  })
}

//   http://localhost:8090/testingLog/findReportByGradeId?semesterId=1&gradeId=3&isEnd=0  班级学期体质报告统计
/**
 * @param {semesterId} params 学校ID
 * @param {gradeId} params 班级ID
 * @param {isEnd} params 学期初末
 * @returns
 */
const findReportByGradeId = (params) => {
  return request({
    url: '/testingLog/findReportByGradeId',
    method: 'get',
    params,
  })
}
//   http://localhost:8090/testingLog/findReportAuthByStuId 判断学生是否有权限查看报告
/**
 * @param {stuId} params 学生ID
 * @returns
 */
const findReportAuthByStuId = (params) => {
  return request({
    url: '/testingLog/findReportAuthByStuId',
    method: 'get',
    params,
  })
}

//   http://localhost:8090/testingLog/findReportByStuId?semesterId=1&stuId=5&isEnd=0  班级学期体质报告统计
/**
 * @param {semesterId} params 学期ID
 * @param {stuId} params 学生ID
 * @param {isEnd} params 学期初末
 * @returns
 */
const findReportByStuId = (params) => {
  return request({
    url: '/testingLog/findReportByStuId',
    method: 'get',
    params,
  })
}

//   http://localhost:8090/testingLog/findReport2ByStuId?semesterId=1&stuId=5&isEnd=0&type=2 班级学期体质报告统计
/**
 * @param {semesterId} params 学校ID
 * @param {stuId} params 班级ID
 * @param {isEnd} params 学期初末
 * @param {type} params 学期初末
 * @returns
 */
const findReport2ByStuId = (params) => {
  return request({
    url: '/testingLog/findReport2ByStuId',
    method: 'get',
    params,
  })
}

// http://localhost:8090/testingLog/findReport2ByGradeId?semesterId=1&gradeId=2&isEnd=0&type=2  班级学期运动报告统计
/**
 * @param {semesterId} params 学校ID
 * @param {gradeId} params 班级ID
 * @param {isEnd} params 学期初末
 * @param {type} params 学期初末
 * @returns
 */
const findReport2ByGradeId = (params) => {
  return request({
    url: '/testingLog/findReport2ByGradeId',
    method: 'get',
    params,
  })
}

// 园所
//   http://localhost:8090/testingLog/findSchoolReports?schoolId=13381&semesterId=3  测试概况
/**
 * @param {semesterId} params 学校ID
 * @param {isEnd} params 学期初末
 * @param {schoolId} params 学期初末
 * @returns
 */
const findSchoolReports = (params) => {
  return request({
    url: '/testingLog/findSchoolReports',
    method: 'get',
    params,
  })
}

// http://localhost:8090/testingLog/findSchoolTestPercent?schoolId=13381&semesterId=3&isEnd=0  测试完成率
/**
 * @param {semesterId} params 学校ID
 * @param {isEnd} params 学期初末
 * @param {schoolId} params 学期初末
 * @returns
 */
const findSchoolTestPercent = (params) => {
  return request({
    url: '/testingLog/findSchoolTestPercent',
    method: 'get',
    params,
  })
}

//   http://localhost:8090/testingLog/findSchoolTestSportsAvg?schoolId=13381&semesterId=3&isEnd=0 园平均值
/**
 * @param {semesterId} params 学校ID
 * @param {isEnd} params 学期初末
 * @param {schoolId} params 学期初末
 * @returns
 */
const findSchoolTestSportsAvg = (params) => {
  return request({
    url: '/testingLog/findSchoolTestSportsAvg',
    method: 'get',
    params,
  })
}

//   http://localhost:8090/testingLog/findProvinceTestSportsAvg?schoolId=13381&semesterId=3&isEnd=0 省平均值
/**
 * @param {semesterId} params 学校ID
 * @param {isEnd} params 学期初末
 * @param {schoolId} params 学期初末
 * @returns
 */
const findProvinceTestSportsAvg = (params) => {
  return request({
    url: '/testingLog/findProvinceTestSportsAvg',
    method: 'get',
    params,
  })
}

//  http://localhost:8090/testingLogAvg/findCountyTestSportsAvg?semesterId=3&isEnd=0 全国平均值
/**
 * @param {semesterId} params 学校ID
 * @param {isEnd} params 学期初末
 * @returns
 */
const findCountyTestSportsAvg = (params) => {
  return request({
    url: '/testingLogAvg/findCountyTestSportsAvg',
    method: 'get',
    params,
  })
}

//http://localhost:8090/classRecords/findSoreStuList?id=29  看视频待评分学生列表
/**
 * @param {id} params
 * @returns
 */
const findSoreStuList = (params) => {
  return request({
    url: '/classRecords/findSoreStuList',
    method: 'get',
    params,
  })
}
//http://localhost:8090/newClassScore/findClassScore?id=29  v2.0查询该学期班级评分 看视频待评分学生列表(新)
/**
 * @param {gradeId} params 班级ID
 * @param {semesterId} params 学期ID
 * @param {videoId} params 视频ID
 * @returns
 */
const findClassScore = (params) => {
  return request({
    url: '/newClassScore/findClassScore',
    method: 'get',
    params,
  })
}

//http://localhost:8090/classRecords/findWaitSore/2/10?userId=22  上课评分列表
/**
 * @param {size} params 展示数
 * @param {page} params 页码
 * @param {userId} data 页码
 * @returns
 */
const findWaitSore = (params, data) => {
  return request({
    url: `/classRecords/findWaitSore/${params.page}/${params.size}`,
    method: 'get',
    params: data,
  })
}

// http://localhost:8090/classRecords/addSore 评分
/**
 * @param {classRecordId} data 上课id
 * @param {videoId} data 视频id
 * @param {soreStuMapList ｜ array } data 信息
 * @param {stuId } soreStuMapList 信息
 * @param {userName } soreStuMapList 信息
 * @param {type} soreStuMapList 信息
 * @param {lesson} soreStuMapList 信息
 * @param {starCount} soreStuMapList 信息
 * @param {gradeId} soreStuMapList 信息
 * @param {gradeName} soreStuMapList 信息
 * @returns
 */
const addSore = (data) => {
  return request({
    url: `/classRecords/addSore `,
    method: 'post',
    data,
  })
}
// http://localhost:8090/newClassScore/saveClassSore 评分(新)
/**
 * @param {classTime} data 上课时间
 * @param {createtime} data 创建日期
 * @param {evaluatestatus} data 评价状态:0=未评价,1=已评价
 * @param {gradeId } data 班级id
 * @param {id } data ID
 * @param {schoolId} data 学校ID
 * @param {semesterId} data 学期ID
 * @param {startDzgf} data 评价获得的星数(动作规范)
 * @param {startHzhz} data 评价获得的星数【互助合作】
 * @param {startKtzz} data 评价获得的星数【课堂专注】
 * @param {startQxbx} data 评价获得的星数【情绪表现】
 * @param {studentId} data 点名学生id
 * @param {teacherId} data 教师id
 * @param {updatetime} data 更新时间
 * @param {userId} data 上课人
 * @param {videoId} data  课程id
 * @returns
 */
const saveClassSore = (data) => {
  return request({
    url: `/newClassScore/saveClassSore `,
    method: 'post',
    data,
  })
}

// http://localhost:8090/atteTeacher/findTestGrade?schoolId=11&gradeId=516 进入班级测试
/**
 * @param {schoolId} params 学校id
 * @param {gradeId} params 班级id
 * @returns
 */
const findTestGrade = (params) => {
  return request({
    url: `/atteTeacher/findTestGrade`,
    method: 'get',
    params,
  })
}

// http://localhost:8090/atteStudent/findStuTestByGradeId/2/2?semesterId=3&gradeId=2&isEnd=0&schoolId=11&stuIds=5,256668,276785 学生体质测试列表
const findStuTestByGradeId = (params, data) => {
  return request({
    url: `/atteStudent/findStuTestByGradeId/${params.page}/${params.size}`,
    method: 'get',
    params: data,
  })
}
// http://localhost:8090/testingLog/findStuBodyTestByGradeId/2/2 学生体质测试列表新
const findStuBodyTestByGradeId = (params, data) => {
  return request({
    url: `/atteStudent/findStuBodyTestByGradeId/${params.page}/${params.size}`,
    method: 'get',
    params: data,
  })
}

// http://localhost:8090/atteStudent/findStuSportTestByGradeId/0/10?semesterId=3&gradeId=2&isEnd=0&schoolId=11&stuIds=5,256668,276785&sportId=1
const findStuSportTestByGradeId = (params, data) => {
  return request({
    url: `/atteStudent/findStuSportTestByGradeId/${params.page}/${params.size}`,
    method: 'get',
    params: data,
  })
}
// http://39.106.9.174:8086/atteStudent/findStuSportTestDataByGradeId/0/10 获取运动测试详情
const findStuSportTestDataByGradeId = (params, data) => {
  return request({
    url: `/atteStudent/findStuSportTestDataByGradeId/${params.page}/${params.size}`,
    method: 'get',
    params: data,
  })
}

// http://localhost:8090/testingLog/addFinishTestDatas?semesterId=3&gradeId=2&isEnd=0&schoolId=11&stuIds=5,256668,276785&sportId=1 提交运动测试
const addFinishSportTestDatas = (params) => {
  return request({
    url: `/testingLog/addFinishSportTestDatas`,
    method: 'get',
    params,
  })
}

// http://39.106.9.174:8086/addSportsData 提交运动测试
const addSportsData = (data) => {
  return request({
    url: `/testingLog/addSportsData`,
    method: 'post',
    data,
  })
}

// // http://localhost:8090/testingLog/addFinishTestDatas?semesterId=3&gradeId=2&isEnd=0&schoolId=11&stuIds=5,256668,276785  提交体质测试
const addFinishBodyTestDatas = (params) => {
  return request({
    url: `/testingLog/addFinishBodyTestDatas`,
    method: 'get',
    params,
  })
}
// http://localhost:8090/testingLog/addBodyData 提交体质测试(新)
const addBodyData = (data) => {
  return request({
    url: `/testingLog/addBodyData`,
    method: 'post',
    data,
  })
}

// http://localhost:8090/testingLog/add  老师提交测试结果
const add = (data) => {
  return request({
    url: `testingLog/add`,
    method: 'post',
    data,
  })
}

// 评价占比：http://localhost:8090/testingLog/findPerEvaluation?schoolId=11&semesterId=3&isEnd=0
const findPerEvaluation = (params) => {
  return request({
    url: `testingLog/findPerEvaluation`,
    method: 'get',
    params,
  })
}
// 园所报告-获取月份信息
const ysReportGetMonth = (params) => {
  return request({
    url: `report/homePage/getMonths`,
    method: 'get',
    params,
  })
}
// 园所报告-获取三维数据
const ysReportMonthNum = (params) => {
  return request({
    url: `report/homePage/getTableV`,
    method: 'get',
    params,
  })
}
// 园所报告-获取运动数据
const ysReportMonthSportData = (params) => {
  return request({
    url: `report/homePage/getTestSportInfoDto`,
    method: 'get',
    params,
  })
}
// 园所报告-获取体质数据
const ysReportMonthTzData = (params) => {
  return request({
    url: `report/homePage/getTestFoundaInfoDto`,
    method: 'get',
    params,
  })
}
// 园所报告-获取综合报告
const ysReportMonthComReport = (params) => {
  return request({
    url: `report/homePage/getTestComReportDto`,
    method: 'get',
    params,
  })
}
// 获取lga课程
const getLAGCourse = (params) => {
  return request({
    url: `video/findCoursesOfTeacherNew`,
    method: 'get',
    params,
  })
}
// 幼儿报告-获取幼儿综合报告
const childrenZHBGGet = (params) => {
  return request({
    url: `report/homePage/getTestCompInfoDto`,
    method: 'get',
    params,
  })
}
// 成长分析-获取测试数据
const growthValueTopData = (params) => {
  return request({
    url: `analysis/growth/chart/avgdata/countrygrade`,
    method: 'get',
    params,
  })
}
// 成长分析-获取增长率数据
const growthValueRateData = (params) => {
  return request({
    url: `analysis/growth/chart/rate/latelycontrast/sc`,
    method: 'get',
    params,
  })
}
// 成长分析-获取六个月增长率数据
const growthValueAnalyseData = (params) => {
  return request({
    url: `analysis/growth/chart/rate/cyclecontrast/sc`,
    method: 'get',
    params,
  })
}
// 全国对比-地图数据
const childrenDbMapData = (params) => {
  return request({
    url: `analysis/growth/chart/avgdata/provincegrade`,
    method: 'get',
    params,
  })
}
// 全国对比-增长率数据
const childrenDbRateData = (params) => {
  return request({
    url: `analysis/growth/chart/avgdata/latelycontrast/sc`,
    method: 'get',
    params,
  })
}
// 全国对比-分析数据
const childrenDbAnalyseData = (params) => {
  return request({
    url: `analysis/growth/chart/avgdata/cyclecontrast/sc`,
    method: 'get',
    params,
  })
}
// 运动处方-雷达图
const sportMethodRadarData = (params) => {
  return request({
    url: `analysis/growth/chart/cycleavgdata/country`,
    method: 'get',
    params,
  })
}
// 运动处方-评分
const sportMethodScoreData = (params) => {
  return request({
    url: `analysis/growth/chart/score/latelycontrast/sc`,
    method: 'get',
    params,
  })
}
// 运动处方-雷达图
const sportMethodAnalyseData = (params) => {
  return request({
    url: `analysis/growth/chart/score/cyclecontrast/sc`,
    method: 'get',
    params,
  })
}
// 查询-结果
const getCXReport = (params) => {
  return request({
    url: `report/homePage/getFaceInfo`,
    method: 'get',
    params,
  })
}
// LGA课程点赞
const coursePraise = (params) => {
  return request({
    url: `video/doParise`,
    method: 'get',
    params,
  })
}
// LGA课程观看次数
const courseCountAdd = (params) => {
  return request({
    url: `getPlayInfoByTokenNew`,
    method: 'get',
    params,
  })
}
// 测试报告园所列表
const testNewRecordList = (params) => {
  return request({
    url: `testNew/getRecodList`,
    method: 'get',
    params,
  })
}
// 测试报告园所详情
const testNewRecordInfo = (params) => {
  return request({
    url: `testNew/getRecordItems`,
    method: 'get',
    params,
  })
}
// 测试报告创建
const createNewReport = (params) => {
  return request({
    url: `testNew/createRecord`,
    method: 'get',
    params,
  })
}
// 测试报告预创建
const createNewReportPre = (params) => {
  return request({
    url: `testNew/preCreateRecord`,
    method: 'get',
    params,
  })
}
// 测试报告标准信息
const getNormalTestLabel = (params) => {
  return request({
    url: `testNew/getNewTestStandard`,
    method: 'get',
    params,
  })
}
// 测试报告单人测试数据
const getSingleTestData = (params) => {
  return request({
    url: `testNew/getSingleTestData`,
    method: 'get',
    params,
  })
}
// 测试报告单人测试数据保存
const saveSingleTestData = (data) => {
  return request({
    url: `testNew/saveSingeNewTest`,
    method: 'post',
    data,
  })
}
// 获取分享出去的二维码地址
const getShareImageUrl = (params) => {
  return request({
    url: `testNew/getRecordInfo`,
    method: 'get',
    params,
  })
}
export default {
  findByGradeType,
  findByGradeId,
  findByStuId,
  getGradeReportList,
  findBySchoolId,
  findReportByGradeId,
  findReportByStuId,
  findReport2ByStuId,
  findReport2ByGradeId,
  findSchoolReports,
  findSchoolTestPercent,
  findSchoolTestSportsAvg,
  findProvinceTestSportsAvg,
  findCountyTestSportsAvg,

  //评分
  findSoreStuList,
  findWaitSore,
  addSore,
  findTestGrade,
  findStuTestByGradeId,
  findStuSportTestByGradeId,
  findStuSportTestDataByGradeId,
  add,
  addFinishSportTestDatas,
  addFinishBodyTestDatas,
  findPerEvaluation,
  addSportsData,
  findStuBodyTestByGradeId,
  addBodyData,
  findReportAuthByStuId,
  findClassScore,
  saveClassSore,
  //新版pes报告
  ysReportGetMonth,
  ysReportMonthNum,
  ysReportMonthSportData,
  ysReportMonthTzData,
  ysReportMonthComReport,
  childrenZHBGGet,
  getLAGCourse,
  growthValueTopData,
  growthValueRateData,
  growthValueAnalyseData,
  childrenDbMapData,
  childrenDbRateData,
  childrenDbAnalyseData,
  sportMethodRadarData,
  sportMethodScoreData,
  sportMethodAnalyseData,
  getCXReport,
  coursePraise,
  courseCountAdd,
  testNewRecordList,
  testNewRecordInfo,
  createNewReportPre,
  createNewReport,
  getNormalTestLabel,
  getSingleTestData,
  saveSingleTestData,
  getShareImageUrl
}

/* jshint esversion: 6 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-11 20:13:53
 * @LastEditors: 张磊（zhanglei1204@jd.com）
 * @LastEditTime: 2021-05-12 21:58:23
 * @FilePath: /官网迭代/src/api/publicBenefit.js
 */
import { request } from '@/utils/request'
// publicBenefit
// http://localhost:8090/annex/searchAnnex?key=3 公益搜索
/**
 * 体验课程|付费 节数
 * @param {key} params 关键字
 * @param {page} params 页码
 * @param {size} params 条数
 * @returns
 */
const searchAnnex = (params) => {
  return request({
    url: '/annex/searchAnnex',
    params,
    method: 'get',
  })
}

// http://localhost:8090/annexComment/video?id=1   评论列表
/**
 * @param {id} params 视频id值
 * @returns
 */
const getMessage = (params) => {
  return request({
    url: '/annexComment/video',
    params,
    method: 'get',
  })
}

// http://localhost:8090/annex/comment?videoId&obj&uid&content&commentCount&commentId  评论
/**
 * @param {videoId} params 视频id值
 * @param {obj} params 0：视频评论  1:给评论评论
 * @param {uid} params 用户id
 * @param {content} params 评论内容
 * @param {commentCount} params 评论个数
 * @param {commentId} params 评论id
 * @returns
 */
const comment = (params) => {
  return request({
    url: '/annex/comment',
    // url: '/annexComment',
    params,
    method: 'get',
  })
}

// http://localhost:8090/annex/praise?id=10&obj=0&uid=12&type=1&objId=10  点赞
/**
 * @param {id} params 公益附件ID   即视频ID
 * @param {obj} params 0：视频点赞 1：评论点赞
 * @param {uid} params 用户id
 * @param {type} params 对象类型 0：视频  1：评论
 * @param {objid} params 评论id
 * @returns
 */
const praise = (params) => {
  return request({
    url: '/annex/praise',
    params,
    method: 'get',
  })
}
/**
 * 公益园视频上传确认按钮
 * @param { projecttype}   公益1： 示范园2： 足特园 3：其他 
 * @param { annextype}   0：图片1：视频2：文件3：html连接  附件类型
 * @param { schoolid}    //学校ID
 * @param { role}    //上传者角色 userID
 * @param { uploader}    //上传者ID userID
 * @param { url }    //视频上传路径  后端返回的字符串
 * @param { uploadertype }    //上传者类型：0：官方    1：园所     
 * @param { savepath }    //视频上传路径  后端返回的字符串
 * @param { coverimage }    //缩略图路径  
 * @param {  name }    //附件名字
 */
// http://localhost:8090/annex/add

const annex = (data) => {
  return request({
    url: `/annex/add`,
    data,
    method: 'post',
  })
}

export default {
  searchAnnex,
  getMessage,
  comment,
  praise,
  annex

}
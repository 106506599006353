<template>
  <div>
    <div class="head-nav">
      <div class="width_1434">
        <div class="positon_box">
          <div class="title">小小运动会</div>
          <div class="positon_boximg">
            <img
              :src="constant.URL + sportslist[indexactive].contestAreaLogo"
              alt=""
              
              v-if="sportslist.length"
            />
            <div>
              <p>重庆赛区</p>
              <p>Chongqing Division</p>
            </div>
          </div>
          
          <!-- <div class="go_back" v-if="$store.state.schoolInfo.atteSchool"> <span>姓名：{{$store.state.userInfo.teacherId?$store.state.teacherInfo.name:($store.state.schoolInfo.atteSchool.contacts?$store.state.schoolInfo.atteSchool.contacts:'')}}</span><span class="go_back_span" @click="signout">退出登陆</span></div>
            <div class="urse_center" v-else>
            <span @click="login">登陆</span>
          </div> -->
          <!-- <div class="urse_center">
            <span @click="login">跳转首页</span>
          </div> -->
        </div>
      </div>
    </div>

    <div class="width_1434 bg_white">
      <div class="tabbox">
        <div class="flxone">
          <div
            :class="$store.state.prcstatus == 'project' ? ' time timeactive' : 'time'"
            @click="goJump('project')"
          >
            项目
          </div>
          <div
            :class="$store.state.prcstatus == 'schedule' ? ' time timeactive' : 'time'"
            @click="goJump('schedule')"
          >
            赛程
          </div>
          <div
            :class="$store.state.prcstatus == 'sportsRank' ? ' time timeactive' : 'time'"
            @click="goJump('sportsRank')"
          >
            排行榜
          </div>
        </div>
        <div class="dis_inline"></div>
        <div class="flxone citybox">
          <div
            :class="indexactive == index ? ' time timeactive' : 'time'"
            v-for="(i, index) in sportslist"
            :key="index"
            @click="gospaotrindex(i.id, index)"
          >
            {{ i.nickName }}
          </div>
        </div>
        <div class="fr fr_box">
          <div class="dis_inline"></div>
          <div
            :class="
              $store.state.prcstatus == 'trainProgram' ? ' time timeactive' : 'time'
            "
            @click="goJump('trainProgram')"
          >
            训练方案
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="signup" @click="signup">报名</div> -->
    
  </div>
</template>
<script>
// import {querylist} from '../api/sports'
export default {
  name: "sports",
  data() {
    return {
      sportslist: [],
      indexactive: 0,
      prcstatus: "",
      trainProgramstatus: "",
      dialogFormVisible: false,
      form: {},
       formLabelWidth: '120px',
       logstatus:false,
       
    };
  },
 
  mounted() {
    if(this.$store.state.userInfo.token){
       this.logstatus = true
    }
    console.log(this.$route.path,'this.prcstatus = val;')
    if(this.$route.path){
      let path = this.$route.path.split('/')[1];
      this.$store.commit('selectPage',path);
    }
    
    this.querylist();
  },
  methods: {
    signout(){
        this.$store.dispatch('out', this)
    },
    signup(){
        if(!this.$store.state.userInfo.token){
        if(!this.$store.state.userInfo.token){
            this.$store.commit("setLoginState", ["login","/sport"]);
            return;
        }
      }
      if(this.$store.state.userInfo.teacherId){
        this.$notice({
          message: '请用园长账号报名',
        }).isShow();
        return;
      }
      this.$router.push({
        path:'/signup',
        
      })
    },
    
    login() {
      this.$router.push({
        path:'/home',
        
      })
    },
    querylist() {
      var that = this;
      
      
      this.api.sports.querylist().then((e) => {
        that.sportslist = e.data;
        console.log(that.sportslist[0].id);
        
        that.$store.commit("setSpotrsindex", that.sportslist[0].id);
        console.log(that.$store.state.setSpotrsindex);
      });
    },
    gospaotrindex(val, index) {
      this.indexactive = index;
      // this.prcstatus = "";
      // this.trainProgramstatus = "";
      this.$store.commit('selectPage','');
      this.$store.commit("setSpotrsindex", this.sportslist[index].id);
       this.$store.commit('isEnableEnroll',this.sportslist[index].isEnableEnroll)
      console.log(this.$store.state.spotrsindex);
      
      this.$router.push({
        path: "/sport",
        query: {
          id: val,
        },
      });
    },
    goJump(val) {
      // if (val == "trainProgram") {
      //   // this.trainProgramstatus = val;
      //   // this.prcstatus = "";
      //   this.$store.commit('selectPage',val);
       
      // } else {
      //   // this.prcstatus = val;
       
      //   // this.trainProgramstatus = "";
      // }
      this.$store.commit('selectPage',val);
      console.log(this.$store.state.prcstatus)
      this.$router.push({
        path: '/'+val,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.head-nav {
  height: 92px;
  background: #bb3037;
}
.width_1434 {
  width: 1434px;
  margin: 0 auto;
  position: relative;
}
.positon_boximg {
  position: absolute;
  left: 0;
  top: 16px;
  width: 250px;
  height: 56px;
  font-weight: bold;
  color: #FFFFFF;
  font-size:24px;
  @include flex(row, flex-start, center);
  >img{
    margin-right:14px;
    width:56px;
    height:56px;
    border-radius: 50%;
  }
  >div >p:nth-child(2){
    font-size:18px;
    font-weight: normal;
  }
}
.positon_box {
  position: relative;
  .title {
    text-align: center;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 92px;
  }
}
.tabbox {
  height: 72px;
  background: linear-gradient(232deg, #3b60a8, #1f4283);
  padding-left: 39px;
  font-size: 20px;
  padding-top: 17px;
  box-sizing: border-box;

  .flxone {
    float: left;
  }
  .time {
    padding: 8px 10px;
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    font-size: 20px;
    cursor: pointer;
  }
  .time:nth-last-child(1) {
    margin-right: 0;
  }
  .dis_inline {
    color: rgba(255, 255, 255, 0.4);
    margin: 0 10px;
    height: 42px;
    float: left;
    height: 42px;
    background: #ffffff;
    opacity: 0.4;
    border-radius: 1px;
    width: 1px;
    vertical-align: middle;
  }
  .time:hover,
  .active {
    background: #122868;
    border-radius: 4px;
  }
  .timeactive {
    background: #122868;
    border-radius: 4px;
  }
  .fr_box {
    padding-right: 146px;
    .time {
      margin-left: 10px;
    }
  }
}
.citybox {
  width: 60%;
  white-space: nowrap;
  overflow-x: auto;
}
.citybox::-webkit-scrollbar {
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
  height:5px;
}
.go_back {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-weight: bold;
  color: #ffffff;
  line-height: 92px;
  max-width: 500px;
  text-align: center;
  span {
    vertical-align: middle;
    margin-right: 20px;
  }
  .go_back_span {
    cursor: pointer;
  }
}
.urse_center {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-weight: bold;
  color: #ffffff;
  line-height: 92px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  span {
    vertical-align: middle;
  }
}
.signup {
  width: 200px;
  height: 80px;
  text-align: center;
  color: white;
  position: fixed;
  right: 30px;
  top: 50%;
  margin-top: -40px;
  border-radius: 10px;
  background: #bb3037;
  line-height: 80px;
  font-size: 30px;
  cursor: pointer;
}

</style>
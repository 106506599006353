const COURSE_ROUTERS = [
  {
    name: "course_class",
    path: "/course_class",
    component: () => import("@/views/course/index.vue"),
  },
  {
    name: "category",
    path: "/category",
    component: () => import("@/views/course/category.vue"),
  },
  {
    name: "classVideo",
    path: "/classVideo",
    component: () => import("@/views/course/classVideo.vue"),
  },
  {
    name: "courseList",
    path: "/courseList",
    component: () => import("@/views/course/courseList.vue"),
  },
  {
    name: "evaluate",
    path: "/evaluate",
    component: () => import("@/views/course/evaluate.vue"),
    meta: { isSchool: true },
  },
  {
    name: "attendClass",
    path: "/attendClass",
    component: () => import("@/views/course/attendClass.vue"),
  },
  {
    name: "sports_info",
    path: "/sports_info",
    component: () => import("@/views/admin/teacher/sports_info.vue"),
  },
  {
    name: "testCheck",
    path: "/testCheck",
    component: () => import("@/views/admin/teacher/testCheck.vue"),
  },
];

export default COURSE_ROUTERS;

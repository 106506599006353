<template>
  <div class="report">
    <div class="report-tit">
      {{ $store.state.schoolInfo.atteSchool.schoolName }}-{{
        $route.query.stuReport
      }}
    </div>
    <div class="report-sub-tit">
      <span>{{ $route.query.grade }}·{{ $route.query.class }}</span>
      <span>{{ $route.query.stu }}</span>
      <span>系统平台幼儿成长测评报告</span>
    </div>
    <div class="report-des">
      <!-- <p class="report-des-time">最近测试时间:<span>2021.08.09</span></p> -->
      <p class="report-des-cont">
        学员测评报告主要来自于全国足球特色幼儿园系统平台的专业测评。我们针对幼儿园阶段不同年纪的孩子提供标准化的测试项目。测评项目分体质测试
        和运动测试两大模块。
      </p>
    </div>
    <div class="report-body">
      <div class="report-sub-line-tit">
        <p class="title-line"></p>
        <p class="title-name">体质测试</p>
      </div>
      <div class="report-body-cont">
        <div
          class="report-body-cont-item"
          v-for="(item, index) in tzList"
          :key="index"
        >
          <div class="report-body-cont-item-top">
            <img
              :src="require(`@/assets/img/test/Icon_Report_${index}.png`)"
              alt=""
            />

            <!-- <img src="../../../assets/img/test/Icon_Report_0.png" alt=""> -->
            <div class="right">
              <p class="name">{{ item.testingFoundation.title }}</p>
              <p class="value">
                {{ item.reportOne ? item.reportOne : "0"
                }}<span>{{ handleDw(item.testingFoundation.title) }}</span>
              </p>
            </div>
          </div>
          <div class="report-body-cont-item-bottom">
            全国平均值{{
              item.avgVal || "0" + handleDw(item.testingFoundation.title)
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="report-motion">
      <div class="report-motion-tit">
        <div class="report-sub-line-tit">
          <p class="title-line"></p>
          <p class="title-name">运动测试</p>
        </div>
        <div class="report-motion-tit-legend">
          <p class="fail">
            <span></span>
            <span>需努力</span>
          </p>
          <p class="good">
            <span></span>
            <span>良好</span>
          </p>
          <p class="excellent">
            <span></span>
            <span>优秀</span>
          </p>
        </div>
      </div>
      <div class="report-motion-cont">
        <div
          class="report-motion-cont-item"
          v-for="(item, index) in ydList"
          :key="index"
        >
          <p class="report-motion-cont-item-tit">
            {{ item.testingLog.testingSports.name }}:<span
              >{{ item.value || "-未测试-"
              }}{{
                `${
                  item.value
                    ? "(" +
                      item.testingLog.testingSports.unit.substr(-1, 1) +
                      ")"
                    : ""
                }`
              }}</span
            >
          </p>
          <div
            class="text"
            :class="{
              step1: item.testingLog.avgVal * 100 < 21,
              step2: item.testingLog.avgVal * 100 > 60,
              step3:
                item.testingLog.avgVal * 100 < 60 &&
                item.testingLog.avgVal * 100 > 21,
            }"
            :style="{ left: item.testingLog.avgVal * 100 + '%' }"
          >
            {{ "超过全国" + (item.testingLog.avgVal * 1000000) / 10000 + "%" }}
            <!-- {{
                            item.testingLog.avgVal*100
                                ? "超过全国" + item.testingLog.avgVal*100 + "%"
                                : ""
                        }} -->
          </div>
          <div class="report-motion-cont-item-progress">
            <div
              class="color"
              :class="{
                step1: item.testingLog.avgVal * 100 < 21,
                step2: item.testingLog.avgVal * 100 > 60,
                step3:
                  item.testingLog.avgVal * 100 < 60 &&
                  item.testingLog.avgVal * 100 > 21,
              }"
              :style="{ width: item.testingLog.avgVal * 100 + '%' }"
            ></div>

            <div class="line"></div>
            <div class="probar">
              <span class="i1"></span>
              <span class="i2"></span>
              <span class="i3"></span>
              <span class="i4"></span>
              <div class="t1">0</div>
              <div class="t2">20</div>
              <div class="t3">60</div>
              <div class="t4">100</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="report-prescription">
      <div class="report-sub-line-tit">
        <p class="title-line"></p>
        <p class="title-name">系统平台幼儿运动处方</p>
      </div>
      <div class="report-prescription-des">
        学员运动处方来源于系统平台根据国内外对儿童青少年体质健康的前沿研究，根据PDA测试结果，根据体育活动的目的，用处方的形式，制定的系统化、
        有针对性的体育活动指导方案。
      </div>
      <!-- <div class="report-prescription-table">
        <p>体质测试</p>
        <table class="layui-table table-style" lay-skin="line">
          <thead>
            <tr>
              <th v-for="(item, index) in tzList" :key="index">
                {{ item.testingFoundation.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(item, index) in tzList" :key="index">
                {{ item.reportOne || 0 }}
              </td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div> -->
      <div class="report-prescription-table mt30">
        <p>运动处方</p>
        <table class="layui-table table-style" lay-skin="line">
          <thead>
            <tr>
              <th style="width: 300px">主题</th>
              <th style="width: 300px">成绩</th>
              <th>处方建议</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in ydList" :key="index">
              <td>
                <b>{{ item.testingLog.testingSports.title + "能力" }}</b>
              </td>
              <td>
                <span>{{ item.value }}</span>
              </td>
              <td style="width: 500px">
                <b>{{
                  item.testingLog.testingSports.advice || "pda_report2"
                }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="tips">
      <div class="report-sub-line-tit">
        <p class="title-line"></p>
        <p class="title-name">注意事项</p>
      </div>
      <div class="tips-cont">
        <p>
          1.由于体温调节系统发育不成熟，儿童应避免在炎热潮湿的环境下持续运动，并且应注意补水。
        </p>
        <p>
          2.超重或体力不足的儿童应以中等强度体力活动开始，适应后逐渐增加运动频率和时间以达到每天60分钟的目标。
        </p>
        <p>
          3.对于有疾病或特殊问题的儿童，如哮喘、肥胖者，应根据他们的身体状态、症状以及体适能水平制定运动处方。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "schoolReport",
  data() {
    return {
      bodyDataList: [],
      tzList: [
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
      ],
      ydList: [
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
      ],
    };
  },
  methods: {
    //   单位转化
    handleDw(val) {
      if (val) {
        let str = "";
        if (val.indexOf("/") != -1) {
          str = val.split("/")[1];
        }
        let res = "";
        if (str) {
          let temp = str.split("");
          temp.unshift("/");
          res = temp.join("");
        }
        return res == "视力" ? "" : res;
      }
    },

    // 体质测试
    getTZ() {
      let data = {
        semesterId: this.$route.query.semesterId,
        stuId: this.$route.query.stuId,
        isEnd: this.$route.query.isEnd,
        gradeType: this.$route.query.gradeType,
        type: this.$route.query.type,
      };
      this.api.pes.findReportByStuId(data).then((res) => {
        if (res.flag) {
          this.tzList = res.data;
          this.loading = false;
          this.tzList.forEach((item) => {
            item.createtime = this.common.dateFormat(
              item.createtime * 1000,
              "yyyy-MM-dd"
            );
          });
        }
      });
    },
    // 运动测试
    getYD() {
      let data = {
        semesterId: this.$route.query.semesterId,
        stuId: this.$route.query.stuId,
        isEnd: this.$route.query.isEnd,
        type: this.$route.query.type,
        gradeType: this.$route.query.gradeType,
      };
      this.api.pes.findReport2ByStuId(data).then((res) => {
        this.ydList = res.data;
        this.ydList.forEach((item) => {
          item.createtime = this.common.dateFormat(
            item.createtime * 1000,
            "yyyy-MM-dd"
          );
        });
      });
    },
  },
  mounted() {
    this.getTZ();
    this.getYD();
  },
};
</script>
<style scoped lang="scss">
.mt30 {
  margin-top: 30px !important;
}
.report {
  width: 1354px;
  margin: 0 auto;
  padding: 0 40px;
  background: #fff;
  .report-tit {
    padding: 38px 0 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .report-sub-tit {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    > span {
      padding: 0 10px;
    }
  }
  .report-des {
    margin-top: 16px;
    .report-des-time {
      font-size: 16px;
      font-weight: 400;
      color: #888888;

      > span {
        color: #333333;
      }
    }
    .report-des-cont {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      // text-indent: 2em;
    }
  }
  .report-body {
    margin-top: 26px;
    .report-body-cont {
      //@include flex(row, space-between, center);
      flex-wrap: wrap;
      display: flex;
      .report-body-cont-item {
        margin-bottom: 20px;
        width: 316px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        margin-right:1.5%;
        .report-body-cont-item-top {
          @include flex(row, flex-start, top);
          > img {
            margin: 10px 0 0 15px;
            width: 75px;
            height: 82px;
          }
          .right {
            margin-left: 4px;
            .name {
              padding-top: 20px;
              font-size: 18px;
              color: #333333;
            }
            .value {
              margin-top: 4px;
              font-size: 30px;
              font-weight: bold;
              color: #333333;
              > span {
                font-size: 16px;
                color: #888888;
              }
            }
          }
        }
        .report-body-cont-item-bottom {
          margin-top: 12px;
          border-top: 1px solid #f1f1f1;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #888888;
          padding-left: 20px;
        }
      }
      .report-body-cont-item:nth-child(4n){
      margin-right:0;}
    }
  }
  .report-motion {
    margin-top: 20px;
    .report-motion-tit {
      @include flex(row, space-between, center);
      .report-motion-tit-legend {
        font-size: 14px;
        color: #333333;

        > p {
          margin-left: 10px;
          display: inline-block;
        }
        .fail {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #ffcc77;
            border-radius: 2px;
          }
        }
        .good {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #71e5b8;
            border-radius: 2px;
          }
        }
        .excellent {
          span:nth-child(1) {
            margin-right: 12px;
            display: inline-block;
            width: 24px;
            height: 12px;
            background: #4f7cff;
            border-radius: 2px;
          }
        }
      }
    }
    .report-motion-cont {
      @include flex(row, space-between, center);
      flex-wrap: wrap;
    }
    .report-motion-cont-item {
      margin-bottom: 20px;
      padding-bottom: 20px;
      width: 316px;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      color: #333333;
      .text {
        // position: absolute;
        // left: 0;
        // top: .32rem;
        padding-left: 20px;
        padding-bottom: 20px;
        height: 0.16rem;
        line-height: 0.16rem;
        font-size: 16px;
        color: #888888;
      }
      .text.step1 {
        color: #888888;
      }
      .text.step2 {
        color: #888888;
        font-size: 16px;
      }
      .text.step3 {
        color: #888888;
        left: inherit !important;
        right: 0;
        top: 0.16rem;
      }

      .report-motion-cont-item-tit {
        padding: 20px 20px 15px 20px;
        font-size: 18px;
      }
      .report-motion-cont-item-progress {
        margin: 0 auto;
        width: 276px;
        .color {
          height: 0.15rem;
          background: #71e5b8;
          position: relative;
        }
        // .color::after {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        //     width: .02rem;
        //     height: .24rem;
        //     background: #71E5B8;
        // }

        .color.step1 {
          background: #71e5b8;
        }
        // .text.step1 {
        //     color: #FFCC77;
        // }
        .color.step2 {
          background: #4f7cff;
        }
        // .text.step2 {
        //     color: #888888;
        //     font-size:16px;
        // }
        .color.step3 {
          background: #71e5b8;
        }
        // .text.step3 {
        //     color: #000;
        //     left: inherit !important;
        //     right: 0;
        //     top: .16rem;
        // }
        .line {
          background-color: #aaa;
          height: 0.02rem;
        }
        .probar {
          position: relative;
          height: 0.2rem;
        }
        .probar span {
          display: block;
          width: 0.02rem;
          height: 0.05rem;
          background-color: #aaa;
        }
        .probar div {
          display: block;
          height: 0.16rem;
          line-height: 0.16rem;
          font-size: 0.16rem;
          color: #aaa;
        }
        .probar .i1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        .probar .i2 {
          position: absolute;
          left: 20%;
          top: 0;
        }
        .probar .i3 {
          position: absolute;
          left: 60%;
          top: 0;
        }
        .probar .i4 {
          position: absolute;
          right: 0;
          top: 0;
        }
        .probar .t1 {
          position: absolute;
          left: 0;
          top: 0.1rem;
        }
        .probar .t2 {
          position: absolute;
          left: calc(20% - 0.1rem);
          top: 0.1rem;
        }
        .probar .t3 {
          position: absolute;
          left: calc(60% - 0.1rem);
          top: 0.1rem;
        }
        .probar .t4 {
          position: absolute;
          right: 0;
          top: 0.1rem;
        }
      }
    }
  }
  .report-prescription {
    margin-top: 20px;
    .report-prescription-des {
      margin-top: 0;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      // text-indent: 2em;
    }
  }
  .report-prescription-table {
    margin-top: 20px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 4px;

    > p {
      padding: 8px 0 0 0;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      opacity: 0.9;
    }
    .table-style {
      margin-top: 6px;
      thead {
        tr {
          height: 42px;
          background: #ba3037;
          color: #ffffff;
          th {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      tbody {
        tr td {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .tips {
    margin-top: 30px;
    padding-bottom: 60px;
    .tips-cont {
      line-height: 28px;
      font-size: 16px;
      color: #333333;
      opacity: 0.9;
    }
  }
  .report-sub-line-tit {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    .title-line {
      margin-right: 12px;
      margin-top: 3px;
      float: left;
      width: 4px;
      height: 18px;
      background: #ba3037;
      border-radius: 2px;
    }
    .title-name {
      margin-top: -2px;
      float: left;
    }
  }
}
</style>
import { request } from "@/utils/request";

// 获取当前金币数
const getGoldInfo = (params) => {
  return request({
    url: `/goldConis/getGoldInfo`,
    method: "get",
    params,
  });
};

//创建金币充值二维码
const createNative = (data) => {
  return request({
    url: "/goldConis/createNative",
    data,
    method: "post",
  });
};

//检查是否支付成功
const checkPay = (params) => {
  return request({
    url: "/goldConis/checkPay",
    params,
    method: "get",
  });
};

//金币支付套餐
const payPackage = (data) => {
  return request({
    url: "/goldConis/payPackage",
    data,
    method: "post",
  });
};
// 金币线下支付套餐
const offineRecharge = (data) => {
  return request({
    url: "/goldConis/offineRecharge",
    data,
    method: "post",
  });
};

const selectPrefootBallInOrderList = (params, data) => {
  return request({
    url: `/goldConis/selectPrefootBallInOrderList/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//上传支付凭证
const uplodaProof = (params) => {
  return request({
    url: "/goldConis/uploadProof",
    params,
    method: "get",
  });
};

// 获取数据

export default {
  getGoldInfo,
  createNative,
  payPackage,
  checkPay,
  selectPrefootBallInOrderList,
  offineRecharge,
  uplodaProof,
};
